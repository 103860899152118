import { FC, useEffect, useState } from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import styled from '@emotion/styled';

import { ModalBodyPadding20, Badge as StyledBadge, BadgeIcon, Text, NoWidthButton, ProfileImg40x40Left, Text16Gray,
    ButtonGroup, LowPriorityButton } from '../children/styled';
import { getBadgePoints, goToEarnSetup, updateImageOnError } from '../../services/helpers';
import UserService from '../../services/userService';
import StorageService from '../../services/storageService';
import BadgeService from '../../services/badgeService';
import Trophy from "../../images/trophy.svg";

const Badge = styled(StyledBadge)`;
  display: inline;
  font-size: 17px;
  padding: 2px 8px;
  bottom: 0px;
  margin: 0px 3px;
`

const TrophyImg = styled.img`
  width: 50%;
  margin: 25px auto 30px;
  display: block;
  cursor: pointer;
  transition: transform .2s;

  &:hover {
    transform: scale(1.1);
  }
`

interface BadgeModalProps {
  showBadgeModal: boolean;
  accountId?: string;
  offerForBadge?: Offer;
  setShowBadgeModal: (val: boolean) => void;
};
const BadgeModal: FC<BadgeModalProps> = ({ showBadgeModal, offerForBadge, accountId, setShowBadgeModal }) => {
  const [badgeToShow, setBadgeToShow] = useState<Badge>();

  const fetchBadge = async (userId: string) : Promise<void> => {
    const badgeObj: Badge = await BadgeService.getUserBadge(userId);
    setBadgeToShow(badgeObj);
  };

  const updateOnError = async () => {
    let userId: string = accountId;
    if (offerForBadge) {
      userId = offerForBadge?.gifter?.id;
      await updateImageOnError(offerForBadge);
    };
    const updatedAcc: Account = await UserService.getAccountById(userId);
    await BadgeService.updateBadge({
      ...badgeToShow,
      account: {
        id: updatedAcc?.id,
        username: updatedAcc?.username,
        name: updatedAcc?.name,
        image: StorageService.getImageOrDefault(updatedAcc?.images),
        phone: updatedAcc?.phone,
        email: updatedAcc?.email,
        offersScope: updatedAcc?.offersScope
      }
    });
  };

  useEffect(() => {
    if (accountId && !offerForBadge) {
      fetchBadge(accountId);
    } else if (offerForBadge && !accountId) {
      fetchBadge(offerForBadge?.gifter?.id);
    };
  }, [accountId, offerForBadge]);

  const getNameOrUsername = () => {
    if (badgeToShow) {
      return UserService.getNameOrUsername(badgeToShow?.account?.name, badgeToShow?.account?.username);
    };
    return UserService.getNameOrUsername(offerForBadge?.gifter?.name, offerForBadge?.gifter?.username);
  };

  return (
    <Modal show={showBadgeModal} onHide={() => setShowBadgeModal(false)} size="sm" centered>
      {offerForBadge &&
        <Modal.Header style={{ padding: "20px 15px" }} closeButton={true}>
          <ModalTitle style={{ fontSize: "18px" }} onClick={() => window.location.assign(`/${offerForBadge?.gifter?.username}`)}>
            <ProfileImg40x40Left id="myImg" src={StorageService.getImageOrDefault([badgeToShow?.account?.image])}
              onError={updateOnError} style={{ marginRight: "8px" }} />
            <Text16Gray style={{ position: "relative", top: "6px", marginBottom: "7px", marginRight: "8px" }}>
              {getNameOrUsername()}
            </Text16Gray>
          </ModalTitle>
        </Modal.Header>
      }
      <ModalBodyPadding20>
        {offerForBadge && <>
          <Text style={{ display: "inline", textAlign: "left" }}>Earned</Text>
          <Badge style={{ fontSize: "15px" }}>
            {getBadgePoints(offerForBadge?.product?.price?.amount, offerForBadge?.checkout?.shippingFee, offerForBadge?.checkout?.processingFee)} points
          </Badge>
          <Text style={{ display: "inline" }}>for sending this gift!</Text></>
        }
        {!offerForBadge && badgeToShow && <>
          <Text style={{ display: "block", textAlign: "center" }}>
          {getNameOrUsername()} has earned a total of:
          </Text>
          <Badge style={{ fontSize: "17px", padding: "8px 10px", top: "6px", margin: "0px auto", width: "fit-content", display: "block", lineHeight: "20px" }}>
            <BadgeIcon className="fa-solid fa-award" />
            {badgeToShow?.points} points
          </Badge></>
        }
        <TrophyImg src={Trophy} />
        <ButtonGroup container direction="row" justifyContent="center" alignItems="center" className='center'>
          <LowPriorityButton onClick={() => setShowBadgeModal(false)}>Done</LowPriorityButton>
          <NoWidthButton onClick={goToEarnSetup}>Earn points</NoWidthButton>
        </ButtonGroup>
      </ModalBodyPadding20>
    </Modal>
  );
}

export default BadgeModal;
