import { FC } from 'react';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { Delete } from '@mui/icons-material';

import { Thumbnail } from './styled';
import { NONE } from '../../constants';

const TabGrid = styled(Grid)`
  margin-top: 10px;
`

const BigImage = styled.img`
  width: 500px;
  height: 500px;
  object-fit: contain;
`

interface ProfileModalProps {
  displayCSS: string
  selectedImage: string
  images: string[]
  canDelete: boolean
  setSelectedImage: (val: string) => void
  setDisplayCSS: (val: string) => void
  setConfirmDeleteModal: (val: boolean) => void
  setImageIndexToDelete: (val: number) => void
}
const ProfileModal: FC<ProfileModalProps> = ({ displayCSS, selectedImage, images, setSelectedImage,
  setDisplayCSS, setConfirmDeleteModal, setImageIndexToDelete, canDelete }) => {

  const handleDeleteClick = (index: number) : void => {
    setImageIndexToDelete(index);
    setConfirmDeleteModal(true);
  };

  const onPhotoModalClose = (event: any) => {
    if (event.target.id === "profilePhotoModal") {
      setDisplayCSS(NONE);
    };
  };

  return (
    <div id="profilePhotoModal" className="photo-modal" style={{ display: displayCSS }} onClick={onPhotoModalClose}>
      <span className="close" onClick={() => setDisplayCSS(NONE)}>&times;</span>
      <BigImage className="photo-modal-content" src={selectedImage} />
      <TabGrid container direction="row" justifyContent="center" alignItems="center">
        {images?.map(img => (
          <div className="column profileThumbnailContainer" key={images.indexOf(img)}>
            <Thumbnail src={img} onClick={() => setSelectedImage(img)} />
            {canDelete ?
              <span onClick={() => handleDeleteClick(images.indexOf(img))} className="overlay"><Delete /></span>
              :
              null
            }
          </div>
        ))}
      </TabGrid>
    </div>
  )
}

export default ProfileModal;
