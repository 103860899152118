import { FC, useState, useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { User, onAuthStateChanged } from "firebase/auth";
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

import Checkout from '../children/Checkout';
import PaymentStatus from '../children/PaymentStatus';
import StripeService from '../../services/stripeService';
import { AuthContext } from '../../providers/AuthProvider';
import { auth } from '../../firebase';
import { EMPTY_STRING, LOGIN_PATH } from '../../constants';
import { MainCenterDiv } from '../children/styled';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

interface OffersProps {
  offer?: Offer;
  setIsCheckout?: (val: boolean) => void;
}
const StripeWrapper: FC<OffersProps> = ({ offer, setIsCheckout }) => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [clientSecret, setClientSecret] = useState<string>(EMPTY_STRING);
  const [alreadyPaid, setAlreadyPaid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const paymentIntentParam: string = new URLSearchParams(window.location.search).get('payment_intent');
  const clientSecretParam: string = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
  const status: string = new URLSearchParams(window.location.search).get('redirect_status');

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
    });
  };

  const getPaymentClientSecret = async () => {
    if (!offer) {
      setIsCheckout(false);
      return;
    };
    try {
      setIsLoading(true);
      const paymentObj = await StripeService.getPaymentById(currentUser.id, offer?.checkout?.paymentId);
      setIsLoading(false);
      if (paymentObj) {
        setClientSecret(paymentObj?.client_secret);
        return;
      }
      console.error("No payment found. Closing checkout.");
      setIsCheckout(false);
    } catch (err) {
      console.error("Error getting client_secret in StripeWrapper: ", err)
      setIsCheckout(false);
    };
  };

  useEffect(() => {
    setUp();
    if (paymentIntentParam && clientSecretParam && status) {
      setClientSecret(clientSecretParam);
      setAlreadyPaid(true);
    } else {
      getPaymentClientSecret();
    }
  }, []);

  return (<>
    {isLoading &&
      <MainCenterDiv style={{ textAlign: "center", marginTop: "30px", marginBottom: "20px" }}>
        <CircularProgress style={{ color: "#585858" }} />
      </MainCenterDiv>
    }
    {!isLoading && clientSecret && stripePromise && (
      <Elements stripe={stripePromise} options={{ clientSecret, appearance: {} }}>
        { alreadyPaid && <PaymentStatus clientSecret={clientSecret} /> }
        { !alreadyPaid && offer && <Checkout offer={offer} /> }
      </Elements>
    )}</>
  );
};

export default StripeWrapper;
