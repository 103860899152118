import { FC, useState, ChangeEvent, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { FormControlLabel, Grid as MuiGrid, Radio, RadioGroup } from '@mui/material';
import { Modal } from 'react-bootstrap';

import { AuthContext } from '../../providers/AuthProvider';
import UserService from '../../services/userService';
import OfferService from '../../services/offerService';
import { ACCEPTED, CONTACTS, EMPTY_STRING, EVERYONE, EVERYONE_OPTION,
  ONLY_MY_CONTACTS_OPTION, SUCCESS, UNCONFIRMED } from '../../constants';
import { Info, Input, Text } from './styled';
import { defaultOfferScope } from '../../services/emptyObjs';

const ModalTitle = styled(Modal.Title)`
   color: var(--fir-color-grey);
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
   font-weight: 400;
   font-size: 15px;
`

const ModalBody = styled(Modal.Body)`
   margin-bottom: 10px;
`

const Buttons = styled(MuiGrid)`
   margin-top: 5px !important;
   margin-bottom: 10px;
`

const Select = styled.select`
   border-radius: 10px;
   border: 1px solid rgba(206,206,206,1.00) !important;
   font-size: 16px;
   padding: 9px;
   width: 100%;
   margin-top: 15px;
`

const InputGrid = styled(MuiGrid)`
   padding: 0px;
`

const Label = styled.div`
   color: rgb(38, 38, 38);
   font-size: 15px;
   font-weight: 500;
   line-height: 18px;
   margin-bottom: 10px;
   width: 100%;
   text-align: left;
`

const TextArea = styled.textarea`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  font-size: 16px;
  padding: 5px 10px;
  width: 100%;
`

const Button = styled.div`
  background-color: #8dd4f6;
  border: solid 1px #8dd4f6;
  padding: 0px 15px;
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  border-radius: 64px;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #fff;
  font-weight: 500;

  &:hover {
    background-color: #8dd4f6;
    border: solid 1px #8dd4f6;
  }
  &:focus {
    border: solid 1px #8dd4f6;
  }
`

const Link = styled.a`
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  line-height: 23.8px;
  font-size: 18px;
  padding-top: 28px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`

const Center = styled.div`
   display: block;
   margin: auto;
   width: 85%;
`

const Error = styled.div`
  color: rgb(255, 90, 84);
  font-size: 12px;
  line-height: 15.8px;
  width: 100%;
  max-width: 333px;
  margin-bottom: 0px !important;
  margin-top: 5px;
`
const noDetailIsEmpty = (address1: string, town: string, postcode: string, firstName: string, lastName: string, phone: string) : boolean => {
  return address1.trim() !== EMPTY_STRING && town.trim() !== EMPTY_STRING && postcode.trim() !== EMPTY_STRING && firstName.trim() !== EMPTY_STRING && lastName.trim() !== EMPTY_STRING && phone.trim() !== EMPTY_STRING;
}

interface AccountFormProps {
  offer: Offer;
  addressModalOpen: boolean;
  setOfferEdited: (val: Offer) => void;
  setAddressModalOpen: (val: boolean) => void;
  setAlertIsOpen: (val: boolean) => void;
  setAlertType: (val: string) => void;
  setAlertMessage: (val: string) => void;
};
const ShippingAddressModal: FC<AccountFormProps> = ({ offer, addressModalOpen, setOfferEdited, setAddressModalOpen, setAlertType, setAlertMessage, setAlertIsOpen}) => {
  const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);
  const fullName: string[] = currentUser?.name?.split(" ");
  const [firstName, setFirstName] = useState<string>(fullName?.at(0) || EMPTY_STRING);
  const [lastName, setLastName] = useState<string>(fullName?.at(1) || EMPTY_STRING);
  const [address1, setAddress1] = useState<string>(currentUser?.address?.address1 || EMPTY_STRING);
  const [address2, setAddress2] = useState<string>(currentUser?.address?.address2 || EMPTY_STRING);
  const [town, setTown] = useState<string>(currentUser?.address?.town || EMPTY_STRING);
  const [province, setProvince] = useState<string>(currentUser?.address?.province || EMPTY_STRING);
  const [postcode, setPostcode] = useState<string>(currentUser?.address?.postcode || EMPTY_STRING);
  const [country, setCountry] = useState<string>("United Kingdom");
  const [phone, setPhone] = useState<string>(currentUser?.phone || EMPTY_STRING);
  const [instructions, setInstructions] = useState<string>(EMPTY_STRING);
  const [saveAddress, setSaveAddress] = useState<boolean>(noDetailIsEmpty(address1, town, postcode, firstName, lastName, phone));
  const [detailsIncomplete, setDetailsIncomplete] = useState<boolean>(!noDetailIsEmpty(address1, town, postcode, firstName, lastName, phone));
  const [error, setError] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [gifteeMessage, setGifteeMessage] = useState<string>(EMPTY_STRING);
  const [gifteeOfferScope, setGifteeOfferScope] = useState<string>(EVERYONE);

  const handleAddress1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
  }

  const handleAddress2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  }

  const handleTownChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTown(event.target.value);
  }

  const handleProvinceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProvince(event.target.value);
  }

  const handlePostcodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostcode(event.target.value);
  }

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  }

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  }

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  }

  const handleInstructionsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInstructions(event.target.value);
  }

  const handleGifteeMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setGifteeMessage(event.target.value);
  }

  const handleOfferPrivacyChange = (event: any) => {
    setGifteeOfferScope(event.target.value);
  }

  const handleSaveChange = () => {
    setSaveAddress(!saveAddress);
  }

  const handleCancelClick = () => {
    setAddressModalOpen(false)
  }

  const handleUseAddressClick = async () => {
    if (detailsIncomplete) {
      setError(true);
      return;
    }
    setShowForm(false);
  }

  const handleAcceptClick = async () => {
    if (saveAddress) {
      const updatedCurrentUser: Account = {
        ...currentUser,
        name: firstName.trim() + " " + lastName.trim(),
        address: {
          address1: address1.trim(),
          address2: address2.trim(),
          town: town.trim(),
          province: province.trim(),
          postcode: postcode.trim(),
          country
        },
        phone: phone.trim(),
        updatedAt: Date.now()
      }
      await UserService.updateAccount(updatedCurrentUser);
      setCurrentUserToStorage(updatedCurrentUser);
    }
    const checkout: Checkout = {
      ...offer.checkout,
      shippingAddress: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        address1: address1.trim(),
        address2: address2.trim(),
        town: town.trim(),
        province: province.trim(),
        postcode: postcode.trim(),
        country
      },
      delivery: {
        phone: phone.trim(),
        instructions: instructions.trim(),
      }
    };
    let offerScope: OfferScope;
    if (offer?.scope) {
      offerScope = { ...offer?.scope, giftee: gifteeOfferScope };
    } else {
      offerScope = { ...defaultOfferScope, giftee: gifteeOfferScope }
    };
    const newOffer: Offer = await OfferService.updateOffer({
      ...offer,
      status: ACCEPTED,
      updatedAt: Date.now(),
      checkout,
      gifteeMessage,
      scope: offerScope
    });
    setOfferEdited(newOffer);
    setAddressModalOpen(false);
    setAlertIsOpen(true);
    setAlertType(SUCCESS);
    setAlertMessage(`You've successfully accepted ${UserService.getNameOrUsername(offer?.gifter?.name, offer?.gifter?.username)}'s gift.`)
  }

  useEffect(() => {
    if (noDetailIsEmpty(address1, town, postcode, firstName, lastName, phone)) {
      setDetailsIncomplete(false);
      setError(false);
      return;
    }
    setDetailsIncomplete(true);
  }, [address1, town, postcode, firstName, lastName, phone]);

  useEffect(() => {
    if (addressModalOpen && detailsIncomplete) {
      setShowForm(true);
    }
    setError(false);
  }, [addressModalOpen]);

  useEffect(() => {
    if (offer && offer?.scope && offer?.scope?.giftee !== UNCONFIRMED) {
      setGifteeOfferScope(offer?.scope?.giftee);
    }
  }, [offer]);

  return (
    <Modal show={addressModalOpen} onHide={() => setAddressModalOpen(false)} centered>
      <Modal.Header closeButton>
        <ModalTitle>Where would you like your gift delivered to?</ModalTitle>
      </Modal.Header>
      <ModalBody>
        <InputGrid container direction="column" justifyContent="center" alignItems="center">
          <Center>
            <Info style={{ marginBottom: "20px", marginTop: "10px", maxWidth: "100%" }}>The details below will be hidden and won't be a part of your public profile. Only you can see them.</Info>
            <Label style={{ marginTop: '10px' }}>Delivery address</Label>
            {showForm ?
              <>
                <Input type='text' placeholder='First name' value={firstName} onChange={handleFirstNameChange} />
                {error && firstName.trim() === EMPTY_STRING ?
                  <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>First name cannot be empty</Error>
                  :
                  null
                }
                <Input type='text' placeholder='Last name' value={lastName} onChange={handleLastNameChange} />
                {error && lastName.trim() === EMPTY_STRING ?
                  <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>Last name cannot be empty</Error>
                  :
                  null
                }
                <Input type='text' placeholder='Address line 1' value={address1} onChange={handleAddress1Change} />
                {error && address1.trim() === EMPTY_STRING ?
                  <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>Address cannot be empty</Error>
                  :
                  null
                }
                <Input type='text' placeholder='Address line 2 (optional)' value={address2} onChange={handleAddress2Change} />
                <Input type='text' placeholder='Town/City' value={town} onChange={handleTownChange} />
                {error && town.trim() === EMPTY_STRING ?
                  <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>Town/city cannot be empty</Error>
                  :
                  null
                }
                <Input type='text' placeholder='County (optional)' value={province} onChange={handleProvinceChange} />
                <Input type='text' placeholder='Postcode' value={postcode} onChange={handlePostcodeChange} />
                {error && postcode.trim() === EMPTY_STRING ?
                  <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>Postcode cannot be empty</Error>
                  :
                  null
                }
                <Select><option value={country}>{country}</option></Select>
                <Error style={{ padding: '0px 5px', marginBottom: '10px', color: "rgb(142, 142, 142)", maxWidth: "100%" }}>
                  Apologies, we currently deliver within the {country} only. Soon it will be available worldwide.
                </Error>
                <Label style={{ marginTop: '20px' }}>Phone</Label>
                <Input type='tel' value={phone} placeholder='Phone' onChange={handlePhoneChange} style={{ marginTop: "0px !important" }}/>
                {error && phone.trim() === EMPTY_STRING ?
                  <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>Your phone number will only be used to contact you in case we have questions about the delivery.</Error>
                  :
                  null
                }
                <Label style={{ marginTop: '25px' }}>
                    <input checked={saveAddress} onChange={handleSaveChange} type="checkbox" style={{ marginRight: '5px', cursor: 'pointer' }} />
                    <label onClick={handleSaveChange} style={{ fontSize: '15px', fontWeight: 400, lineHeight: '16px', cursor: "pointer" }}>
                      Save details for future use
                    </label>
                </Label>
                <Buttons container direction="row" justifyContent="center" alignItems="flex-start" style={{ marginTop: '50px', marginBottom: '0px' }}>
                  <Link onClick={handleCancelClick}>Cancel</Link>
                  <Button onClick={handleUseAddressClick} style={{ marginLeft: '10px', marginRight: '0px' }}>Use this address</Button>
                </Buttons>
              </>
            :
              <>
                <Text>{firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase() + lastName.slice(1)}</Text>
                <Text>{address1}</Text>
                { address2 !== EMPTY_STRING ? <Text>{address2}</Text> : null }
                <Text>{town}</Text>
                { province !== EMPTY_STRING ? <Text>{province}</Text> : null }
                <Text>{postcode}</Text>
                <Text>{country}</Text>
                <Label style={{ marginTop: '20px' }}>Phone</Label>
                <Text>{phone}</Text>
                <Label style={{ marginTop: '20px', marginLeft: "5px" }}>Delivery instructions (optional)</Label>
                <TextArea rows={2} value={instructions} onChange={handleInstructionsChange} />
                <hr style={{ margin: "20px 0px" }} />
                <Label style={{ marginBottom: '15px' }}>Who can see this gift offer?</Label>
                <RadioGroup onChange={handleOfferPrivacyChange} value={gifteeOfferScope}>
                  <FormControlLabel style={{ height: "25px", marginBottom: "5px" }} value={EVERYONE} control={<Radio />} label={EVERYONE_OPTION} />
                  <FormControlLabel style={{ height: "25px" }} value={CONTACTS} control={<Radio />} label={ONLY_MY_CONTACTS_OPTION} />
                </RadioGroup>
                <hr style={{ margin: "20px 0px" }} />
                <Label style={{ marginBottom: '15px' }}>Write a message to {UserService.getNameOrUsername(offer?.gifter?.name, offer?.gifter?.username)} (optional)</Label>
                <TextArea rows={3} value={gifteeMessage} onChange={handleGifteeMessageChange} placeholder='Thanks for the gift!' />
                {detailsIncomplete ?
                  <Buttons container direction="row" justifyContent="center" alignItems="flex-start" style={{ marginTop: '50px', marginBottom: '0px' }}>
                    <Button onClick={() => setShowForm(true)}>Update details</Button>
                  </Buttons>
                  :
                  <MuiGrid container direction="row" justifyContent="center" alignItems="flex-start" style={{ marginTop: '0px', marginBottom: '0px' }}>
                    <Link onClick={() => setShowForm(true)}>Edit details</Link>
                    <Button onClick={handleAcceptClick} style={{ marginLeft: '10px', marginRight: '0px' }}>Accept</Button>
                  </MuiGrid>
                }
              </>
            }
          </Center>
        </InputGrid>
      </ModalBody>
    </Modal>
  );
};

export default ShippingAddressModal;
