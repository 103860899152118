import { FC, useState, ChangeEvent, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { StorageReference } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import { Modal as AccountInfoModal } from 'react-bootstrap';

import UserService from "../../services/userService";
import { auth } from '../../firebase';
import { AuthContext } from '../../providers/AuthProvider';
import { Hr, Info, Label, LinkTextA, ModalBody, NoWidthButton, ProfileImg190x190Right, SmallSpinner, Spinner as BaseSpinner, Text } from './styled';
import {
  CONTACTS, BASE_URL, ERROR, EVERYONE, MAX_FILE_SIZE_KB, MAX_COMPRESSED_FILE_SIZE_KB, COMPLETED_SETUP,
  DAYS, PROFILE_PATH, CREATE_PATH, EMPTY_STRING, PROFILE_IMAGES_FOLDER, SLASH, EVERYONE_OPTION, ONLY_MY_CONTACTS_OPTION, BLOCK, SHARE_INFO_FREQUENCY
} from '../../constants';
import Alert from './Alert';
import StorageService from '../../services/storageService';
import { emptyBadge } from '../../services/emptyObjs';
import BadgeService from '../../services/badgeService';

const Main = styled(Grid)`
   margin-top: 40px;
   margin-bottom: 130px;
`

const LabelGrid = styled(Grid)`
  padding-left: 32px;
  padding-right: 32px;
`

const ColumnTwo = styled(Grid)`
  padding: 0px;
`

const Error = styled.p`
  color: rgb(255, 90, 84);
  font-size: 12px;
  line-height: 15.8px;
  width: 100%;
  max-width: 333px;
`

const TextArea = styled.textarea`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  font-size: 16px;
  padding: 5px 10px;
  width: 100%;
  max-width: 333px;
`

const Input = styled.input`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00);
  font-size: 16px;
  padding: 10px;
  width: 100%;
  max-width: 333px;
`

const InputGrid = styled(Grid)`
    margin-bottom: 20px;
  `

const Heading = styled.h3`
  line-height: 31.4px;
  white-space: nowrap;
  letter-spacing: -1.1px;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
  margin-top: 16px;
  color: rgb(0, 0, 0);
  max-width: 333px;
  width: 100%;
  text-wrap: wrap;
`

const UploadImage = styled.label`
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
`

const ProfileImageLoader = styled.label`
  border-radius: 50%;
  padding: 7px;
  width: 250px;
  height: 250px;
  float: right;
  position: relative;
`

const Spinner = styled(BaseSpinner)`
  top: 27%;
  left: 37%;
  font-size: 20px;
`

interface AccountFormProps {
  images: string[]
  selectedImage: string
  setDisplayCSS: (val: string) => void
  setAccount: (account: Account) => void
  setEditing: (val: boolean) => void
  setImages: (imgs: string[]) => void
  setSelectedImage: (img: string) => void
}
const AccountForm: FC<AccountFormProps> = ({ setAccount, setEditing, images, setImages, setDisplayCSS, selectedImage, setSelectedImage }) => {
  const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);
  const [name, setName] = useState(currentUser?.name || EMPTY_STRING);
  const [username, setUsername] = useState(currentUser?.username || EMPTY_STRING);
  const [bio, setBio] = useState(currentUser?.bio || EMPTY_STRING);
  const [address1, setAddress1] = useState(currentUser?.address?.address1 || EMPTY_STRING);
  const [address2, setAddress2] = useState(currentUser?.address?.address2 || EMPTY_STRING);
  const [town, setTown] = useState(currentUser?.address?.town || EMPTY_STRING);
  const [province, setProvince] = useState(currentUser?.address?.province || EMPTY_STRING);
  const [postcode, setPostcode] = useState(currentUser?.address?.postcode || EMPTY_STRING);
  const [country, setCountry] = useState(currentUser?.address?.country || EMPTY_STRING);
  const [phone, setPhone] = useState(currentUser?.phone || EMPTY_STRING);
  const [offersScope, setOffersScope] = useState<string>(currentUser?.offersScope || EVERYONE);
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [fileUploadError, setFileUploadError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [imgUploadLoading, setImgUploadLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [completeModalOpen, setCompleteModalOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const uploadCompressedFile = async (compressedFile: Blob) : Promise<void> => {
    if((compressedFile?.size / 1024) > MAX_COMPRESSED_FILE_SIZE_KB) {
      setFileUploadError(true);
      setErrorMessage("Sorry, image is too big. Please select an image of smaller size.");
      return;
    }
    setFileUploadError(false);
    setErrorMessage(EMPTY_STRING);
    setImgUploadLoading(true);
    const imgref: StorageReference = await StorageService.uploadImage(compressedFile, PROFILE_IMAGES_FOLDER);
    const imgUrl: string = await StorageService.getImageUrl(imgref);
    const updatedImages: string[] = await StorageService.updateImages(imgUrl, images, PROFILE_IMAGES_FOLDER);
    setImages(updatedImages);
    setSelectedImage(imgUrl);
    if (currentUser) {
      const updatedCurrentUser: Account = {
        ...currentUser,
        images: updatedImages,
        updatedAt: Date.now()
      }
      setAccount(updatedCurrentUser);
      setCurrentUserToStorage(updatedCurrentUser);
      await UserService.updateAccount(updatedCurrentUser);
    }
    setImgUploadLoading(false);
  }

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    let compressedFile: any;
    try {
      if (file && ((file.size / 1024) > MAX_FILE_SIZE_KB)) {
        compressedFile = await imageCompression(file, {
          maxSizeMB: 0.4,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        });
      } else {
        compressedFile = file;
      }
      await uploadCompressedFile(compressedFile);
    } catch (error) {
      console.error(error);
    }
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleUsernameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value.trim());
    const uname = event.target.value.trim().toLocaleLowerCase();
    const anotherUserWithUsername = await UserService.getAccountByUsername(uname);
    if (anotherUserWithUsername && anotherUserWithUsername?.username !== currentUser?.username) {
      setUsernameError(true);
      setErrorMessage("Username is already taken.");
    } else if (UserService.containsSpecialChars(uname)) {
      setUsernameError(true);
      setErrorMessage("Username can only contain letters, numbers, periods, and underscores.");
    } else if (uname?.length > 30) {
      setUsernameError(true);
      setErrorMessage("Username can only contain 30 characters.");
    } else {
      setUsernameError(false);
    };
  };

  const handleBioChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBio(event.target.value);
  }

  const handleAddress1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
  }

  const handleAddress2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  }

  const handleTownChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTown(event.target.value);
  }

  const handleProvinceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProvince(event.target.value);
  }

  const handlePostcodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostcode(event.target.value);
  }

  const handleCountryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCountry(event.target.value);
  }

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  }

  const handleOffersScopeChange = (event: any) => {
    setOffersScope(event.target.value);
  }

  const handleImgClick = () => {
    if (images?.length > 0) {
      setDisplayCSS(BLOCK);
    }
  }

  const handleCancelClick = () => {
    setEditing(false);
    if (username.trim() !== EMPTY_STRING) {
      window.location.assign(`/${username}`);
    }
    window.scrollTo(0, 0);
  }

  const handleSubmitClick = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);
    if (username.trim() === EMPTY_STRING || usernameError) {
      setAlertIsOpen(true);
      setAlertType(ERROR);
      setAlertMessage("Please enter a valid username.");
      setSubmitting(false);
      return;
    }
    const anotherUserWithUsername = await UserService.getAccountByUsername(username.toLowerCase().trim());
    if (anotherUserWithUsername && anotherUserWithUsername?.username !== currentUser?.username) {
      setUsernameError(true);
      setErrorMessage("Username is already taken.")
      setAlertIsOpen(true);
      setAlertType(ERROR);
      setAlertMessage("Username is already taken.");
      setSubmitting(false);
      return;
    }
    const updatedCurrentUser: Account = {
      id: auth?.currentUser?.uid,
      username: username.toLowerCase().trim(),
      name: name.trim(),
      email: auth?.currentUser?.email,
      bio,
      address: {
        address1: address1.trim(),
        address2: address2.trim(),
        town: town.trim(),
        province: province.trim(),
        postcode: postcode.trim(),
        country: country.trim()
      },
      phone: phone.trim(),
      offersScope,
      images,
      createdAt: currentUser?.createdAt ? currentUser?.createdAt : Date.now(),
      updatedAt: currentUser?.updatedAt ? currentUser?.updatedAt : Date.now()
    };
    await UserService.updateAccount(updatedCurrentUser);
    if (pathname === CREATE_PATH) {
      try {
        // const accountSettings: AccountSettings = await AccountSettingsService.getAccountSettings(auth.currentUser.uid);
        // if (!accountSettings) {
        //   await AccountSettingsService.addAccountSettings({
        //     ...emptyAccountSettings,
        //     accountId: auth.currentUser.uid
        //   });
        // };
        await BadgeService.addBadge({...emptyBadge,
          updatedAt: Date.now(),
          account: {
            id: updatedCurrentUser?.id,
            username: updatedCurrentUser?.username,
            name: updatedCurrentUser?.name,
            email: updatedCurrentUser?.email,
            image: StorageService.getImageOrEmpty(updatedCurrentUser?.images),
            phone: updatedCurrentUser?.phone,
            offersScope: updatedCurrentUser?.offersScope
          }
        });
      } catch (err) {
        console.error(err);
      };
    };
    setCurrentUserToStorage({ ...currentUser, ...updatedCurrentUser });
    setAccount(updatedCurrentUser);
    const updatedAccount: Account = await UserService.getAccountById(updatedCurrentUser.id);
    const dateOfSetup: string = localStorage.getItem(COMPLETED_SETUP);
    if (!dateOfSetup || (moment(Date.now()).diff(moment(parseInt(dateOfSetup)), DAYS) > SHARE_INFO_FREQUENCY)) {
      localStorage.setItem(COMPLETED_SETUP, Date.now().toString());
      window.location.assign(PROFILE_PATH);
      return;
    }
    window.location.assign(BASE_URL + SLASH + updatedAccount?.username);
  }

  useEffect(() => {
    if (images?.length > 0) {
      setSelectedImage(images[0]);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    setCompleteModalOpen(pathname === CREATE_PATH);
  }, [pathname]);

  return (
    <Main container justifyContent="center" spacing={1}  columns={{ xs: 1, sm: 1, md: 12 }}>
      <Grid item xs={1} md={3}>
        {imgUploadLoading ?
          <ProfileImageLoader className='profile-img'>
            <ProfileImg190x190Right src={selectedImage} onClick={handleImgClick} className='profile-img' />
            <Spinner animation="border" className='spinner-large-screen' />
          </ProfileImageLoader>
          :
          <ProfileImg190x190Right src={selectedImage} onClick={handleImgClick} className='profile-img' />
        }
      </Grid>
      <ColumnTwo item xs={1} md={6}>
        <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
          <LabelGrid item xs={2}></LabelGrid>
          <Grid item xs={6}>
            <UploadImage>
              <LinkTextA>Change profile photo</LinkTextA>
              <input type="file" onChange={handleUpload} accept="image/*" multiple={false} />
              { fileUploadError && <Error style={{ marginBottom: '10px' }}>{errorMessage}</Error> }
            </UploadImage>
            { pathname === CREATE_PATH &&
              <Info style={{ padding: '0px 5px', marginBottom: '10px' }}>Help people you know find your account by adding a profile picture.</Info>
            }
          </Grid>
        </InputGrid>
        <form>
          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}>
              <Label>Name</Label>
            </LabelGrid>
            <Grid item xs={6}>
              <Input type='text' value={name} onChange={handleNameChange} required  />
            </Grid>
          </InputGrid>

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}>
              <Label>Username</Label>
            </LabelGrid>
            <Grid item xs={6}>
              <Input type='text' style={{ marginBottom: "10px", border: `${usernameError ? "1px solid rgba(255,90,84,1.00)" : "1px solid rgba(206,206,206,1.00)"}` }}
                value={username} onChange={handleUsernameChange} required />
              { usernameError && <Error style={{ padding: '0px 5px', marginBottom: '10px' }}>{errorMessage}</Error> }
              <Info style={{ padding: '0px 5px', marginBottom: '10px' }}>Help people you know find your account by using your username.</Info>
            </Grid>
          </InputGrid>

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}>
              <Label>Bio</Label>
            </LabelGrid>
            <Grid item xs={6}>
              <TextArea value={bio} onChange={handleBioChange} rows={4} />
            </Grid>
          </InputGrid>

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}></LabelGrid>
            <Grid item xs={6}>
              <Heading>Where do you want your gifts delivered? (Optional)</Heading>
              <Hr className="solid"/>
              <Info>
                The details below will be hidden and won't be a part of your public profile. Only you can see them. Your personal data will not be shared with anyone.
              </Info>
            </Grid>
          </InputGrid>

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}>
              <Label>Email</Label>
            </LabelGrid>
            <Grid item xs={6}>
              <Input disabled value={currentUser?.email} type='email' />
            </Grid>
          </InputGrid>

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}>
              <Label>Delivery address</Label>
            </LabelGrid>
            <Grid item xs={6}>
              <Input value={address1} onChange={handleAddress1Change} type='text' placeholder='Address line 1' style={{ marginBottom: '16px', marginRight: '5px' }} />
              <Info style={{ padding: '0px 5px', marginBottom: '20px' }}>Add your address if you want to easily accept gifts.</Info>
              <Input value={address2} onChange={handleAddress2Change} type='text' placeholder='Address line 2 (optional)' style={{ marginBottom: '16px', marginRight: '5px' }} />
              <Input value={town} onChange={handleTownChange} type='text' placeholder='Town/City' style={{ marginBottom: '16px', marginRight: '5px' }} />
              <Input value={province} onChange={handleProvinceChange} type='text' placeholder='State/Province' style={{ marginBottom: '16px', marginRight: '5px' }} />
              <Input value={postcode} onChange={handlePostcodeChange} type='text' placeholder='Postcode' style={{ marginBottom: '16px', marginRight: '5px' }} />
              <Input value={country} onChange={handleCountryChange} type='text' placeholder='Country' style={{ marginBottom: '16px', marginRight: '5px' }} />
            </Grid>
          </InputGrid>

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}>
              <Label>Phone</Label>
            </LabelGrid>
            <Grid item xs={6}>
              <Input onChange={handlePhoneChange} value={phone} type='tel' />
            </Grid>
          </InputGrid>

          {pathname === CREATE_PATH &&
            <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
              <LabelGrid item xs={2}></LabelGrid>
              <Grid item xs={6}>
                <Hr />
                <Label style={{ padding: '0px 5px', marginBottom: '15px', textAlign: 'left' }}>Who can offer to send you gifts?</Label>
                <RadioGroup onChange={handleOffersScopeChange} value={offersScope}>
                  <FormControlLabel value={EVERYONE} control={<Radio />} label={EVERYONE_OPTION} />
                  <FormControlLabel value={CONTACTS} control={<Radio />} label={ONLY_MY_CONTACTS_OPTION} />
                </RadioGroup>
                <Info style={{ padding: '5px', marginTop: "15px" }}>
                  No one can send you a gift without your knowledge. You can always choose to accept or decline a gift offer. We take your safety and security very seriously.
                </Info>
              </Grid>
            </InputGrid>
          }

          <InputGrid container direction="row" justifyContent="center" alignItems="flex-start" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
            <LabelGrid item xs={2}></LabelGrid>
            <Grid item xs={6}>
              {currentUser.username !== EMPTY_STRING && !submitting && <NoWidthButton onClick={handleCancelClick} type="button">Cancel</NoWidthButton>}
              <NoWidthButton onClick={handleSubmitClick} type="submit">
                { submitting ? <SmallSpinner animation="border" /> : <>Submit</> }
              </NoWidthButton>
            </Grid>
          </InputGrid>
        </form>
      </ColumnTwo>
      <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} />
      <AccountInfoModal show={completeModalOpen} onHide={() => setCompleteModalOpen(false)} centered>
        <ModalBody style={{ marginBottom: "0px" }}>
          <Text style={{ padding: "8px" }}>Add a photo and a bio for a chance to win a FREE gift! 🎁</Text>
          <NoWidthButton onClick={() => setCompleteModalOpen(false)} className='center' style={{ marginRight: "auto", marginTop: "20px" }}>
            Complete account
          </NoWidthButton>
        </ModalBody>
      </AccountInfoModal>
    </Main>
  );
};

export default AccountForm;
