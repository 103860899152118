import { FC, useContext } from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';

import { ModalBodyPadding20, Text, NoWidthButton, ButtonGroup, LowPriorityButton, Text16Gray } from '../children/styled';
import { sendGiftHelper } from '../../services/helpers';
import UserService from '../../services/userService';
import { AuthContext } from '../../providers/AuthProvider';

interface MsgInfoModalProps {
  showMsgInfoModal: boolean;
  account: Account;
  setShowMsgInfoModal: (val: boolean) => void;
};
const MsgInfoModal: FC<MsgInfoModalProps> = ({ showMsgInfoModal, account, setShowMsgInfoModal }) => {
  const { currentUser, signOut } = useContext(AuthContext);

  const handleSendGiftClick = async () => {
    await sendGiftHelper(currentUser, account, signOut);
    setShowMsgInfoModal(false);
  };

  return (
    <Modal show={showMsgInfoModal} onHide={() => setShowMsgInfoModal(false)} size="sm" centered>
      <Modal.Header style={{ padding: "20px 15px" }} closeButton={true}>
        <ModalTitle>
          <Text16Gray>Send a message 💬</Text16Gray>
        </ModalTitle>
      </Modal.Header>
      <ModalBodyPadding20>
        <Text>Looks like {UserService.getNameOrUsername(account?.name, account?.username)} is not in your connections.</Text>
        <Text>You can start a conversation if you send a connection request or a gift that they accept. 🎁</Text>
        <ButtonGroup container direction="row" justifyContent="center" alignItems="center" className='center'>
          <LowPriorityButton onClick={() => setShowMsgInfoModal(false)}>Close</LowPriorityButton>
          <NoWidthButton onClick={handleSendGiftClick}>Send gift</NoWidthButton>
        </ButtonGroup>
      </ModalBodyPadding20>
    </Modal>
  );
}

export default MsgInfoModal;
