import { FC } from 'react';
import styled from '@emotion/styled';

import { NONE } from '../../constants';

const BigImage = styled.img`
  width: 500px;
  height: 500px;
  object-fit: contain;
`

interface ImageModalProps {
  displayCSS: string
  selectedImage: string
  setDisplayCSS: (val: string) => void
}
const ImageModal: FC<ImageModalProps> = ({ displayCSS, selectedImage, setDisplayCSS }) => {

  const onImageModalClose = (event: any) => {
    if (event.target.id === "imgModal") {
      setDisplayCSS(NONE);
    };
  };

  return (
    <div id="imgModal" className="photo-modal" style={{ display: displayCSS }} onClick={onImageModalClose}>
      <span className="close" onClick={() => setDisplayCSS(NONE)}>&times;</span>
      <BigImage className="photo-modal-content" src={selectedImage} />
    </div>
  )
}

export default ImageModal;
