import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { INVITES } from "../constants";

const getInviteById = async (inviteId: string) : Promise<Invite> => {
  try {
    const inviteRef = doc(firestoreDb, INVITES, inviteId);
    const invite = await getDoc(inviteRef);
    if (invite.exists()) {
      return invite.data() as Invite;
    }
  } catch (e) {
    console.error("Error getting invite by id: ", e);
  }
}

const addInvite = async (inviteObj: Invite) : Promise<Invite> => {
  try {
    const inviteRef = await addDoc(collection(firestoreDb, INVITES), inviteObj);
    await updateDoc(inviteRef, { id: inviteRef.id });
    return getInviteById(inviteRef.id);
  } catch (e) {
    console.error("Error adding invite: ", e);
  }
}

const getInviteByShareId = async (shareId: string) : Promise<Invite> => {
  try {
    const q = query(collection(firestoreDb, INVITES), where("shareId", "==", shareId));
    const invitesSnapshot = await getDocs(q);
    const invites = invitesSnapshot.docs;
    if (invites?.length === 1) {
      return invites[0].data() as Invite;
    }
    return null;
  } catch (e) {
    console.error("Error getting invite by shareId: ", e);
  }
}

const InviteService = {
  addInvite,
  getInviteByShareId
}

export default InviteService;
