import { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Modal, ModalTitle } from 'react-bootstrap';
import { FilterNoneOutlined as CopyIcon } from '@mui/icons-material';

import { BASE_URL, SLASH } from '../../constants';
import {
  ButtonsGroup, GridMarginRight, ConnectionsIcon, ConnectionsIconButton,
  ProfileImg60x60Left as ProfileImg, Item, SubText, Text, NoWidthButton, CopyArea,
  LinkText, IconButtonMui
} from './styled';
import { handleCopyClick, sendGiftHelper } from '../../services/helpers';
import CongratsService from '../../services/congratsService';
import StorageService from '../../services/storageService';
import UserService from '../../services/userService';

const ModalBody = styled(Modal.Body)`
   margin-bottom: 0px;
   padding: 15px;
`

const Center = styled.div`
   display: block;
   margin: auto;
   width: 100%;
`

const SingleCenterButton = styled(NoWidthButton)`
  margin: 20px auto 0px auto;
`

interface CongratsModalProps {
  currentUser: Account;
  congratsModalOpen: boolean;
  celebrationObj: Celebration;
  setCongratsModalOpen: (val: boolean) => void; 
  signOut: () => Promise<void>;
  setAlertIsOpen: (val: boolean) => void;
  setAlertType: (val: string) => void;
  setAlertMessage: (val: string) => void;
}
const CongratsModal: FC<CongratsModalProps> = ({ currentUser, congratsModalOpen, celebrationObj,
  setAlertIsOpen, setAlertType, setAlertMessage, setCongratsModalOpen, signOut }) => {
  const [usersCongrats, setUsersCongrats] = useState<SubUser[] | null>();

  const handleAccountClick = (user: SubUser) => {
    window.location.assign(BASE_URL + SLASH + user?.username);
  };

  const handleGiftClick = async (user: SubUser) => {
    await sendGiftHelper(currentUser, user, signOut);
    setCongratsModalOpen(false);
  };

  const getUserCongrats = async () => {
    const congratsList: Congrats[] = await CongratsService.getAllActiveCongratsForCele(celebrationObj?.id);
    setUsersCongrats(congratsList?.map(congratsObj => congratsObj?.sender));
  };

  useEffect(() => {
    if (celebrationObj && congratsModalOpen) {
      getUserCongrats();
    };
  }, [celebrationObj, congratsModalOpen]);

  return (
    <Modal size="sm" show={congratsModalOpen} onHide={() => setCongratsModalOpen(false)} centered>
      <Modal.Header style={{ padding: "20px 15px" }} closeButton={true}>
        <ModalTitle style={{ fontSize: "18px" }}>
          Greeters <span style={{ marginLeft: "2px" }}>👏🏻</span>
        </ModalTitle>
      </Modal.Header>
      <ModalBody>
        <Center>
          {usersCongrats && usersCongrats?.length > 0 && <>
            {usersCongrats.map(user => (
              <Item container direction="row" justifyContent="flex-start" alignItems="center" key={user?.id} className='center-width-24rem'>
                <ProfileImg src={StorageService.getImageOrDefault([user?.image])} onClick={() => handleAccountClick(user)} style={{ width: "60px", height: "60px" }} />
                <GridMarginRight item onClick={() => handleAccountClick(user)}>
                  <Text style={{ marginBottom: "0px" }}>{user?.name}</Text>
                  <SubText>@{user?.username}</SubText>
                </GridMarginRight>
                <ButtonsGroup>
                  <ConnectionsIconButton onClick={() => handleGiftClick(user)}>
                    <ConnectionsIcon className="fas fa-gift" />
                  </ConnectionsIconButton>
                </ButtonsGroup>
              </Item>))
            }
            <SingleCenterButton className='center' onClick={() => setCongratsModalOpen(false)}>Done</SingleCenterButton></>
          }
          {(!usersCongrats || usersCongrats?.length === 0) && currentUser?.id !== celebrationObj?.author?.id &&
            <>
              <Text style={{ textAlign: "center" }}>
                There are no greetings yet.
                You could be the first to congratulate {UserService.getNameOrUsername(celebrationObj?.author?.name, celebrationObj?.author?.username)}!
              </Text>
              <SingleCenterButton className='center' onClick={() => setCongratsModalOpen(false)}>Done</SingleCenterButton>
            </>
          }
          {(!usersCongrats || usersCongrats?.length === 0) && currentUser?.id === celebrationObj?.author?.id && <>
            <Text style={{ textAlign: "center", marginBottom: "25px" }}>Share your celebration with others so they can gift you! 🎁</Text>
            <CopyArea onClick={() => handleCopyClick(BASE_URL + SLASH + currentUser?.username, setAlertIsOpen, setAlertType, setAlertMessage)}
              container direction="row" justifyContent="space-between" alignItems="center">
              <LinkText>{BASE_URL + SLASH + currentUser?.username}</LinkText>
              <IconButtonMui><CopyIcon /></IconButtonMui>
            </CopyArea>
            <SingleCenterButton className='center' onClick={() => setCongratsModalOpen(false)}>Done</SingleCenterButton></>
          }
        </Center>
      </ModalBody>
    </Modal>
  );
};

export default CongratsModal;
