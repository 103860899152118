import { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { getStorage, ref, getMetadata } from "firebase/storage";
import { CardMedia, ListItemIcon, Menu, MenuItem, Tooltip, Unstable_Grid2 as Grid } from '@mui/material';
import styled from '@emotion/styled';
import moment from 'moment';

import { ACHIEVEMENT, ANNIVERSARY, CELEBRATING, CELEBRATING_A, CELEBRATING_AN, CONTACTS, EMPTY_STRING, ENGAGED, EVERYONE,
  GRADUATION, GOAL, JUST_GOT, LEFT, MARRIED, SOMETHING_NEW, HIT_A, BLOCK, EMPTY_ARRAY, CELEBRATION_IMAGES_FOLDER, STORAGE_OBJ_NOT_FOUND,
  DOES_NOT_EXIST, ACTIVE } from '../../constants';
import { Card, CardContent, CardHeader, ConnectionsIconButton, DetailsGiftsList, IconSize16, Item, Text16Gray, NoWidthButton,
  ProfileImg40x40Left, ProfileImg60x60Left, SubText, TextGiftsList, TextMargin0, Time, TopRightIconButton, DotSeparator,
  IconImage as BaseIconImage, CardHeaderGrid, BaseLeftIcon, ellipsisDropDownCSS } from './styled';
import StorageService from '../../services/storageService';
import CelebrationService from '../../services/celebrationService';
import { AuthContext } from '../../providers/AuthProvider';
import { sendGiftHelper } from '../../services/helpers';
import UserService from '../../services/userService';
import PopperFull from "../../images/popper-full.svg";
import PopperOutline from "../../images/popper-outline.svg";
import CongratsService from '../../services/congratsService';
import { emptyCongrats } from '../../services/emptyObjs';
import NotificationService from '../../services/notifcationService';

const InlineButton = styled(NoWidthButton)`
  min-height: unset;
  padding: 8px 15px;
  margin: 0px;
`

const VerticalScroll = styled.div`
  padding: 0px 5px;
  overflow-y: scroll;
  max-height: 130px;
  background-color: #f0f2f5;
`

const TypeBackground = styled.div`
  max-width: 100%;
  padding: 10px 15px;
  margin-bottom: 13px;
  color: #106bd5;
  background-color: #eff8ff;
  border-radius: 8px;
  border: 2px solid #0000;
  text-align: center;
`

const TypeText = styled.p`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 0px;
`

const DarkText = styled.span`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.04rem;
  text-transform: none;
  line-height: 1.2rem;
  margin-left: 4px;
`

const TypeIcon = styled.span`
  margin-right: 10px;
`

const TypeDescriptionDiv = styled.div`
  padding: 0px 12px;
`

const ScopeIcon = styled.i`
  font-size: 9px;
  margin-right: 5px;
  bottom: 1px;
  position: relative;
`

const ProductItem = styled(Item)`
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`

const PoppersImage = styled(BaseIconImage)`
  width: 30px;
  height: 30px;
  position: relative;
  bottom: 2px;
  margin-left: 10px;
`

const ClapIcon = styled.i`
  font-size: 18px;
`

const GreetersNotifButton = styled.span`
  display: block;
  position: absolute;
  left: 40px;
  bottom: 33px;
  width: 10px;
  height: 10px;
  background-color: rgb(255, 48, 64);
  border-radius: 50%;
  z-index: 1;
`

const formatMessage = (message: string, index: number, previousMessage: string) => {
  if (message === EMPTY_STRING && previousMessage !== EMPTY_STRING) {
    return (<span key={index} style={{ height: "8px", display: "block" }} />);
  };
  return (<TextMargin0 key={index}>{message}</TextMargin0>)
};

interface CelebrationProps {
  celebration: Celebration;
  personal: boolean;
  displayingOnFeed?: boolean;
  celebrationEdited?: Celebration;
  congratulated?: boolean;
  confetti?: any;
  newNotif?: boolean;
  handleCelebrationProductsChange?: (celebration: Celebration) => void;
  setCelebrationToEdit?: (celebration: Celebration) => void;
  setCelebrationModalOpen: (val: boolean) => void;
  handleGiftIconClick?: (product: Product, userObj?: SubUser) => void;
  setShowProductsToSelect?: (val: boolean) => void;
  setDisplayImageModalCSS: (val: string) => void;
  setImageToModal: (val: string) => void;
  setCelebrationToDelete: (celebration: Celebration) => void;
  setShowDeleteModal: (val: boolean) => void;
  setCelebrationToShowCongrats: (celebration: Celebration) => void;
  setCongratsModalOpen: (val: boolean) => void;
};
const Celebration: FC<CelebrationProps> = ({ celebration, personal, displayingOnFeed, setCelebrationToEdit, handleCelebrationProductsChange,
  setCelebrationModalOpen, handleGiftIconClick, setShowProductsToSelect, celebrationEdited, setImageToModal, setDisplayImageModalCSS,
  setCelebrationToDelete, setShowDeleteModal, confetti, congratulated, setCelebrationToShowCongrats, setCongratsModalOpen, newNotif }) => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [wordingMap, setWordingMap] = useState<Map<string, string>>();
  const [dropDownAnchorEl, setDropDownAnchorEl] = useState<HTMLElement | null>(null);
  const dropdownOpen = Boolean(dropDownAnchorEl);
  const [selectedProductsOnPost, setSelectedProductsOnPost] = useState<Product[]>(celebration?.selectedProducts || []);
  const [celebrationImg, setCelebrationImg] = useState<string | null>(celebration?.images?.at(0));
  const [authorImage, setAuthorImage] = useState<string | null>(celebration?.author?.image);
  const [congratsClicked, setCongratsClicked] = useState<boolean>(false);
  const [showGreetersNotif, setShowGreetersNotif] = useState<boolean>(newNotif);

  const handleEllipsisClick = (event: MouseEvent<HTMLElement>) => {
    setDropDownAnchorEl(event.currentTarget);
  };

  const handleEditClick = async () => {
    const latestCelebration: Celebration = await CelebrationService.getCelebrationById(celebration?.id);
    setCelebrationToEdit(latestCelebration);
    setCelebrationModalOpen(true);
    setDropDownAnchorEl(null);
  };

  const handleProductsEditClick = async () => {
    const latestCelebration: Celebration = await CelebrationService.getCelebrationById(celebration?.id);
    setCelebrationToEdit(latestCelebration);
    setShowProductsToSelect(true);
    setCelebrationModalOpen(true);
  };

  const handleDeleteClick = async () => {
    setCelebrationToDelete(celebration);
    setShowDeleteModal(true);
  };

  const handleRemoveProduct = async (productId: string) => {
    const newSelectedProducts: Product[] = celebration?.selectedProducts?.filter(selectedProduct => selectedProduct?.id !== productId);
    setSelectedProductsOnPost(newSelectedProducts);
    const updatedCelebration: Celebration = {
      ...celebration,
      selectedProducts: newSelectedProducts,
      updatedAt: Date.now()
    };
    if (handleCelebrationProductsChange) {
      handleCelebrationProductsChange(updatedCelebration);
    };
    try {
      await CelebrationService.updateCelebration(updatedCelebration);
    } catch (err) {
      console.error(err)
    };
  };

  const handleImageClick = () => {
    if (celebrationImg && celebrationImg !== EMPTY_STRING) {
      setDisplayImageModalCSS(BLOCK);
      setImageToModal(celebrationImg);
    };
  };

  const handleCongratsClick = async () => {
    const congratsAdded: boolean = !congratsClicked;
    if (congratsAdded) {
      confetti();
    };
    setCongratsClicked(!congratsClicked);
    await CongratsService.upsertCongrats({
      ...emptyCongrats,
      sender: {
        id: currentUser?.id,
        username: currentUser?.username,
        name: currentUser?.name,
        image: StorageService.getImageOrDefault(currentUser?.images),
        phone: currentUser?.phone,
        email: currentUser?.email,
        offersScope: currentUser?.offersScope
      },
      celebration: { ...celebration },
      isActive: congratsAdded,
      createdAt: Date.now(),
      updatedAt: Date.now()
    });
  };

  const handleGreetersClick = async () => {
    setCelebrationToShowCongrats(celebration);
    setCongratsModalOpen(true);
    if (personal && newNotif) {
      setShowGreetersNotif(false);
      await NotificationService.removeCongratsNotification(currentUser?.id, celebration?.id);
    };
  };

  const getWording = (type: string) : string => {
    const wording: string = wordingMap?.get(type);
    if (!wording) {
      return CELEBRATING_A;
    }
    return wording;
  };

  const updateAccImageOnError = async () => {
    try {
      const latestCelebrationAuthor: Account = await UserService.getAccountById(celebration?.author?.id);
      await CelebrationService.updateCelebration({
        ...celebration,
        author: {
          id: latestCelebrationAuthor?.id,
          username: latestCelebrationAuthor?.username,
          name: latestCelebrationAuthor?.name,
          image: StorageService.getImageOrEmpty(latestCelebrationAuthor?.images),
          phone: latestCelebrationAuthor?.phone,
          email: latestCelebrationAuthor?.email,
          offersScope: latestCelebrationAuthor?.offersScope,
        }
      });
      setAuthorImage(StorageService.getImageOrEmpty(latestCelebrationAuthor?.images));
    } catch (err) {
      console.error(err)
    };
  };

  const updateImageOnError = async () => {
    try {
      if (celebrationImg && celebrationImg !== EMPTY_STRING) {
        await getMetadata(ref(getStorage(),
          CELEBRATION_IMAGES_FOLDER+StorageService.getFileNameFromURL(celebrationImg))
        );
      }
    } catch (error) {
      if (error.code === STORAGE_OBJ_NOT_FOUND || error.toString().includes(DOES_NOT_EXIST)) {
        setCelebrationImg(EMPTY_STRING);
        await CelebrationService.updateCelebration({
          ...celebration,
          images: EMPTY_ARRAY
        });
      };
    };
  };

  useEffect(() => {
    const loadedWordingMap = new Map<string, string>();
    loadedWordingMap.set(ANNIVERSARY, CELEBRATING_AN);
    loadedWordingMap.set(ACHIEVEMENT, CELEBRATING_AN);
    loadedWordingMap.set(MARRIED, JUST_GOT);
    loadedWordingMap.set(ENGAGED, JUST_GOT);
    loadedWordingMap.set(GRADUATION, CELEBRATING);
    loadedWordingMap.set(GOAL, HIT_A);
    loadedWordingMap.set(SOMETHING_NEW, CELEBRATING);
    setWordingMap(loadedWordingMap);
  }, []);

  useEffect(() => {
    if (celebrationEdited && celebrationEdited?.id === celebration?.id) {
      setSelectedProductsOnPost(celebrationEdited?.selectedProducts);
      if (celebrationEdited?.images?.at(0) && celebrationEdited?.images?.at(0) !== EMPTY_STRING) {
        setCelebrationImg(celebrationEdited?.images?.at(0));
      } else {
        setCelebrationImg(EMPTY_STRING);
      };
    };
  }, [celebrationEdited]);

  useEffect(() => {
    if (congratulated) {
      setCongratsClicked(congratulated);
    };
  }, [congratulated]);

  useEffect(() => {
    if (newNotif) {
      setShowGreetersNotif(newNotif);
    };
  }, [newNotif]);

  return (
    <Card className='mobile-card center-width-24rem' style={{ padding: "12px 0px 0px 0px", marginBottom: "30px", borderBottomLeftRadius: `${selectedProductsOnPost?.length > 0 ?  0 : "0.75rem"}`,
      borderBottomRightRadius: `${selectedProductsOnPost?.length > 0 ?  0 : "0.75rem"}` }}>
      <CardHeader style={{ textAlign: LEFT, padding: "0px 8px 15px" }}
        avatar={<ProfileImg40x40Left id="myImg" src={StorageService.getImageOrDefault([authorImage])} onError={updateAccImageOnError} className="profile-img"
          onClick={() => window.location.assign(`/${celebration?.author?.username}`)} />}
        title={<>
          <CardHeaderGrid container direction="row" justifyContent="flex-start" alignItems="center">
            <Text16Gray style={{ marginTop: "0px" }} onClick={() => window.location.assign(`/${celebration?.author?.username}`)}>
              {celebration?.author?.name?.trim() !== EMPTY_STRING ? celebration?.author?.name : celebration?.author?.username}
            </Text16Gray>
            <Tooltip title={moment(celebration?.createdAt).format('ll')} enterDelay={2000} enterNextDelay={1000} placement='right'>
              <Time>
                <DotSeparator style={{ top: "1px" }}>•</DotSeparator>
                <span style={{ top: "2px", position: "relative" }}>{moment(celebration?.createdAt).fromNow(true)}</span>
              </Time>
            </Tooltip>
          </CardHeaderGrid>
          {personal && <>
            <TopRightIconButton onClick={handleEllipsisClick}>
              <i className="fa-solid fa-ellipsis" />
            </TopRightIconButton>
            <Menu anchorEl={dropDownAnchorEl} open={dropdownOpen} onClose={() => setDropDownAnchorEl(null)} onClick={() => setDropDownAnchorEl(null)} PaperProps={ellipsisDropDownCSS}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <MenuItem onClick={handleEditClick}>
                <ListItemIcon><i className="fa-solid fa-pen" /></ListItemIcon>
                Edit celebration
              </MenuItem>
              <MenuItem onClick={handleDeleteClick}>
                <ListItemIcon><i className="fa-regular fa-trash-can" /></ListItemIcon>
                Move to bin
              </MenuItem>
            </Menu></>
          }</>
        }
        subheader={personal &&
          <Text16Gray style={{ fontSize: "12px" }}>
            { celebration?.scope === EVERYONE && <ScopeIcon className="fa-solid fa-earth-africa" style={{ fontSize: "11px", bottom: "0px" }} /> }
            { celebration?.scope === CONTACTS && <ScopeIcon className="fa-solid fa-user-group" /> }
            {celebration?.scope?.charAt(0).toUpperCase() + celebration?.scope?.slice(1)}
          </Text16Gray>
        }
      />
      <CardContent style={{ textAlign: LEFT, padding: "0px" }}>
        {(celebration?.type?.trim() !== EMPTY_STRING || celebration?.description?.trim() !== EMPTY_STRING) &&
          <TypeDescriptionDiv>
            {celebration?.type?.trim() !== EMPTY_STRING &&
              <TypeBackground>
                <TypeText>
                  <TypeIcon>🎉</TypeIcon>
                  {getWording(celebration?.type)}
                  <DarkText>{celebration?.type}!</DarkText>
                </TypeText>
              </TypeBackground>
            }
            {celebration?.description?.trim() !== EMPTY_STRING &&
              <div style={{ marginBottom: "20px" }}>
                {celebration?.description.split(/\r?\n/)?.map((text, i, arr) => (formatMessage(text, i, arr[i - 1])))}
              </div>
            }
          </TypeDescriptionDiv>
        }
        {celebrationImg && celebrationImg !== EMPTY_STRING &&
          <CardMedia component="img" height="300" image={celebrationImg} onError={updateImageOnError} onClick={handleImageClick}
            style={{ cursor: "pointer", padding: "0px", objectFit: "contain", height: "fit-content", maxHeight: "600px" }} className='min-height-mobile' />
        }
        <div style={{ borderTop: "1px solid #CED0D4", margin: "0px auto" }} />
        {!personal &&
          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: "12px" }}>
            <InlineButton onClick={() => sendGiftHelper(currentUser, celebration?.author, signOut)}>
              <BaseLeftIcon className="fas fa-gift" />Gift
            </InlineButton>
            <div>
              <InlineButton onClick={handleGreetersClick}>
                <ClapIcon className="fa-solid fa-hands-clapping" />
              </InlineButton>
              <PoppersImage src={congratsClicked ? PopperFull : PopperOutline} onClick={handleCongratsClick} />
            </div>
          </Grid>
        }
        {personal &&
          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: "12px" }}>
            <InlineButton onClick={handleProductsEditClick}>
              <BaseLeftIcon className="fas fa-gift" />
              {selectedProductsOnPost?.length > 0 ?
                <>Edit</>
                :
                <>Add</>
              }
            </InlineButton>
            <InlineButton style={{ position: "relative" }} onClick={handleGreetersClick}>
              <GreetersNotifButton className={`greeters-notification ${showGreetersNotif ? ACTIVE : null }`} />
              <ClapIcon className="fa-solid fa-hands-clapping" />
            </InlineButton>
          </Grid>
        }
        {selectedProductsOnPost?.length > 0 && <>
          <div style={{ height: "10px", backgroundColor: "#f0f2f5", borderTop: "1px solid #CED0D4" }}></div>
          <VerticalScroll>
            {selectedProductsOnPost?.map(product => (
              <ProductItem container direction="row" justifyContent="flex-start" key={product?.id} alignItems="center" className='center-width-24rem'>
                <ProfileImg60x60Left onClick={() => window.open(product?.url)} src={product?.images?.at(0)} className='smaller-mobile-profile-img'
                  style={{ borderRadius: "0px", marginRight: "12px" }} />
                <DetailsGiftsList item onClick={() => window.open(product?.url)}>
                  <TextGiftsList style={{ fontSize: "15px", marginBottom: "5px" }}>{product?.title}</TextGiftsList>
                  <SubText>{product?.shop?.name}</SubText>
                  <SubText>£{product?.price?.amount}</SubText>
                </DetailsGiftsList>
                <ConnectionsIconButton style={{ position: "absolute", right: "8px" }}>
                { !personal && <IconSize16 className="fa-solid fa-gift" onClick={() => displayingOnFeed ? handleGiftIconClick(product, celebration?.author) : handleGiftIconClick(product)} /> }
                { personal && <IconSize16 className="fa-solid fa-trash-can" onClick={() => handleRemoveProduct(product?.id)} /> }
                </ConnectionsIconButton>
              </ProductItem>
            ))}
          </VerticalScroll>
          <div style={{ height: "10px", backgroundColor: "#f0f2f5" }}></div></>
        }
      </CardContent>
    </Card>
  );
};

export default Celebration;
