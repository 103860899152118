import { FC } from 'react';
import { Grid } from '@mui/material';
import { Modal } from 'react-bootstrap';

import { NoWidthButton, ModalBody, Heading as BaseHeading, Text, LowPriorityButton, TrophyImg } from './styled';
import styled from '@emotion/styled';
import Trophy from "../../images/trophy.svg";
import { goToEarnSetup } from '../../services/helpers';

const Heading = styled(BaseHeading)`
  margin: 0px auto;
  display: block;
  text-align: center;
  font-size: 25px;
`

const BannerImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`

interface PromoModalProps {
  showShareInfoModal: boolean;
  setShowShareInfoModal: (val: boolean) => void;
};
const PromoModal: FC<PromoModalProps> = ({ showShareInfoModal, setShowShareInfoModal }) => {
  return (<>
    <Modal show={showShareInfoModal} onHide={() => setShowShareInfoModal(false)} centered size="sm">
      <ModalBody style={{ marginBottom: "0px" }}>
        <BannerImg src='https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/media%2Fcelebration-banner-prop.jpg?alt=media&token=2ab8c538-9e13-4065-9228-4dd3981a0915' />
        <div className='center' style={{ marginBottom: "15px" }}>
          <Heading style={{ display: "inline" }}>Earn points</Heading>
          <TrophyImg style={{ width: "38px", bottom: "10px" }} src={Trophy} />
        </div>
        <Text style={{ margin: "20px 0px" }}>Send a gift to an animal shelter or someone you want to connect with and earn points! 🏅</Text>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "30px" }}>
          <LowPriorityButton onClick={() => setShowShareInfoModal(false)}>Later</LowPriorityButton>
          <NoWidthButton onClick={goToEarnSetup}>Send a gift</NoWidthButton>
        </Grid>
      </ModalBody>
    </Modal>
    </>
  )
}

export default PromoModal;
