import { signOut } from "firebase/auth";

import { auth } from './index';
import { ALPHA_NUMERIC_CHARS, EMPTY_STRING, INVITE_ID_LENGTH } from "../constants";

export const logOut = async () : Promise<void> => {
  try {
    await signOut(auth);
  } catch (e) {
    console.error("Firebase Error signing out: ", e);
  }
}

export const randomStringGenerator = () : string => {
  let result = EMPTY_STRING;
  for (let i = 0; i < INVITE_ID_LENGTH; i++) {
    result += ALPHA_NUMERIC_CHARS.charAt(Math.floor(Math.random() * ALPHA_NUMERIC_CHARS?.length));
  }
  return result;
}

export const getRndInteger = (min:number, max:number) : string => {
  const random: number = Math.floor(Math.random() * (max - min)) + min;
  return random.toString();
}
