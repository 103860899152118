import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Skeleton, Paper, Grid, Fab } from '@mui/material';
import styled from '@emotion/styled';

import Product from '../children/Product';
import GiftingForm from './GiftingForm';
import { NoWidthButton, ZeroMarginGrid, Text, MainDiv, TagDiv } from '../children/styled';
import { AuthContext } from '../../providers/AuthProvider';
import { displayShareInfoModal, addOrDeleteTagHelper, sendToProfile, resetTagsHelper } from '../../services/helpers';
import { EMPTY_STRING, ENTER, TAGS_LIST } from '../../constants';
import UserService from '../../services/userService';
import WishlistForm from '../children/WishlistForm';
import ReactionService from '../../services/reactionService';
import WishlistService from '../../services/wishlistService';
import ProductService from '../../services/productService';
import PromoModal from '../children/PromoModal';
import TagsStrip from '../children/TagsStrip';
import TagsModal from '../children/TagsModal';

const NotificationBar = styled(Paper)`
   padding: 10px 20px;
   background-color: #8dd4f6;
   border: 1px solid #8dd4f6;
   position: relative;
   margin-bottom: 20px;
`

const InputContainer = styled.div`
   position: relative;
   width: 95%;
   max-width: 400px;
`

const Input = styled.input`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00);
  font-size: 16px;
  padding: 10px;
  width: 100%;
  color: rgba(30, 28, 36, 0.85);

  &:focus {
   outline: none;
  }
`

const Button = styled(NoWidthButton)`
   margin-left: 10px;
   margin-bottom: 0px;
   padding: 2px 7px;
   min-height: 0px;
   outline: white;
`

const CloseIcon = styled.i`
   position: absolute;
   right: 8px;
   top: 6px;
   color: rgb(101, 103, 107);
   font-size: 15px;
   cursor: pointer;
`

const TopButton = styled(Fab)`
   position: fixed;
   right: 15px;
   display: none;
   width: 40px;
   height: 40px;
`

const Products = () => {
   const [selectedProduct, setSelectedProduct] = useState<Product | null>();
   const [giftingForm, setGiftingForm] = useState<boolean>(false);
   const [productLink, setProductLink] = useState<string>(EMPTY_STRING);
   const [productSuggestionOpen, setProductSuggestionOpen] = useState<boolean>(true);
   const [wishListFormOpen, setWishListFormOpen] = useState<boolean>(false);
   const [userReactions, setUserReactions] = useState<Reaction[]>([]);
   const [userWishlistItems, setUserWishlistItems] = useState<WishlistItem[]>([]);
   const [products, setProducts] = useState<Product[]>([]);
   const [userLink, setUserLink] = useState<string>(EMPTY_STRING);
   const [showShareInfoModal, setShowShareInfoModal] = useState<boolean>(false);
   const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
   const [tagsOnArray, setTagsOnArray] = useState<boolean[]>(new Array(TAGS_LIST?.length).fill(false));
   const [loading, setLoading] = useState<boolean>(false);

   const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);

   const handleProductLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
      setProductLink(event.target.value);
   };

   const handleProductSuggestionCloseClick = () => {
      setProductSuggestionOpen(false);
   };

   const handleCreateOfferClick = () => {
      if (productLink?.trim() === EMPTY_STRING) {
         return;
      };
      setWishListFormOpen(true);
   };

   const handleEnterClick = (e: any) => {
      if (e.key === ENTER) {
         handleCreateOfferClick();
      }
   };

   const handleAddOrDeleteTag = async (index: number, isAdding: boolean) => {
      await addOrDeleteTagHelper(index, isAdding, tagsOnArray, setLoading, setTagsOnArray, setProducts);
   };

   const handleResetClick = async () => {
      await resetTagsHelper(setLoading, setTagsOnArray, setProducts);
   };

   // const handleCustomCreateClick = async () => {
   //    if (!currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
   //       signOut();
   //       window.location.assign(LOGIN_PATH);
   //       return;
   //    }
   //    setGiftingForm(true);
   // };

   const getLatestUserDetails = async () => {
      const latestUser: Account = await UserService.getAccountById(currentUser?.id);
      setCurrentUserToStorage(latestUser);
   };

   const getAllReactions = async () => {
      const reactions: Reaction[] = await ReactionService.getReactionsByUserId(currentUser?.id);
      setUserReactions(reactions);
   };

   const getAllWishlistItems = async () => {
      const wishlistItems: WishlistItem[] = await WishlistService.getWishlistsByUserId(currentUser?.id);
      setUserWishlistItems(wishlistItems);
   };

   const getProducts = async () => {
      try {
         const productsList: Product[] = await ProductService.getProducts();
         setProducts(productsList);
      } catch (err) {
         console.error("Error loading Products: ", err)
      }
   };

   useEffect(() => {
      getProducts();
      if (currentUser) {
         if (!displayShareInfoModal(currentUser?.username, setUserLink, setShowShareInfoModal)) {
            sendToProfile();
         };
         getLatestUserDetails();
         getAllReactions();
         getAllWishlistItems();
      };
   }, []);

   return (<>
      <MainDiv>
         {giftingForm && !wishListFormOpen && <GiftingForm productObj={selectedProduct} setGiftingForm={setGiftingForm} /> }
         {!giftingForm && wishListFormOpen && <WishlistForm link={productLink} />}
         {!giftingForm && !wishListFormOpen && <>
            {productSuggestionOpen &&
               <NotificationBar elevation={0} className="margin-bottom-ipad-10">
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0.5} columns={{ xs: 1, sm: 1, md: 9 }}>
                     <Text className='margin-bottom-825' style={{ marginRight: "10px", marginBottom: "0px", fontSize: "14px" }}>
                        Don't see what you like? Enter the link to the gift that you want:
                     </Text>
                     <InputContainer>
                        <Input type='text' placeholder="https://" value={productLink} onChange={handleProductLinkChange}
                           onKeyDown={handleEnterClick} required style={{ fontSize: "14px", padding: "7px 10px" }} />
                     </InputContainer>
                     <Button className="dropdown" onClick={handleCreateOfferClick}>Add to wishlist</Button>
                  </Grid>
                  <CloseIcon onClick={handleProductSuggestionCloseClick} className="fa-solid fa-xmark" />
               </NotificationBar>
            }
            <TagDiv className='center'>
               <TagsStrip setShowTagsModal={setShowTagsModal} tagsOnArray={tagsOnArray} handleAddOrDeleteTag={handleAddOrDeleteTag} />
            </TagDiv>
            <ZeroMarginGrid container alignItems="center" spacing={4} columns={{ xs: 1, sm: 1, md: 12, lg: 12 }} columnSpacing={{ xs: 0.5 }}>
               {!loading && products && products?.length > 0 ?
                  <>
                     {/* <Card style={{ height: "556px", top: "16px", maxWidth: "23.5rem" }}>
                     <ImgContainter style={{ height: "235px" }}>
                        <ProductImg onClick={handleCustomCreateClick} style={{ width: "40%", marginBottom: "0px", marginTop: "30px" }}
                           src="https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/media%2Fwhite-gift.png?alt=media&token=3c8badc5-47d6-4355-9a89-29a6f193ab58" />
                     </ImgContainter>
                     <Body>
                        <UnderlineDiv onClick={handleCustomCreateClick} style={{ marginBottom: "35px" }}>
                           <RbCard.Title>Send a Custom Gift</RbCard.Title>
                           <CardText>Can't find a gift to send?</CardText>
                           <CardText style={{ lineHeight: "22px" }}>Add a custom gift and we'll send it like any other gift.</CardText>
                        </UnderlineDiv>
                        <BigButton onClick={handleCustomCreateClick} style={{ position: "relative", top: "60px" }}>CREATE</BigButton>
                     </Body>
                  </Card> */}
                     {products && products?.map(product => (
                        <ZeroMarginGrid item xs={1} sm={1} md={6} lg={4} key={product?.id}>
                           <Product product={product} setGiftingForm={setGiftingForm} setSelectedProduct={setSelectedProduct} setProductSuggestionOpen={setProductSuggestionOpen}
                              userReactions={userReactions} userWishlistItems={userWishlistItems} />
                        </ZeroMarginGrid>
                     ))}
                  </>
                  :
                  <>
                     {[0, 1, 2, 3, 4, 5].map(i => (
                        <ZeroMarginGrid item xs={1} sm={1} md={6} lg={4} key={i}>
                           <Skeleton variant="rounded" width={250} height={200} style={{ margin: "auto" }} />
                           <Skeleton width={250} style={{ margin: "auto" }} />
                        </ZeroMarginGrid>
                     ))}
                  </>
               }
            </ZeroMarginGrid></>
         }
      </MainDiv>
      <TopButton aria-label="Scroll to top" className='mobile-only' onClick={() => window.scrollTo(0, 0)} style={{ bottom: `${currentUser ? "70px": "20px"}` }}>
        <i className="fa-solid fa-chevron-up" />
      </TopButton>
      <TagsModal showTagsModal={showTagsModal} setShowTagsModal={setShowTagsModal} tagsOnArray={tagsOnArray} handleAddOrDeleteTag={handleAddOrDeleteTag}
         handleResetClick={handleResetClick} />
      <PromoModal showShareInfoModal={showShareInfoModal} setShowShareInfoModal={setShowShareInfoModal} />
      {/* <ReactionsModal product={selectedProduct} likesClicked={likesClicked} setLikesClicked={setLikesClicked} reactionsModalOpen={reactionsModalOpen}
      setReactionsModalOpen={setReactionsModalOpen} setGiftingForm={setGiftingForm} setSelectedProduct={setSelectedProduct} /> */}</>
   );
};

export default Products;
