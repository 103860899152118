import { FC, useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { CircularProgress, Grid as MuiGrid } from '@mui/material';
import moment from 'moment';
import { User, onAuthStateChanged } from "firebase/auth";

import { AuthContext } from '../../providers/AuthProvider';
import {
  CONNECTED, BASE_URL, PENDING, LOGIN_PATH, CONNECTIONS_ADDED, EMPTY_STRING,
  EVERYONE, SLASH, REQUESTS_PATH, CONNECTIONS_PATH, CONNECTION_REQUESTS
} from '../../constants';
import {
  ButtonsGroup, GridMarginRight, ConnectionsIcon, ConnectionsIconButton, ProfileImg60x60Left, ForwardToInboxIcon, Item, MainDiv, NoWidthButton, SubText,
  TextBigger, PageHeading, Tab, TabHover, TabButtonText, TabHr, CenterDiv
} from '../children/styled';
import UserService from '../../services/userService';
import { auth } from '../../firebase';
import ConnectionService from '../../services/connectionService';
import { displayShareInfoModal, sendGiftHelper, removeNotification, sendToProfile } from '../../services/helpers';
import StorageService from '../../services/storageService';
import PromoModal from '../children/PromoModal';

const Button = styled(NoWidthButton)`
  min-height: 35px;
`

interface ConnectionsProp {
  requestsPath: boolean
}
const Connections: FC<ConnectionsProp> = ({ requestsPath }) => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [requestsOnly, setRequestsOnly] = useState<Connection[]>([]);
  const [otherConnections, setOtherConnections] = useState<Connection[]>([]);
  const [showRequests, setShowRequests] = useState<boolean>(requestsPath);
  const [loading, setLoading] = useState<boolean>(true);
  const [canGiftReferenceMap, setCanGiftReferenceMap] = useState(new Map());
  const [showShareInfoModal, setShowShareInfoModal] = useState<boolean>(false);
  const [userLink, setUserLink] = useState<string>(EMPTY_STRING);

  const handleRemoveClick = async (connToRemove: Connection) => {
    await ConnectionService.updateConnectionRemoved(connToRemove?.requester?.id, connToRemove?.receiver?.id);
    setRequestsOnly(requestsOnly?.filter(conn => conn?.id !== connToRemove?.id));
  };

  const handleConfirmClick = async (connToConfirm: Connection) => {
    await ConnectionService.updateConnectionAccepted(connToConfirm?.requester?.id, connToConfirm?.receiver?.id);
    setRequestsOnly(requestsOnly?.filter(conn => conn?.id !== connToConfirm?.id));
  };

  const updateUsersInConnection = async (connection: Connection) => {
    const updatedRequesterObj = await UserService.getAccountById(connection?.requester?.id);
    const updatedReceiverObj = await UserService.getAccountById(connection?.receiver?.id);
    await ConnectionService.updateUsersDetails({ ...connection,
      requester: {
        id: updatedRequesterObj?.id,
        username: updatedRequesterObj?.username,
        name: updatedRequesterObj?.name,
        image: StorageService.getImageOrEmpty(updatedRequesterObj?.images),
        phone: updatedRequesterObj?.phone,
        email: updatedRequesterObj?.email,
        offersScope: updatedRequesterObj?.offersScope
      },
      receiver: {
        id: updatedReceiverObj?.id,
        username: updatedReceiverObj?.username,
        name: updatedReceiverObj?.name,
        image: StorageService.getImageOrEmpty(updatedReceiverObj?.images),
        phone: updatedReceiverObj?.phone,
        email: updatedReceiverObj?.email,
        offersScope: updatedReceiverObj?.offersScope
      }});
  }

  const handleAccountClick = (connection: Connection) => {
    if (currentUser?.id === connection?.requester?.id) {
      window.location.assign(BASE_URL + SLASH + connection?.receiver?.username);
    } else {
      window.location.assign(BASE_URL + SLASH + connection?.requester?.username);
    }
  };

  const handleGiftClick = async (connection: Connection) => {
    if (currentUser?.id === connection?.requester?.id) {
      await sendGiftHelper(currentUser, connection?.receiver, signOut);
    } else {
      await sendGiftHelper(currentUser, connection?.requester, signOut);
    };
  };

  const getOtherUser = (connection: Connection) : SubUser => {
    if (currentUser?.id === connection?.requester?.id) {
      return connection?.receiver;
    }
    return connection?.requester;
  };

  const getConnections = async (firstTimeOnly?: boolean) : Promise<void> => {
    setLoading(true);
    try {
      const connectionsForCurrentUser: Connection[] = await ConnectionService.getConnectionsByUserId(currentUser?.id);
      if (connectionsForCurrentUser && connectionsForCurrentUser?.length > 0) {
        const requestsList: Connection[] = connectionsForCurrentUser?.filter(con => con?.receiver?.id === currentUser?.id && con?.status === PENDING);
        const allList: Connection[] = connectionsForCurrentUser?.filter(con => con?.status === CONNECTED || (currentUser?.id === con?.requester?.id && con?.status === PENDING));
        setRequestsOnly(requestsList);
        setOtherConnections(allList);
        if (firstTimeOnly && allList?.length === 0 && requestsList?.length > 0) {
          setShowRequests(true);
        };
      };
    } catch (err) {
      console.error("Error loading connections: ", err)
    };
    setLoading(false);
  };

  const handleTabClick = async (requestsClicked: boolean) => {
    if (requestsClicked) {
      setShowRequests(true);
    } else {
      setShowRequests(false);
    };
    await getConnections();
  };

  const canGiftNonAsync = (giftee: SubUser, connectionStatus: string) : boolean => {
    if (currentUser?.id === giftee?.id) {
      return false;
    };
    if ((connectionStatus === CONNECTED) || (giftee?.offersScope && giftee?.offersScope === EVERYONE)) {
      return true;
    };
    return false;
  }

  const updateImageOnError = async (connection: Connection, userId: string, inRequests: boolean) => {
    const fullUserObj: Account = await UserService.getAccountById(userId);
    if (inRequests) {
      setRequestsOnly( requestsOnly?.map(conn => (conn?.requester?.id === userId ? { ...conn, requester: { ...conn?.requester, image: StorageService.getImageOrEmpty(fullUserObj?.images) }  } : conn))?.map(conn => (conn?.receiver?.id === userId ? { ...conn, receiver: { ...conn?.receiver, image: StorageService.getImageOrEmpty(fullUserObj?.images) }  } : conn)) );
    } else {
      setOtherConnections( otherConnections?.map(conn => (conn?.requester?.id === userId ? { ...conn, requester: { ...conn?.requester, image: StorageService.getImageOrEmpty(fullUserObj?.images) }  } : conn))?.map(conn => (conn?.receiver?.id === userId ? { ...conn, receiver: { ...conn?.receiver, image: StorageService.getImageOrEmpty(fullUserObj?.images) }  } : conn)) );
    };
    await updateUsersInConnection(connection);
  };

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
    });
  };

  useEffect(() => {
    setUp();
    if (!displayShareInfoModal(currentUser?.username, setUserLink, setShowShareInfoModal)) {
      sendToProfile();
    };
    sendToProfile();
    getConnections(true);
  }, []);

  useEffect(() => {
    const updateConnectionsGifting = async () => {
      if (otherConnections?.length > 0) {
        const canGiftReferenceCopy = new Map();
        for (const connection of otherConnections) {
          if (canGiftNonAsync(getOtherUser(connection), connection?.status)) {
            canGiftReferenceCopy?.set(connection?.id, true);
          };
        };
        setCanGiftReferenceMap(canGiftReferenceCopy);
      }
    };

    updateConnectionsGifting();
  }, [otherConnections]);

  useEffect(() => {
    if (showRequests) {
      window.history.pushState(null, EMPTY_STRING, REQUESTS_PATH);
      removeNotification(currentUser?.id, CONNECTION_REQUESTS);
    } else {
      window.history.pushState(null, EMPTY_STRING, CONNECTIONS_PATH);
      removeNotification(currentUser?.id, CONNECTIONS_ADDED);
    };
  }, [showRequests]);

  return (<>
    <MainDiv>
      <PageHeading className='center-width-24rem' style={{ marginBottom: "7px" }}>Connections</PageHeading>
      <MuiGrid container direction="row" justifyContent="space-around" alignItems="center" className='center-width-24rem'
        style={{ marginBottom: "40px", borderBottom: "1px solid #CED0D4" }}>
        {!showRequests && <>
          <Tab><TabButtonText>All {otherConnections?.length > 0 && <>({otherConnections?.length})</>}</TabButtonText><TabHr /></Tab>
          <TabHover onClick={() => handleTabClick(true)}>Requests {requestsOnly?.length > 0 && <>({requestsOnly?.length})</>}</TabHover></>
        }
        {showRequests && <>
          <TabHover onClick={() => handleTabClick(false)}>All {otherConnections?.length > 0 && <>({otherConnections?.length})</>}</TabHover>
          <Tab><TabButtonText>Requests {requestsOnly?.length > 0 && <>({requestsOnly?.length})</>}</TabButtonText><TabHr /></Tab></>
        }
      </MuiGrid>
      {loading &&
        <CenterDiv style={{ height: "50px", marginTop: "30px" }}>
          <CircularProgress style={{ width: "30px", height: "30px" }} />
        </CenterDiv>
      }
      {!loading && <>
        {showRequests && requestsOnly?.length > 0 && <>
          {requestsOnly?.sort((con1, con2) => con2?.updatedAt - con1?.updatedAt)?.map(requestCon => (
            <Item container direction="row" justifyContent="flex-start" alignItems="center" key={requestCon?.id} className='center-width-24rem'>
              <GridMarginRight item onClick={() => handleAccountClick(requestCon)} style={{ margin: "0px" }}>
                <MuiGrid container direction="row" justifyContent="flex-start" alignItems="center">
                  <ProfileImg60x60Left src={StorageService.getImageOrDefault([requestCon?.requester?.image])} onClick={() => handleAccountClick(requestCon)}
                    onError={() => updateImageOnError(requestCon, requestCon?.requester?.id, true)} className='smaller-mobile-profile-img' />
                  <div>
                    <TextBigger className="width-145-max-300">{requestCon?.requester?.name !== EMPTY_STRING && requestCon?.requester?.name}</TextBigger>
                    <SubText className="width-145-max-300">@{requestCon?.requester?.username}</SubText>
                    <SubText style={{ fontSize: "14px" }}>
                      {moment(requestCon?.updatedAt).fromNow(true)}
                    </SubText>
                  </div>
                </MuiGrid>
              </GridMarginRight>
              <ButtonsGroup item xs={12} className="connections-buttons">
                <div style={{ marginTop: "5px" }}>
                  <Button onClick={() => handleRemoveClick(requestCon)}
                    style={{ backgroundColor: "#E4E6EB", border: "solid 1px #E4E6EB", color: "#050505", marginRight: "10px" }}>Remove</Button>
                  <Button onClick={() => handleConfirmClick(requestCon)}>Confirm</Button>
                </div>
              </ButtonsGroup>
            </Item>))
          }</>
        }
        {showRequests && requestsOnly?.length === 0 &&
          <TextBigger className='center-width-24rem'>You do not have any connection requests at the moment.</TextBigger>
        }
        {!showRequests && otherConnections?.length > 0 && <>
          {otherConnections?.sort((con1, con2) => con2?.updatedAt - con1?.updatedAt)?.map(connection => (
            <Item container direction="row" justifyContent="flex-start" alignItems="center" key={connection?.id} className='center-width-24rem'>
              <ProfileImg60x60Left src={StorageService.getImageOrDefault([getOtherUser(connection).image])} onClick={() => handleAccountClick(connection)}
                onError={() => updateImageOnError(connection, getOtherUser(connection)?.id, false)} className='smaller-mobile-profile-img' />
              <GridMarginRight item onClick={() => handleAccountClick(connection)}>
                <TextBigger className="width-145-max-300">{getOtherUser(connection).name !== EMPTY_STRING && getOtherUser(connection).name}</TextBigger>
                <SubText className="width-145-max-300">@{getOtherUser(connection).username}</SubText>
                {connection?.status === PENDING &&
                  <SubText style={{ marginTop: "10px", marginBottom: "3px" }}>
                    <ForwardToInboxIcon />
                    <span style={{ fontStyle: "italic" }}>Pending</span>
                  </SubText>
                }
                <SubText style={{ fontSize: "14px" }}>
                  {moment(connection?.updatedAt).fromNow(true)}
                </SubText>
              </GridMarginRight>
              {canGiftReferenceMap?.get(connection?.id) &&
                <ButtonsGroup>
                  <ConnectionsIconButton onClick={() => handleGiftClick(connection)}>
                    <ConnectionsIcon className="fas fa-gift" />
                  </ConnectionsIconButton>
                </ButtonsGroup>
              }
            </Item>))
          }</>
        }
        {!showRequests && otherConnections?.length === 0 &&
          <TextBigger className='center-width-24rem'>You do not have any connections at the moment.</TextBigger>
        }
      </>}
    </MainDiv>
    <PromoModal showShareInfoModal={showShareInfoModal} setShowShareInfoModal={setShowShareInfoModal} />
    </>
  );
};

export default Connections;
