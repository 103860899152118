import { doc, updateDoc, getDoc, setDoc, query, where, getDocs, collection, orderBy, startAt, endAt, limit, deleteDoc } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { ACCOUNTS, CHARS, EMPTY_STRING, EQUALS, ID, ORG, SUGGESTED, SUGGESTED_ACCOUNT_LIMIT, TYPE, USERNAME } from "../constants";

const getAllAccounts = async () : Promise<Account[]> => {
  try {
    const accountsSnapshot = await getDocs(collection(firestoreDb, ACCOUNTS));
    return accountsSnapshot?.docs?.map(doc => doc.data() as Account);
  } catch (e) {
    console.error("Error in getting all accounts: ", e);
  }
}

const getOrgAccounts = async () : Promise<Account[]> => {
  try {
    const accountsSnapshot = await getDocs(
      query(collection(firestoreDb, ACCOUNTS), where(TYPE, EQUALS, ORG))
    );
    const accounts = accountsSnapshot.docs;
    return accounts?.map(doc => doc.data() as Account);
  } catch (e) {
    console.error("Error in getting org accounts: ", e);
  }
}

const getSuggestedAccounts = async () : Promise<Account[]> => {
  try {
    const accountsSnapshot = await getDocs(query(
      collection(firestoreDb, ACCOUNTS),
      where(SUGGESTED, EQUALS, true),
      orderBy(ID),
      startAt(CHARS.at(Math.floor(Math.random() * CHARS.length))),
      limit(SUGGESTED_ACCOUNT_LIMIT)
    ));
    return accountsSnapshot.docs.map(doc => doc.data() as Account);
  } catch (e) {
    console.error("Error in getting suggested accounts: ", e);
  }
}

const getAccountById = async (userId: string) : Promise<Account> => {
  try {
    const accountRef = doc(firestoreDb, ACCOUNTS, userId);
    const account = await getDoc(accountRef);
    if (account.exists()) {
      return account.data() as Account;
    }
    return null;
  } catch (e) {
    console.error("Error getting account by id: ", e);
  }
}

const updateAccount = async (accountObj: Account) : Promise<void> => {
  try {
    const accountRef = doc(firestoreDb, ACCOUNTS, accountObj.id);
    const account = await getDoc(accountRef);
    if (account.exists()) {
      await updateDoc(accountRef, { ...accountObj });
    } else {
      await setDoc(accountRef, { ...accountObj });
    }
  } catch (e) {
    console.error("Error creating or updating user: ", e);
  }
}

const getAccountByUsername = async (username: string) : Promise<Account> => {
  try {
    const accountsRef = collection(firestoreDb, ACCOUNTS);
    const accountsSnapshot = await getDocs(
      query(accountsRef, where(USERNAME, EQUALS, username))
    );
    const accounts = accountsSnapshot.docs;
    if (accounts?.length === 1) {
      return accounts[0].data() as Account;
    }
    return null;
  } catch (e) {
    console.error("Error getting user: ", e);
  }
}

const getAccountsStartingWith = async (subString: string) : Promise<Account[]> => {
  try {
    const accountsRef = collection(firestoreDb, ACCOUNTS);
    const accountsSnapshot = await getDocs(
      query(accountsRef, orderBy(USERNAME), startAt(subString), endAt(subString+'\uf8ff'), limit(5))
    );
    const accounts = accountsSnapshot.docs;
    if (accounts?.length > 0) {
      return accounts?.map(connection => connection.data() as Account);
    }
    return null;
  } catch (e) {
    console.error("Error getting users starting with a substring: ", e);
  }
}

const deleteUser = async (accountId: string) : Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, ACCOUNTS, accountId));
  } catch (e) {
    console.error("Error deleting user by id: ", e);
  }
}

const containsSpecialChars = (str: string) : boolean => {
  const specialChars = /[`!@#$%^&*()+\-=\[\]{};'’:"\\|,<>\/?~\s/]/;
  return specialChars.test(str);
}

const getNameOrUsername = (name: string, username: string) : string => {
  if (name && name.trim() !== EMPTY_STRING) {
    const firstname = name?.split(" ")[0];
    return firstname.charAt(0).toUpperCase() + firstname.slice(1);
  }
  return `@${username}`;
}

const capitalizeFullname = (fullname: string) : string => {
  const names = fullname.split(" ");
  if (names?.length > 1) {
    return names[0].charAt(0).toUpperCase() + names[0].slice(1) + " " + names[1].charAt(0).toUpperCase() + names[1].slice(1);
  } else if (names?.length === 1) {
    return names[0].charAt(0).toUpperCase() + names[0].slice(1);
  }
  return EMPTY_STRING;
}

const UserService = {
  getAllAccounts,
  getSuggestedAccounts,
  getAccountById,
  getOrgAccounts,
  getAccountByUsername,
  getNameOrUsername,
  getAccountsStartingWith,
  updateAccount,
  containsSpecialChars,
  capitalizeFullname,
  deleteUser
}

export default UserService;
