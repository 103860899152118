import styled from '@emotion/styled';
import tw from "twin.macro";
import { Card as RbCard, Modal, Spinner as RbSpinner } from 'react-bootstrap';
import { GroupAdd, Person, Logout, Group, ForwardToInbox } from '@mui/icons-material';
import {
  Grid as MuiGrid, CardActions as MuiCardActions, IconButton as MuiIconButton, Skeleton, Stack as MuiStack,
  CardHeader as MuiCardHeader, CardContent as MuiCardContent, Tooltip as MuiTooltip
} from '@mui/material';

export const NoWidthButton = styled.button`
  background-color: #8dd4f6;
  border: solid 1px #fff;
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  line-height: 23.8px;
  font-size: 17px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  padding: 6px 15px;
  margin-right: 5px;
  margin-bottom: 7px;

  &:hover {
    background-color: #8dd4f6;
    border: solid 1px #8dd4f6;
  }
  &:focus {
    border: solid 1px #8dd4f6;
  }
`

export const LowPriorityButton = styled.button`
  background-color: #E4E6EB;
  border: solid 1px #fff;
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  line-height: 23.8px;
  font-size: 17px;
  text-align: center;
  color: #050505;
  font-weight: 500;
  padding: 6px 15px;
  margin-right: 5px;
  margin-bottom: 7px;

  &:hover {
    background-color: #E4E6EB;
    border: solid 1px #E4E6EB;
  }
  &:focus {
    border: solid 1px #E4E6EB;
  }
`

export const Button = styled.button`
  width: 45%;
  margin: 0px auto;
  display: block;
  line-height: 23.8px;
  min-height: 40px;
  background-color: #8dd4f6;
  border: solid 1px #8dd4f6;
  border-radius: 64px;
  padding: 12px 25px;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  min-width: 115px;
  cursor: pointer;

  &:hover {
    background-color: #8dd4f6;
    border: solid 1px #8dd4f6;
  }
  &:focus {
    border: solid 1px #8dd4f6;
  }
`

export const PostButton = styled.div`
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  text-align: center;
  color: #65676B;
  font-weight: 500;
  width: 50%;

  &:hover {
    background-color: #F0F2F5;
    border: solid 1px #F0F2F5;
    border-radius: 5px;
  }
  &:focus {
    border: solid 1px #F0F2F5;
  }
`

export const ButtonDiv = styled.div`
  background-color: #8dd4f6;
  border: solid 1px #8dd4f6;
  padding: 12px 25px;
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  border-radius: 64px;
  line-height: 23.8px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    background-color: #8dd4f6;
    border: solid 1px #8dd4f6;
  }
  &:focus {
    border: solid 1px #8dd4f6;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 333px;
`

export const FormButton = styled(NoWidthButton)`
   margin-top: 15px;
   margin-left: 7px;
   padding: 6px 25px;
`

export const FooterContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

export const Container = styled.div`
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  padding-top: 150px;
  padding-bottom: 280px;
  width: 95%;
  max-width: 400px;
`

export const ProfileImg190x190Right = styled.img`
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
  transition: 0.3s;
  width: 190px;
  height: 190px;
  float: right;
  object-fit: cover;

  &:hover {
    opacity: 0.7;
  }
`

export const Thumbnail = styled.img`
  width: 128px;
  height: 128px;
  object-fit: cover;

  &:hover {
    opacity: 0.7;
  }
`

export const Error = styled.p`
  color: rgb(255, 90, 84);
  font-size: 13px;
  line-height: 15.8px;
  width: 100%;
  max-width: 333px;
  text-align: center;
`

export const Heading = styled.h3`
  line-height: 31.4px;
  white-space: nowrap;
  letter-spacing: -1.1px;
  font-size: 28px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 50px;
  color: rgb(0, 0, 0);
  text-wrap: wrap;
`

export const PageHeading = styled.h3`
  font-size: 28px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
  color: rgb(0, 0, 0);
  margin-bottom: 30px;
`

export const LoginRegisterHeading = styled.h1`
  line-height: 42.6px;
  letter-spacing: -1.5px;
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 25px;
`

export const PersonIcon = styled(Person)`
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
`

export const GroupAddIcon = styled(GroupAdd)`
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
`

export const AccountsImg = styled.img`
  height: 44px;
  width: 44px;
  margin-right: 12px;
  border-radius: 100px;
  object-fit: cover;
`

export const NavNotifButton = styled.span`
  left: 88%;
  height: 19px;
  width: 19px;
  font-size: 11px;
  line-height: 15px;
  background-color: rgb(255, 48, 64);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  border: 1px solid rgb(255, 48, 64);
  text-align: center;
  color: white;
  z-index: 1;
`

export const GroupIcon = styled(Group)`
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
`

export const LogoutIcon = styled(Logout)`
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
`

export const ForwardToInboxIcon = styled(ForwardToInbox)`
  font-size: 20px;
  cursor: pointer;
  margin-right: 3px;
  margin-bottom: 3px;
`

export const Icon = styled.i`
  font-size: 30px;
  color: #fff;
  display: inline-block;
`

export const IconSize16 = styled.i`
  padding: 3px 5px;
  font-size: 16px;
`

export const IconSize25 = styled.i`
  padding: 2px 3px;
  font-size: 25px;
`

export const ToggleNotification = styled.span`
  right: 5px;
  top: 10px;
  position: absolute;
  height: 12px;
  width: 12px;
  font-size: 11px;
  line-height: 17px;
  background-color: rgb(255, 48, 64);
  border-radius: 50%;
  border: 1px solid rgb(255, 48, 64);
  text-align: center;
  color: white;
  z-index: 1;
`

const BaseNotificationButton = styled.span`
  position: absolute;
  border: 3px solid #fff;
  line-height: 15px;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  z-index: 3;
  cursor: pointer;
`

export const ProfileImgNotif = styled(BaseNotificationButton)`
  top: 8px;
  right: 27px;
  height: 19px;
  width: 19px;
  background-color: rgb(255, 48, 64);
  color: white;
`

export const ProfileImgArrow = styled(BaseNotificationButton)`
  top: 46px;
  right: 30px;
  height: 14px;
  width: 14px;
  background-color: #E4E6EB;
  color: #050505;
`

export const MsgNotification = styled.span`
  top: 0px;
  right: 8px;
  height: 14px;
  width: 14px;
  font-size: 9px;
  line-height: 15px;
  background-color: rgb(255, 48, 64);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  border: 2px solid #fff;
  text-align: center;
  color: #050505;
  z-index: 1;
  cursor: pointer;
`

export const Hr = styled.hr`
  width: 100%;
  max-width: 333px;
`

export const HrMarginTopBottom20 = styled(Hr)`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const HrMarginNoMaxWidth = styled.hr`
   width: 100%;
   margin: 20px auto 5px auto;
`

export const Hr100 = styled.hr`
   width: 100%;
`

export const Title = styled.h3`
   color: rgb(0, 0, 0);
   line-height: 20px;
   font-weight: normal;
   font-size: 18px;
   margin-top: 20px;
   margin-bottom: 15px;

   &:hover {
      text-decoration: underline;
   }
`

export const PrimaryLink = styled.a`
  ${tw`px-6 text-base md:text-lg border-b-0 font-semibold cursor-pointer rounded-full`}
  background-color: rgb(0, 0, 0);
  color: #fff !important;
  width: 150px;
  text-align: center;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const NavLink = styled.a`
  ${tw`text-base md:text-lg my-2 lg:my-0 font-semibold tracking-wide transition duration-300
    pb-1 border-b-2 border-transparent hocus:text-black no-underline cursor-pointer`}
  color: rgb(0, 0, 0);
`

export const SmallSpinner = styled(RbSpinner)`
  height: 25px;
  width: 25px;
  color: white;
  padding: 3px;
  font-size: 20px;
`

export const Spinner = styled(RbSpinner)`
  position: absolute;
  top: 38%;
  left: 34%;
  height: 70px;
  width: 70px;
  color: white;
  font-size: 35px;
`

export const Text = styled.p`
  color: rgb(38, 38, 38);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 8px;
  line-height: 20px;
`

export const TextMargin0 = styled(Text)`
  margin: 0px;
`

export const TextBigger = styled.p`
  color: #050505;
  font-size: 1.0625rem;
  color: rgb(38, 38, 38);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
`

export const ZeroMarginGrid = styled(MuiGrid)`
  margin: 0px;
`

export const ZeroMarginGridPadding10 = styled(MuiGrid)`
  margin: 0px;
  padding-top: 10px !important;
`

export const ColumnTwo = styled(MuiGrid)`
   padding: 20px;
`

export const ProductImg = styled.img`
  ${tw`w-80 md:w-1/2 lg:w-3/4 max-h-96`}
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  cursor: pointer;
`

export const ProfileImg40x40Left = styled.img`
   border-radius: 100px;
   width: 40px;
   height: 40px;
   float: left;
   cursor: pointer;
   transition: 0.3s;
   object-fit: cover;

   &:hover {
      opacity: 0.9;
   }
`

export const Text16Gray = styled.p`
  color: var(--fir-color-grey);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 0px;
  cursor: pointer;
  display: inline-block;
`

export const Clickable = styled.div`
  margin-left: 10px;
  cursor: pointer;
  width: 90%;
`

export const Shop = styled.p`
  line-height: 18.5px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: ABCGintoNormal-Regular;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Info = styled.p`
  color: rgb(142, 142, 142);
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  max-width: 333px;
`

export const ModalBodyPadding20 = styled(Modal.Body)`
  padding: 20px;
`

export const ModalTitle = styled(Modal.Title)`
  color: var(--fir-color-grey);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 15px;
`

export const ModalBody = styled(Modal.Body)`
  margin-bottom: 10px;
`

export const ModalProductImg = styled.img`
  width: 300px;
  height: 300px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

export const ProductTitle = styled.h3`
  margin-bottom: 16px;
  margin-top: 32px;
  color: rgb(0, 0, 0);
  line-height: 42.6px;
  letter-spacing: -1.5px;
  font-family: ABCGintoNormal-Black;
  font-weight: normal;
  font-size: 38px;
  cursor: pointer;
`

export const ButtonGroup = styled(MuiGrid)`
  margin-top: 30px;
`

export const Main = styled(MuiGrid)`
  margin-top: 60px;
  margin-bottom: 10px;
`

export const MainDiv = styled.div`
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  margin-bottom: 75px;
  max-width: 90%;
`

export const ButtonStretched = styled.div`
  background-color: #8dd4f6;
  border: solid 1px #8dd4f6;
  width: 95%;
  max-width: 364px;
  min-height: 52px;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  padding: 6px 12px;
  border-radius: 64px;
  -webkit-line-clamp: 2;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  margin-top: 16px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #fff;
  font-weight: 500;

  &:hover {
    background-color: #8dd4f6;
    border: solid 1px #8dd4f6;
  }
  &:focus {
    border: solid 1px #8dd4f6;
  }
`

export const Chip = styled.div`
   display: inline-block;
   padding-left: 25px;
   padding-right: 12px;
   height: 50px;
   font-size: 16px;
   line-height: 50px;
   border-radius: 25px;
   background-color: #fff;
   outline: none;
   border: 1px solid #EBEBEB;
   margin-bottom: 30px;
   width: auto;
`

export const ChipImg = styled.img`
   float: left;
   margin-right: 10px;
   margin-left: -20px;
   height: 44px;
   width: 44px;
   border-radius: 100px;
   margin-top: 3px;
   object-fit: cover;
`

export const Input = styled.input`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
`

export const GoogleIconImg = styled.img`
  height: 20px;
  min-width: 20px;
  width: 20px;
  left: 5%;
  position: absolute;
`

export const GoogleButton = styled.div`
  background-color: rgb(255, 255, 255);
  outline: none;
  color: rgba(91,92,90,1.00);
  width: 95%;
  max-width: 364px;
  min-height: 52px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 6px 12px;
  border-radius: 64px;
  border: 1px solid rgb(191, 191, 191);
  -webkit-line-clamp: 2;
  line-height: 23.8px;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  position: relative;
`

export const Item = styled(MuiGrid)`
  margin-bottom: 35px;
  max-width: 24rem;
  width: 100%;
  position: relative;
`

export const Item600 = styled(MuiGrid)`
  margin-bottom: 35px;
  max-width: 600px;
  width: 100%;
  position: relative;
`

export const ProfileImg60x60Left = styled.img`
  border-radius: 100px;
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 16px;
  cursor: pointer;
  object-fit: cover;

  &:hover {
    opacity: 0.9;
  }
`

export const ProductImgGiftsList = styled(ProfileImg60x60Left)`
  width: 70px;
  height: 70px;
  marginright: 20px;
  border-radius: 0px;
`

export const GridMarginRight = styled(MuiGrid)`
  cursor: pointer;
`

export const ScrollableDiv = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  padding: 10px;
`

export const DetailsGiftsList = styled(GridMarginRight)`
  text-align: left;
  max-width: 210px;
  margin-right: 0px;
  width: 50%;
`

export const TextGiftsList = styled.p`
  color: rgb(38, 38, 38);
  font-size: 14px;
  line-height: 16px;
  max-width: 500px;
  margin-bottom: 0.8rem;
`

export const SubText = styled.p`
  color: rgb(142, 142, 142);
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0px;
`

export const ButtonsGroup = styled(MuiGrid)`
  position: absolute;
  right: 0;
`

export const ConnectionsIconButton = styled.button`
  background-color: #8dd4f6;
  border: solid 1px #8dd4f6;
  padding: 2px;
  color: #fff;
  cursor: pointer;
  border-radius: 100px;
`

export const ConnectionsIcon = styled.i`
  padding: 10px;
  font-size: 20px;
`

export const CardActions = styled(MuiCardActions)`
  padding: 0px;
  border-top: 1px solid #c6c6c6;
`

export const Label = styled.label`
  margin-top: 9px;
  color: rgb(38, 38, 38);
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
`

export const Card = styled(RbCard)`
  width: 100%;
  max-width: 24rem;
  border: 0px;
  padding: 12px 16px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
  border: 1px solid #E2E8F0;
  border-radius: 0.75rem;
  box-sizing: border-box;

  &:hover {
    border-color: #CBD5E0;
  }
`

export const CardHeader = styled(MuiCardHeader)`
  padding: 0px 8px;
`

export const CardHeaderOffersSent = styled(MuiCardHeader)`
  padding: 0px 0px 0px 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  width: 94%;
  justify-content: center;
  flex-direction: row;
  max-width: 250px;
`

export const CardContent = styled(MuiCardContent)`
  overflow-wrap: anywhere;
  padding: 15px 12px;
`

export const Tooltip = styled(MuiTooltip)`
  display: inline-block;
`

export const MessageBody = styled(MuiGrid)`
  padding: 8px 12px !important;
  color: #050505;
  background-color: #F0F2F5;
  white-space: normal;
  border-radius: 18px;
  word-break: break-word;
  min-height: fit-content;
  line-height: 18px;
`

export const Time = styled.p`
  color: rgb(115, 115, 115);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
`

export const Img = styled(RbCard.Img)`
  border-radius: 0.5rem;
  width:100%;
  height:100%;
  object-fit: contain;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

export const Body = styled(RbCard.Body)`
  font-family: 'Calibre', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  padding: 0px;
`

export const CardText = styled(RbCard.Text)`
  margin-bottom: 0px;
  line-height: 30px;
  font-size: 15px;
  color: #1A202C;
`

export const ImgContainter = styled.div`
  width: 100%;
  max-width: 350px;
  height: 230px;
  margin-bottom: 15px;
`

export const UnderlineDiv = styled.div`
  cursor: pointer;
`

export const CopyArea = styled(MuiGrid)`
  align-items: center;
  background: #f1f3f4;
  border-radius: 10px;
  color: #5f6368;
  display: flex;
  padding: 15px 10px;
  cursor: pointer;
`

export const LinkText = styled.span`
  letter-spacing: .00625em;
  font-family: Roboto,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  flex-grow: 1;
  overflow-wrap: anywhere;
`

export const IconButtonMui = styled(MuiIconButton)`
  outline: none !important;
  cursor: pointer;
  padding: 0px;
`

export const Content = styled.div`
  margin-top: 10px;
`

export const SwipeContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Reactions = styled(MuiGrid)`
  margin-top: 10px;
  margin-bottom: 20px;
  top: 470px;
  position: relative;
`

export const SwipeRightIcon = styled.i`
  color: #2ce6c7;
  font-size: 35px;
`

export const ActiveButton = styled(ButtonStretched)`
  margin-top: 12px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  width: 80%;
`

export const Details = styled.div`
  padding: 8px 20px;
`

export const IconButton = styled(MuiIconButton)`
  background-color: #fff;
  padding: 15px;
  &:focus {
    background-color: #fff;
  }
`

export const IconButtonPadding7MarginLR2 = styled(MuiIconButton)`
  padding: 7px;
  margin-right: 2px;
  margin-left: 2px;
`

export const TopRightIconButton = styled(IconButtonPadding7MarginLR2)`
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 5px;
  width: 33px;
`

export const TopRightIconButtonBackground = styled(IconButtonPadding7MarginLR2)`
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 5px;
  width: 33px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #E4E6EB;
`

export const UserImg = styled.img`
  width: 95%;
  height: 350px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  display: block;
`

export const ButtonIcon = styled(Icon)`
  margin-right: 7px;
  font-size: 15px;
  bottom: 1px;
  position: relative;
`

export const GrayLink = styled.a`
  color: #65676B;
  font-size: .8125rem;
  cursor: pointer;
  position: relative;
  left: 13px;
  bottom: 3px;

  :hover {
    text-decoration: underline;
    color: #65676B;
  }
`

export const GrayLinkNoHover = styled.span`
  color: #65676B;
  font-size: .8125rem;
  position: relative;
  left: 13px;
  bottom: 3px;
  pointer-events: none;
`

export const DisabledButton = styled(NoWidthButton)`
  background-color: #E4E6EB;
  border: solid 1px #E4E6EB;
  color: #050505;
  &:hover {
    background-color: #E4E6EB;
    border: solid 1px #E4E6EB;
  }
  &:focus {
    border: solid 1px #E4E6EB;
  }
`

export const LoadingShadow = styled(Skeleton)`
  margin-right: auto !important;
  margin-left: auto !important;
`

export const StackMarginTop = styled(MuiStack)`
  margin-top: 25px !important;
`

export const IconImage = styled.img`
  width: 31px;
  cursor: pointer;
`

export const CenterDiv = styled.div`
  width: 100%;
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LinkTextA = styled.a`
  color: rgb(0, 149, 246);
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  text-transform: inherit;
  width: auto;
  border: 0;
  display: inline-block;
  padding: 0 !important;
  position: relative;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    color: rgb(0, 55, 107);
  }
`

export const TabHover = styled.div`
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  text-align: center;
  color: #65676B;
  font-weight: 500;
  width: 50%;
  height: 60px;

  &:hover {
    background-color: #F0F2F5;
    border: solid 1px #F0F2F5;
    border-radius: 5px;
  }
  &:focus {
    border: solid 1px #F0F2F5;
  }
`

export const Tab = styled.div`
  min-height: 40px;
  cursor: pointer;
  align-items: center;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  text-align: center;
  color: #65676B;
  font-weight: 500;
  width: 50%;
  height: 60px;
`

export const TabButtonText = styled.div`
  margin: 0px;
  position: relative;
  top: 9px;
  color: #0766ff;
`

export const TabHr = styled.hr`
  margin: 0px;
  color: transparent;
  border-top: 3px solid #0766ff;
  top: 10px;
  position: relative;
  width: 99%;
  opacity: 90%;
`

export const BadgeIcon = styled.i`
  font-size: 16px;
  margin-right: 6px;
`

export const Badge = styled.div`
  color: #0064D1;
  height: 36px;
  border-radius: 18px;
  background-color: #EBF5FF;
  font-size: 16px;
  text-wrap: nowrap;
  font-weight: 600;
  padding: 0 12px;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
  bottom: 2px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    background-color: #dee8f3;
  }
`

export const DotSeparator = styled.span`
  line-height: 18px;
  font-weight: 400;
  font-size: 8px;
  margin: 0px 4px;
  position: relative;
`

export const Link = styled.a`
  color: #0064D1;
  cursor: pointer;
  text-decoration: none;
`

export const TrophyImg = styled.img`
  width: 35px;
  display: inline-block;
  cursor: pointer;
  transition: transform .2s;
  position: relative;
  bottom: 9px;
  left: 7px;

  &:hover {
    transform: scale(1.1);
  }
`

export const WhiteSpinnerSpan = styled.span`
  position: relative;
  top: 3px;
  text-align: center;
`

export const LoginSignUpText = styled.p`
  text-align: center;
  line-height: 18.5px;
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
`

export const TextLink = styled.a`
    cursor: pointer;
    fontWeight: bold;
    color: rgb(38, 38, 38);
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 2px;
    text-decoration: none;

    &:hover {
      color: rgb(38, 38, 38);
    }
`

export const CardHeaderGrid = styled(MuiGrid)`
  width: 75%;
  max-width: 275px;
`

export const TagDiv = styled.div`
   width: 90%;
`

export const MainCenterDiv = styled.div`
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 130px;
  margin-top: 40px;
  max-width: 90%;
`

export const BaseLeftIcon = styled.i`
  margin-right: 7px;
  font-size: 15px;
`

export const ellipsisDropDownCSS = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}
