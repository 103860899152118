import { FC, useState, ChangeEvent, useContext, useEffect, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { sendPasswordResetEmail } from "firebase/auth";
import { Grid as MuiGrid } from '@mui/material';

import { auth } from '../../firebase';
import { AuthContext } from '../../providers/AuthProvider';
import { Container, Error, FooterContainer, LoginRegisterHeading, NoWidthButton, SmallSpinner } from '../children/styled';
import { EMPTY_STRING, ENTER, HOME_PATH, LOGIN_PATH, REGISTER_PATH } from '../../constants';
import Footer from '../children/Footer';

const Input = styled.input`
  width: 95%;
  max-width: 364px;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const Text = styled.p`
  text-align: center;
  line-height: 18.5px;
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
`

const TextLeft = styled.p`
  line-height: 18.5px;
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const Span = styled.span`
  font-family: ABCGintoNormal-Bold;
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: bold;
`

const ButtonsGroup = styled(MuiGrid)`
  width: 95%;
  max-width: 364px;
  margin: 25px auto 20px auto;
`

const PasswordReset: FC = () => {
  const [email, setEmail] = useState(EMPTY_STRING);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [resetEmailSent, setResetEmailSent] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { currentUser } = useContext(AuthContext);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailError(false);
    setEmail(event.target.value);
  }

  const handleSignUpClick = () => {
    setEmailError(false);
    window.location.assign(REGISTER_PATH);
    window.scrollTo(0, 0);
  }

  const handleLoginClick = async () => {
    setEmailError(false);
    window.location.assign(LOGIN_PATH);
    window.scrollTo(0, 0);
  }

  const handleSendEmailClick = async () => {
    if (email === EMPTY_STRING) {
      setEmailError(true);
      setErrorMessage("Please enter your email.");
      return;
    }
    setSubmitting(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
    } catch (error) {
      setEmailError(true);
      setErrorMessage("Cannot find account with that email. Please make sure your email is correct.");
    };
    setSubmitting(false);
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if(event.key === ENTER) {
      handleSendEmailClick()
    }
  };

  useEffect(() => {
    if (currentUser) {
      window.location.assign(HOME_PATH);
    }
  }, []);

  return (
    <FooterContainer>
      <Container>
        {!resetEmailSent && <>
          <LoginRegisterHeading>Reset your password</LoginRegisterHeading>
          <TextLeft>Please enter your email address to receive the password reset link.</TextLeft>
          <Input type='email' placeholder='Email' value={email} onChange={handleEmailChange} onKeyDown={handleEnterPress} required />
          {emailError && <Error style={{ marginRight: "auto", marginLeft: "auto" }}>{errorMessage}</Error>}
          <ButtonsGroup container direction="row" justifyContent="center" alignItems="center">
            <NoWidthButton onClick={handleLoginClick} className="margin-bottom-300">Cancel</NoWidthButton>
            <NoWidthButton onClick={handleSendEmailClick}>
              {submitting ? <SmallSpinner animation="border" /> : <>Send email</>}
            </NoWidthButton>
          </ButtonsGroup>
          <Text>Don't have an account? <Span onClick={handleSignUpClick}>Sign up</Span></Text></>
        }
        {resetEmailSent && <>
          <LoginRegisterHeading>Password reset email sent <span style={{ fontSize: "25px", marginLeft: "3px", bottom: "2px", position: "relative" }}>📩</span></LoginRegisterHeading>
          <TextLeft>We've sent an email to reset your password.</TextLeft>
          <TextLeft>Check your <strong>spam/junk mail</strong> if it is not in your primary mailbox.</TextLeft>
          <ButtonsGroup container direction="row" justifyContent="center" alignItems="center">
            <NoWidthButton onClick={() => setResetEmailSent(false)} className="margin-bottom-300">Resend</NoWidthButton>
            <NoWidthButton onClick={handleLoginClick}>Go to Login</NoWidthButton>
          </ButtonsGroup></>
        }
      </Container>
      <Footer />
    </FooterContainer>
  );
};

export default PasswordReset;
