import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { ModalBodyPadding20, Text, NoWidthButton, ButtonGroup, LowPriorityButton } from './styled';

interface DeleteModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: (val: boolean) => void;
  confirmDeleteClick: () => void;
};
const DeleteModal: FC<DeleteModalProps> = ({ showDeleteModal, setShowDeleteModal, confirmDeleteClick }) => {
  return (
    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} size="sm" centered>
      <ModalBodyPadding20>
        <Text style={{ textAlign: "center" }}>Confirm delete?</Text>
        <ButtonGroup container direction="row" justifyContent="center" alignItems="center" className='center'>
          <LowPriorityButton onClick={() => setShowDeleteModal(false)}>Cancel</LowPriorityButton>
          <NoWidthButton onClick={confirmDeleteClick}>Delete</NoWidthButton>
        </ButtonGroup>
      </ModalBodyPadding20>
    </Modal>
  );
}

export default DeleteModal;
