import { getStorage, ref, uploadBytes, UploadResult, getDownloadURL, StorageReference, deleteObject } from "firebase/storage";

import { DEFAULT_PROFILE_IMG, EMPTY_STRING, MAX_IMAGES_NUMBER } from "../constants";

const getFileNameFromURL = (url: string) : string => {
  if (!url || url === EMPTY_STRING) {
    return;
  };
  try {
    const parts = url?.split("%2F");
    return parts[parts?.length - 1].split("?")[0];
  } catch (e) {
    console.error("Error getting image name from url: ", e);
  }
}

const generateImageName = () => {
  let result = EMPTY_STRING;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters?.length;
  let counter = 0;
  while (counter < 10) {
    result += characters?.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const uploadImage = async (file: any, folderPath: string) : Promise<StorageReference> => {
  try {
    const imageRef = ref(getStorage(), folderPath + generateImageName());
    const res: UploadResult = await uploadBytes(imageRef, file);
    return res.ref;
  } catch (e) {
    console.error("Error uploading image: ", e);
  }
}

const deleteImage = async (fileName: string, folderPath: string) : Promise<void> => {
  try {
    const imageRef = ref(getStorage(), folderPath + fileName);
    await deleteObject(imageRef);
  } catch (e) {
    console.error("Error deleting image: ", e);
  }
}

const updateImages = async (imgUrl: string, images: string[], folderPath: string): Promise<string[]> => {
  images.unshift(imgUrl);
  if (images?.length > MAX_IMAGES_NUMBER) {
    const deletedImageUrl: string = images?.pop();
    if (deletedImageUrl !== undefined) {
      await deleteImage(getFileNameFromURL(deletedImageUrl), folderPath);
    }
  }
  return images;
}

const getImageUrl = async (ref: any) : Promise<string> => {
  try {
    return await getDownloadURL(ref);
  } catch (e) {
    console.error("Error getting image url: ", e);
  }
}

const getImageOrDefault = (images: string[]) => {
  if (images && images?.length > 0 && images[0] && images[0] !== EMPTY_STRING) {
    return images[0];
  }
  return DEFAULT_PROFILE_IMG;
}

const getImageOrEmpty = (images: string[]) => {
  if (images && images?.length > 0 && images[0] && images[0] !== EMPTY_STRING) {
    return images[0];
  }
  return EMPTY_STRING;
}

const StorageService = {
  uploadImage,
  deleteImage,
  getImageUrl,
  getFileNameFromURL,
  updateImages,
  getImageOrDefault,
  getImageOrEmpty
}

export default StorageService;
