import { useState, useContext } from 'react';
import styled from "@emotion/styled/macro";
import { Spinner } from 'react-bootstrap';
import { useAutocomplete } from '@mui/material';

import { AuthContext } from '../../providers/AuthProvider';
import UserService from '../../services/userService';
import { AccountsImg } from './styled';
import StorageService from '../../services/storageService';
import { EMPTY_STRING } from '../../constants';

const SearchInput = styled.input`
  outline: none;
  border: 1px solid #EBEBEB;
  border-radius: 40px;
  padding: 10px 10px 10px 41px;
  font-weight: 300;
  font-size: .9375rem;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(30, 28, 36, 0.85);
  height: 46px;
  width: 373px;
`

const Ul = styled.ul`
  border-radius: 16px;
  width: 373px;
  list-style: none;
  background-color: rgba(255,255,255,1.00);
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(30, 28, 36, 0.85);
  padding: 8px;
  z-index: 1;
  position: fixed;
  top: 65px;
  display: block;
`

const AccountNames = styled.figcaption`
   margin-left: 0px;
`

const AccountHandle = styled.div`
   color: rgb(38, 38, 38);
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
`

const AccountName = styled.div`
   color: rgb(142, 142, 142);
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
   font-weight: 400;
   font-size: 14px;
`

const AccountFigure = styled.figure`
   margin: 0;
   display: flex;
   align-items: center;
   position: relative;
   text-decoration: none;
   padding: 8px;

   :hover {
    background-color: rgba(68, 73, 80, 0.15);
    border-radius: 16px;
  }
`

const LoadingDiv = styled.div`
   text-align: center;
   color: #E4E6EB;
   padding: 5px;
`

const Search = () => {
  const { currentUser } = useContext(AuthContext);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>(EMPTY_STRING);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [usersList, setUsersList] = useState<Account[] | null>([]);

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    options: usersList,
    getOptionLabel: (option: Account) => option.username,
		isOptionEqualToValue: (option, value) => option.valueOf === value.valueOf
  });

  const handleSearchChange = async (event: any) => {
    const val: string = event.target.value;
    setSearchVal(val);
    if (val.trim() === EMPTY_STRING) {
      setShowSuggestions(false);
      setUsersList([]);
      return;
    }
    setShowSuggestions(true);
    const resList: Account[] = await UserService.getAccountsStartingWith(val.replace("@", EMPTY_STRING).toLowerCase());
    if (resList) {
      setUsersList(resList);
      setIsLoading(false);
      return;
    }
    setUsersList([]);
    setIsLoading(true);
  }

  const handleAccountClick = (username: string) => {
    if (username && username !== EMPTY_STRING) {
      setShowSuggestions(false);
      setSearchVal(EMPTY_STRING);
      setUsersList([]);
      window.location.assign(`/${username}`);
    }
  }

  return (
    <>{currentUser ? <>
        <div className='search-navbar'>
          <i className="fas fa-search" />
          <SearchInput {...getInputProps()} type="search" placeholder="Search usernames" value={searchVal} onChange={handleSearchChange} className='search-input' />
        </div>
        {showSuggestions ? (
          <Ul {...getListboxProps()} className="search-navbar-suggestions">
            {isLoading && usersList?.length === 0 ?
                <LoadingDiv><Spinner animation="border" /></LoadingDiv>
              :
              <>{usersList?.length > 0 && (groupedOptions as typeof usersList)?.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <AccountFigure onClick={() => handleAccountClick(option?.username)}>
                    <AccountsImg src={StorageService.getImageOrDefault(option?.images)} />
                    <AccountNames>
                        <AccountHandle>{option?.username}</AccountHandle>
                        <AccountName>{option?.name}</AccountName>
                    </AccountNames>
                  </AccountFigure>
                </li>
                ))}
              </>}
          </Ul>
        ) : null}
      </>
      : null}
    </>
  )
}

export default Search;
