import { FC, useContext, useEffect, useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { GroupAdd as GroupAddIcon, Reply as ReplyIcon } from '@mui/icons-material';
import { Grid, IconButton as MuiIconButton, Skeleton, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper,
  styled as muiStyled } from '@mui/material';
import { FilterNoneOutlined as CopyIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Card as RbCard } from 'react-bootstrap';
import confetti from 'canvas-confetti';
import { CardData, CardEnterEvent, CardEvent, CardSwiper } from 'react-card-swiper';

import Alert from '../children/Alert';
import { authenticateUser, getMax25RandomAccounts, goToHomeOnClick, handleCopyClick, sendGiftHelper, hasNoConnection,
  saveReactionProductUser, shuffleObjArray, trimText, messageHelper } from '../../services/helpers';
import ProductService from '../../services/productService';
import { ButtonGroup, ButtonStretched, Icon, Item, PageHeading as BasePageHeading, NoWidthButton, SubText, Text, HrMarginTopBottom20,
  ProfileImg190x190Right, StackMarginTop as BaseStack, LoadingShadow, IconImage, CopyArea, LinkText, IconButtonMui,
  LowPriorityButton, Button, TrophyImg } from '../children/styled';
import UserService from '../../services/userService';
import { SUCCESS, CONNECTIONS_PATH, EMPTY_STRING, LIKED, SKIPPED, BASE_URL,
  SLASH, ORG, PRODUCTS_PATH, NONE, LIKE_CAP, SKIP, PRODUCT, LIKE } from '../../constants';
import { AuthContext } from '../../providers/AuthProvider';
import { auth } from '../../firebase';
import ConnectionService from '../../services/connectionService';
import HeartRegular from "../../images/heart-regular.png";
import ReactionService from '../../services/reactionService';
import WishlistService from '../../services/wishlistService';
import StorageService from '../../services/storageService';
import CelebrationFormModal from './CelebrationFormModal';
import Celebration from './Celebration';
import Trophy from "../../images/trophy.svg";
import ImageModal from './ImageModal';
import CelebrationService from '../../services/celebrationService';
import DeleteModal from './DeleteModal';
import CongratsModal from './CongratsModal';
import MsgInfoModal from './MsgInfoModal';

const Content = styled.div`
  margin-top: 50px;
`

const Paragraph = styled(Text)`
  margin-bottom: 20px;
  text-align: left;
  width: 75%;
`

const PageHeading = styled(BasePageHeading)`
  font-size: 20px;
  text-align: center;
`

const UnderlineDiv = styled.div`
  cursor: pointer;
  padding: 20px 16px;
`

const LabelText = styled(Text)`
  font-size: 14px;
  padding: 0px 10px;
  cursor: pointer;
`

const IconButton = styled(MuiIconButton)`
  background-color: transparent;
  padding: 15px;
  &:focus {
    background-color: transparent;
  }
`

const ActiveButton = styled(ButtonStretched)`
  margin-top: 12px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  width: 80%;
`

const DisabledButton = styled(NoWidthButton)`
  background-color: #E4E6EB;
  border: solid 1px #E4E6EB;
  color: #050505;
  &:hover {
    background-color: #E4E6EB;
    border: solid 1px #E4E6EB;
  }
  &:focus {
    border: solid 1px #E4E6EB;
  }
`

const ButtonIcon = styled(Icon)`
  margin-right: 7px;
  font-size: 16px;
  top: 1px;
  position: relative;
`

const BannerImg = styled.img`
  width: 70%;
  margin: 30px auto;
  display: block;
`

const WideButton = styled(Button)`
  margin-top: 20px;
`

const Stack = styled(BaseStack)`
  margin: auto;
`

const CustomConnector = muiStyled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: { top: 22, zIndex: -1 },
  [`&.${stepConnectorClasses.active}`]: { [`& .${stepConnectorClasses.line}`]: { backgroundColor: "#ea6079" } },
  [`&.${stepConnectorClasses.completed}`]: { [`& .${stepConnectorClasses.line}`]: { backgroundColor: "#ea6079" } },
  [`& .${stepConnectorClasses.line}`]: { height: 3, border: 0, backgroundColor: '#eaeaf0', borderRadius: 1 },
}));

interface RegularSetupProps {
  stepTwo?: boolean;
};
const RegularSetup: FC<RegularSetupProps> = ({ stepTwo }) => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [activeStep, setActiveStep] = useState<number>(stepTwo ? 1 : 0);
  const [emailIsSent, setEmailIsSent] = useState<boolean>(false);
  const stepsVerificationDone = ['Post a celebration', 'Earn points', 'Select gifts'];
  const stepsVerification = ['Post a celebration', 'Earn points', 'Email verification'];
  const [stepLabels, setStepLabels] = useState<string[]>(stepsVerificationDone);
  const [products, setProducts] = useState<Product[] | null>([]);
  const [suggestedAccounts, setSuggestedAccounts] = useState<Account[] | null>([]);
  const [requestedArray, setRequestedArray] = useState<boolean[]>([]);
  const [productsEmpty, setProductsEmpty] = useState<boolean>(false);
  const [accountsEmpty, setAccountsEmpty] = useState<boolean>(false);
  const [accountsLoading, setAccountsLoading] = useState<boolean>(true);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [emailVerified, setEmailVerified] = useState<boolean>(true);
  const [celebrationModalOpen, setCelebrationModalOpen] = useState<boolean>(false);
  const [showProductsToSelect, setShowProductsToSelect] = useState<boolean>(false);
  const [celebrationAdded, setCelebrationAdded] = useState<Celebration | null>(null);
  const [celebrationToEdit, setCelebrationToEdit] = useState<Celebration | null>(null);
  const [celebrationEdited, setCelebrationEdited] = useState<Celebration | null>(null);
  const [confettiFired, setConfettiFired] = useState<boolean>(false);
  const [displayImageModalCSS, setDisplayImageModalCSS] = useState<string>(NONE);
  const [imageToModal, setImageToModal] = useState<string>(EMPTY_STRING);
  const [celebrationToDelete, setCelebrationToDelete] = useState<Celebration>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [celebrationToShowCongrats, setCelebrationToShowCongrats] = useState<Celebration>(null);
  const [congratsModalOpen, setCongratsModalOpen] = useState<boolean>(false);
  const [showMsgInfoModal, setShowMsgInfoModal] = useState<boolean>(false);
  const [accountToMessage, setAccountToMessage] = useState<Account>();

  const handleResendEmailClick = async () => {
    await auth?.currentUser?.reload();
    if (auth?.currentUser?.emailVerified) {
      goToHomeOnClick();
      return;
    };
    await sendEmailVerification(auth?.currentUser);
    setEmailIsSent(true);
    setAlertType(SUCCESS);
    setAlertMessage("We've sent a verification email. If the email is not in your primary mailbox, please check your spam/junk mail. 📧");
    setAlertIsOpen(true);
  };

  const handleMessageClick = async (account: Account) => {
    setAccountToMessage(account);
    const userIsAuthenticated: boolean = await authenticateUser(currentUser, signOut);
    if (userIsAuthenticated) {
      await messageHelper(currentUser, account, setShowMsgInfoModal);
    };
  };

  const confirmDeleteClick = async () => {
    setShowDeleteModal(false);
    resetCelebrationObjs();
    await CelebrationService.deleteCelebrationAndImage(celebrationToDelete);
  };

  const getSuggestedProducts = async () => {
    try {
      // todo: make more efficient
      const allProductsList: Product[] = await ProductService.getProducts();
      const allUserReactions: Reaction[] = await ReactionService.getReactionsByUserId(currentUser?.id);
      const allWishlistItems: WishlistItem[] = await WishlistService.getWishlistsByUserId(currentUser?.id);
      const productIdsToExclude: string[] = allUserReactions?.map(reaction => reaction?.product?.id)
        .concat(allWishlistItems?.map(wishlistItems => wishlistItems?.product?.id));
      let unreactedProducts = allProductsList?.filter(productObj => !productIdsToExclude?.includes(productObj?.id));
      if (unreactedProducts?.length > 10) {
        unreactedProducts = unreactedProducts?.slice(0, 10);
      };
      setProducts(unreactedProducts);
      setProductsEmpty(unreactedProducts?.length === 0);
      setProductsLoading(false);
    } catch (err) {
      console.error("Error loading suggested products: ", err)
    }
  };

  const getSuggestedUsers = async () => {
    try {
      const orgAccounts: Account[] = await UserService.getOrgAccounts();
      const personalAccounts: Account[] = await UserService.getSuggestedAccounts();
      const orgPersonalArr: Account[] = shuffleObjArray(orgAccounts).concat(personalAccounts);
      setSuggestedAccounts(orgPersonalArr);
      setRequestedArray(new Array(orgPersonalArr?.length).fill(false));
      setAccountsEmpty(orgPersonalArr?.length === 0);
      setAccountsLoading(false);
    } catch (err) {
      console.error("Error loading suggested users: ", err)
    }
  };

  const checkIfEmailVerified = async () => {
    await auth?.currentUser?.reload();
    if (!auth?.currentUser?.emailVerified) {
      setEmailVerified(false);
      setStepLabels(stepsVerification);
    }
  };

  const resetCelebrationObjs = () => {
    setCelebrationAdded(null);
    setCelebrationToEdit(null);
    setCelebrationEdited(null);
  };

  const handleNewCelebrationClick = () => {
    resetCelebrationObjs();
    setCelebrationModalOpen(true);
  };

  const getCardContent = (productObj: Product) => {
    return (
      <UnderlineDiv>
        <RbCard.Title onClick={() => window.open(productObj?.url)}>
          {ProductService.getProductTitleShortened(productObj?.title)}
        </RbCard.Title>
        <Text style={{ marginTop: "4px" }} onClick={() => window.open(productObj?.shop?.url)}>{productObj?.shop?.name}</Text>
      </UnderlineDiv>
    );
  };

  const getProductsForCards = () : CardData[] => {
    return products?.map(productObj => ({
      id: productObj?.id,
      meta: { product: {...productObj} },
      src: productObj?.images?.at(0),
      content: getCardContent(productObj)
    }));
  };

  const handleFinish = () => {
    setProductsEmpty(true);
  };

  // @ts-ignore
  const handleCardSwiped: CardEvent = async (el, meta, id, action, operation) => {
    await authenticateUser(currentUser, signOut);
    await saveReactionProductUser(currentUser, action?.valueOf() === LIKE ? LIKED : SKIPPED, meta[PRODUCT]);
  };

  // @ts-ignore
  const handleCardPresented: CardEnterEvent = (el, meta, id) => {};

  useEffect(() => {
    getSuggestedProducts();
    getSuggestedUsers();
    if (auth?.currentUser) {
      checkIfEmailVerified();
    };
  }, []);

  useEffect(() => {
    if (celebrationAdded && !confettiFired) {
      confetti();
      setConfettiFired(true);
    };
  }, [celebrationAdded]);

  useEffect(() => {
    if (celebrationEdited) {
      setCelebrationAdded(celebrationEdited);
    };
  }, [celebrationEdited]);

  const icons: { [index: string]: React.ReactElement } = {
    1: <Icon className="fas fa-gift" style={{ fontSize: "22px" }} />,
    2: <GroupAddIcon style={{ marginLeft: "3px" }} />,
    3: <Icon className={`fa-solid ${emailVerified ? "fa-store" : "fa-envelope-circle-check"}`}
          style={{ fontSize: "22px", marginLeft: `${emailVerified ? "0px" : "5px"}` }} />
  };
  const CustomStepIcon = ({ active, completed, className, icon }) => {
    const StepIcon = muiStyled('div')
      <{ownerState: { completed?: boolean; active?: boolean }}>(({ ownerState }) => ({
        backgroundColor: '#ccc',
        cursor: "pointer",
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && { backgroundColor: "#ea6079" }),
        ...(ownerState.completed && { backgroundColor: "#ea6079" }),
    }));
    return (
      <StepIcon ownerState={{ completed, active }} className={className + " zoom"}>
        {icons[String(icon)]}
      </StepIcon>
    );
  };

  const getButtons = (accountObj: Account, index: number) => {
    if (accountObj?.type === ORG) {
      return (
        <WideButton onClick={() => sendGiftHelper(currentUser, accountObj, signOut)}>
          <ButtonIcon className="fas fa-hand-holding-heart" />Gift
        </WideButton>
      );
    };
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "20px" }}>
        {requestedArray[index] &&
          <DisabledButton>
            <ButtonIcon className="fas fa-light fa-paper-plane" style={{ color: "#050505" }} />Requested
          </DisabledButton>
        }
        {!requestedArray[index] &&
          <LowPriorityButton onClick={() => handleMessageClick(accountObj)}>
            <ButtonIcon style={{ color: "#050505", top: "0px" }} className="fa-regular fa-comment" />Message
          </LowPriorityButton>
        }
        <NoWidthButton onClick={() => sendGiftHelper(currentUser, accountObj, signOut)}>
          <ButtonIcon style={{ top: "0px" }} className="fas fa-gift" />Gift
        </NoWidthButton>
      </Grid>
    )
  };

  return (<>
    <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
      {stepLabels?.map((label, index) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step key={label} {...stepProps} onClick={() => setActiveStep(index)}>
            <StepLabel {...labelProps} StepIconComponent={CustomStepIcon}>
              <LabelText>{label}</LabelText>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
    <Content>
      {activeStep === 0 && <>
        {!celebrationAdded && <PageHeading style={{ marginBottom: "20px" }}>Celebrate and get gifts! 🎉</PageHeading>}
        {celebrationAdded && <PageHeading style={{ marginBottom: "20px" }}>Congratulations! 🎉</PageHeading>}
        {!celebrationAdded && <>
          <Paragraph className='center' style={{ width: "60%" }}>Post a celebration like a birthday, new job, lifestyle change, or any other.</Paragraph>
          <BannerImg src='https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/media%2Fcelebration-banner-prop.jpg?alt=media&token=2ab8c538-9e13-4065-9228-4dd3981a0915' />
          <Paragraph className='center' style={{ width: "60%" }}>Add gifts that you want for your celebration so others can gift you!</Paragraph>
          <ButtonGroup container direction="row" justifyContent="flex-start" alignItems="flex-start" className='center' style={{ marginTop: "50px" }}>
            <LowPriorityButton onClick={() => setActiveStep(1)}>
              Skip
            </LowPriorityButton>
            <NoWidthButton onClick={handleNewCelebrationClick}>New celebration</NoWidthButton>
          </ButtonGroup></>
        }
        {celebrationAdded && <>
          <Celebration celebration={celebrationAdded} setCelebrationModalOpen={setCelebrationModalOpen} celebrationEdited={celebrationEdited}
            setCelebrationToEdit={setCelebrationToEdit} displayingOnFeed={true} personal={true} setShowProductsToSelect={setShowProductsToSelect}
            setImageToModal={setImageToModal} setDisplayImageModalCSS={setDisplayImageModalCSS} setCelebrationToDelete={setCelebrationToDelete}
            setShowDeleteModal={setShowDeleteModal} setCelebrationToShowCongrats={setCelebrationToShowCongrats} setCongratsModalOpen={setCongratsModalOpen} />
          <Paragraph className='center' style={{ textAlign: "center" }}>Share your account with others to get gifts for your celebration. 🎁</Paragraph>
          <CopyArea onClick={() => handleCopyClick(BASE_URL + SLASH + currentUser?.username, setAlertIsOpen, setAlertType, setAlertMessage)}
            container direction="row" justifyContent="space-between" alignItems="center">
            <LinkText>{BASE_URL + SLASH + currentUser?.username}</LinkText>
            <IconButtonMui><CopyIcon /></IconButtonMui>
          </CopyArea>
          <ButtonGroup container direction="row" justifyContent="flex-start" alignItems="flex-start" className='center' style={{ marginTop: "50px" }}>
            <LowPriorityButton onClick={handleNewCelebrationClick}>
              New celebration
            </LowPriorityButton>
            <NoWidthButton onClick={() => setActiveStep(1)}>Next</NoWidthButton>
          </ButtonGroup></>
        }</>
      }
      {activeStep === 1 && <>
        {accountsLoading && <>
          <Stack spacing={1}>
            <LoadingShadow variant="circular" width={180} height={180} style={{ marginBottom: "10px" }} />
            <LoadingShadow variant="rounded" width={250} height={38} />
            <LoadingShadow variant="rounded" width={250} height={38} />
          </Stack>
          <Stack spacing={1}>
            <LoadingShadow variant="circular" width={180} height={180} style={{ marginBottom: "10px" }} />
            <LoadingShadow variant="rounded" width={250} height={38} />
            <LoadingShadow variant="rounded" width={250} height={38} />
          </Stack>
          <Stack spacing={1}>
            <LoadingShadow variant="circular" width={180} height={180} style={{ marginBottom: "10px" }} />
            <LoadingShadow variant="rounded" width={250} height={38} />
            <LoadingShadow variant="rounded" width={250} height={38} />
          </Stack></>
        }
        {!accountsLoading && <>
          {accountsEmpty && <>
            <PageHeading>Your connection requests are pending ⌛️</PageHeading>
            <ButtonGroup container direction="row" justifyContent="flex-start" alignItems="flex-start" className='center' style={{ marginTop: "50px" }}>
              <LowPriorityButton onClick={() => window.location.assign(CONNECTIONS_PATH)}>
                View connections
              </LowPriorityButton>
              <NoWidthButton onClick={() => setActiveStep(2)}>Next</NoWidthButton>
            </ButtonGroup></>
          }
          {!accountsEmpty && <>
            <div className='center' style={{ marginBottom: "15px" }}>
              <PageHeading style={{ display: "inline" }}>Send a gift and earn points!</PageHeading>
              <TrophyImg src={Trophy} />
            </div>
            {suggestedAccounts?.map((accountObj, index) =>
              <div key={accountObj?.id}>
                <Item container direction="column" justifyContent="center" alignItems="center" className='center-width-24rem'
                  onClick={() => window.location.assign(`/${accountObj?.username}`)} style={{ marginBottom: "0px" }}>
                  <ProfileImg190x190Right src={StorageService.getImageOrDefault(accountObj?.images)} style={{ marginRight: "0px", float: "none" }} />
                  <Grid item style={{ cursor: "pointer", textAlign: "center", marginTop: "3px" }}>
                    {accountObj?.name && <Text>{accountObj?.name}</Text>}
                    <SubText>@{accountObj?.username}</SubText>
                    {accountObj?.type === ORG &&
                      <SubText>Animal Rescue <i className="fas fa-paw" style={{ fontSize: "12px", marginLeft: "2px" }} /></SubText>
                    }
                  </Grid>
                </Item>
                {accountObj?.bio && <SubText style={{ overflow: "wrap", padding: "8px 8px 0px 8px", textAlign: "center" }}>{trimText(accountObj?.bio, 200)}</SubText>}
                {getButtons(accountObj, index)}
                <HrMarginTopBottom20 style={{ marginLeft: "auto", marginRight: "auto" }} />
              </div>
            )}</>
          }</>
        }</>
      }
      {activeStep === 2 && !emailVerified && <>
        <PageHeading>Verify your email 📤</PageHeading>
        <Paragraph className='center'>We sent you a link to verify your email.</Paragraph>
        <Paragraph className='center'>Check your <strong>spam/junk mail</strong> if it is not in your primary mailbox.</Paragraph>
        <ButtonGroup container direction="row" justifyContent="flex-start" alignItems="flex-start" className='center' style={{ marginTop: "50px" }}>
          <LowPriorityButton onClick={handleResendEmailClick}>
            { emailIsSent ? "Email sent" : "Resend email" }
          </LowPriorityButton>
          <NoWidthButton onClick={goToHomeOnClick}>Done</NoWidthButton>
        </ButtonGroup></>
      }
      {activeStep === 2 && emailVerified && <>
        {productsLoading &&
          <Stack spacing={1}>
            <LoadingShadow variant="rounded" width={270} height={230} />
            <LoadingShadow variant="rounded" width={270} height={62} />
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "30px" }}>
              <Skeleton variant="circular" width={50} height={50} style={{ marginLeft: "5px", marginRight: "5px" }} />
              <Skeleton variant="circular" width={50} height={50} style={{ marginLeft: "5px", marginRight: "5px" }} />
            </Grid>
          </Stack>
        }
        {!productsLoading &&
          <div style={{ height: "600px" }}>
            {!productsEmpty && <PageHeading>Swipe on your favourite products! 🎁</PageHeading>}
            <Stack height="500px" width="300px" direction="column" alignItems="center" justifyContent="end" p={2}>
              <CardSwiper
                data={getProductsForCards()}
                onEnter={handleCardPresented}
                onFinish={handleFinish}
                onDismiss={handleCardSwiped}
                dislikeButton={
                  <IconButton style={{ padding: "8px" }} title={SKIP}>
                    <ReplyIcon style={{ fontSize: "43px", color: "#ccc" }} />
                  </IconButton>
                }
                likeButton={
                  <IconButton title={LIKE_CAP}>
                    <IconImage style={{ marginLeft: "1px", width: "30px" }} src={HeartRegular} />
                  </IconButton>
                }
                withActionButtons
                withRibbons
                likeRibbonText={LIKE_CAP}
                dislikeRibbonText={SKIP}
                ribbonColors={{ bgLike: 'green', bgDislike: 'red', textColor: 'white' }}
                emptyState={
                  <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} textAlign={'center'} gap={2}>
                    <PageHeading style={{ marginBottom: "0px" }}>You've reached the end of the line. 🏅</PageHeading>
                    <Text>We'll have more gifts soon. 🔜</Text>
                    <ActiveButton onClick={() => window.location.assign(PRODUCTS_PATH)}>
                      <ButtonIcon className="fa-solid fa-gift" />All products
                    </ActiveButton>
                  </Stack>
                }
              />
            </Stack>
          </div>
        }</>
      }
    </Content>
    <CelebrationFormModal celebrationModalOpen={celebrationModalOpen} setCelebrationModalOpen={setCelebrationModalOpen} setCelebrationAdded={setCelebrationAdded}
      showProductsToSelect={showProductsToSelect} setShowProductsToSelect={setShowProductsToSelect} celebrationToEdit={celebrationToEdit} setCelebrationEdited={setCelebrationEdited} />
    <ImageModal displayCSS={displayImageModalCSS} setDisplayCSS={setDisplayImageModalCSS} selectedImage={imageToModal} />
    <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} confirmDeleteClick={confirmDeleteClick} />
    <CongratsModal currentUser={currentUser} signOut={signOut} celebrationObj={celebrationToShowCongrats} congratsModalOpen={congratsModalOpen}
      setCongratsModalOpen={setCongratsModalOpen} setAlertIsOpen={setAlertIsOpen} setAlertType={setAlertType} setAlertMessage={setAlertMessage} />
    <MsgInfoModal showMsgInfoModal={showMsgInfoModal} setShowMsgInfoModal={setShowMsgInfoModal} account={accountToMessage} />
    <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} /></>
  );
}

export default RegularSetup;
