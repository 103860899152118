import { FC } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import AuthProvider from './providers/AuthProvider';
import Home from './components/views/Home';
import Account from './components/views/Account';
import Login from './components/views/Login';
import Register from './components/views/Register';
import Offers from './components/views/Offers';
import Connections from './components/views/Connections';
import Navbar from './components/children/Navbar';
import './styles/App.css';
import './styles/responsive.css';
import StripeWrapper from './components/views/StripeWrapper';
import NotFound from './components/views/NotFound';
import InvitePage from './components/views/InvitePage';
import Setup from './components/views/Setup';
import CustomGiftsVerification from './components/views/CustomGiftsVerification';
import PasswordReset from './components/views/PasswordReset';
import { ABOUT_PATH, PRODUCTS_PATH, CONNECTIONS_PATH, CREATE_PATH, CUSTOM_GIFTS_VERIFICATION_PATH, LIKES, LOGIN_PATH,
  LOGIN_RECOVER_PATH, PAYMENT_PATH, PROFILE_PATH, RECEIVED_PATH, REGISTER_PATH, REQUESTS_PATH, SENT_PATH, WISHLIST, EARN_PATH,
  INBOX_PATH } from './constants';
import Products from './components/views/Products';
import LandingPage from './components/views/LandingPage';
import Inbox from './components/views/Inbox';

const App: FC<any> = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path={ABOUT_PATH} element={<LandingPage />} />
          <Route path={PRODUCTS_PATH} element={<Products />} />
          <Route path={LOGIN_PATH} element={<Login />} />
          <Route path={LOGIN_RECOVER_PATH} element={<PasswordReset />} />
          <Route path={REGISTER_PATH} element={<Register />} />
          <Route path={REGISTER_PATH} element={<Register isGifter={true} />} />
          <Route path={RECEIVED_PATH} element={<Offers userSent={false} />} />
          <Route path={SENT_PATH} element={<Offers userSent={true} />} />
          <Route path={CONNECTIONS_PATH} element={<Connections requestsPath={false} />} />
          <Route path={REQUESTS_PATH} element={<Connections requestsPath={true} />} />
          <Route path={CREATE_PATH} element={<Account isNewAccount={true} />} />
          <Route path={PAYMENT_PATH} element={<StripeWrapper />} />
          <Route path={CUSTOM_GIFTS_VERIFICATION_PATH} element={<CustomGiftsVerification />} />
          <Route path={EARN_PATH} element={<Setup stepTwo={true} />} />
          <Route path={INBOX_PATH} element={<Inbox />} />
          <Route path={INBOX_PATH + "/t" + "/:roomId"} element={<Inbox />} />
          <Route path={PROFILE_PATH} element={<Setup />} />
          <Route path='/invite/:shareId' element={<InvitePage />} />
          <Route path='/:username' element={<Account isNewAccount={false} />} />
          <Route path='/:username/wishlist' element={<Account isNewAccount={false} tab={WISHLIST} />} />
          <Route path='/:username/likes' element={<Account isNewAccount={false} tab={LIKES} />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
