import { useContext, useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Grid as MuiGrid } from '@mui/material';
import { User, onAuthStateChanged } from "firebase/auth";
import styled from '@emotion/styled';
import confetti from 'canvas-confetti';

import { AuthContext } from '../../providers/AuthProvider';
import FeedCompositeService from '../../services/feedCompositeService';
import {
  CardContent, CenterDiv, MainDiv, Card as BaseCard, CardHeader, ProfileImg40x40Left, TextBigger,
  CardActions as BaseCardActions
} from '../children/styled';
import { auth } from '../../firebase';
import { AUTHOR, EMPTY_STRING, GIFTEE, GIFTER, LEFT, NONE, TO_GIFT_ID } from '../../constants';
import Offer from '../children/Offer';
import Celebration from '../children/Celebration';
import { authenticateUser, checkIfIdInArray, displayShareInfoModal, getCongratsFromUser, getCongratsNotifs } from '../../services/helpers';
import ConnectionService from '../../services/connectionService';
import CelebrationFormModal from '../children/CelebrationFormModal';
import GiftingForm from './GiftingForm';
import Alert from '../children/Alert';
import ShippingAddressModal from '../children/ShippingAddressModal';
import BadgeModal from '../children/BadgeModal';
import LandingPage from './LandingPage';
import PromoModal from '../children/PromoModal';
import StorageService from '../../services/storageService';
import ImageModal from '../children/ImageModal';
import CelebrationService from '../../services/celebrationService';
import DeleteModal from '../children/DeleteModal';
import CongratsModal from '../children/CongratsModal';

const ActionButtons = styled.div`
  cursor: pointer;
  align-items: center;
  font-size: .9375rem;
  text-align: center;
  color: #65676B;
  font-weight: 600;
  width: 50%;
  line-height: 1.3333;
  padding: 8px;
  padding-top: 3px;

  &:hover {
    background-color: #F0F2F5;
    border-radius: 5px;
  }
  &:focus {
    border: solid 1px #F0F2F5;
  }
`

const CardActions = styled(BaseCardActions)`
  margin-top: 12px;
  padding-top: 8px;
`

const TypeButton = styled.button`
  background-color: #F0F2F5;
  border: solid 1px #fff;
  min-height: 40px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px;
  color: #65676B;
  text-align: left;
  line-height: 1.34;
  font-weight: 300;
  padding: 8px 12px;
  width: 100%;
  word-wrap: break-word;

  &:hover {
    background-color: #e4e6e9;
  }
`

const Icon = styled.i`
  opacity: 0.7;
  margin-right: 8px;
  font-size: 24px;
  position: relative;
  top: 4px;
`

const Card = styled(BaseCard)`
  padding: 12px 8px 10px 8px;
  margin-bottom: 30px;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
`

const Home = () => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [feedItems, setFeedItems] = useState<FeedItem[]>([]);
  const [hasMoreItems, sethasMoreItems] = useState<boolean>(true);
  const [lastOffer, setLastOffer] = useState<Offer>();
  const [lastCelebration, setLastCelebration] = useState<Celebration>();
  const [celebrationEdited, setCelebrationEdited] = useState<Celebration | null>(null);
  const [celebrationModalOpen, setCelebrationModalOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();
  const [giftingFormOpen, setGiftingFormOpen] = useState<boolean>(false);
  const [celebrationToEdit, setCelebrationToEdit] = useState<Celebration | null>();
  const [showProductsToSelect, setShowProductsToSelect] = useState<boolean>(false);
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>();
  const [offerEdited, setOfferEdited] = useState<Offer | null>();
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [showBadgeModal, setShowBadgeModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [offerForBadge, setOfferForBadge] = useState<Offer | null>();
  const [showShareInfoModal, setShowShareInfoModal] = useState<boolean>(false);
  const [userLink, setUserLink] = useState<string>(EMPTY_STRING);
  const [displayImageModalCSS, setDisplayImageModalCSS] = useState<string>(NONE);
  const [imageToModal, setImageToModal] = useState<string>(EMPTY_STRING);
  const [celebrationToDelete, setCelebrationToDelete] = useState<Celebration>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [celeIdsCongratulated, setCeleIdsCongratulated] = useState<string[]>(null);
  const [celebrationToShowCongrats, setCelebrationToShowCongrats] = useState<Celebration>(null);
  const [congratsModalOpen, setCongratsModalOpen] = useState<boolean>(false);
  const [accCeleIdsWithNotifs, setAccCeleIdsWithNotifs] = useState<string[]>(null);

  const handleGiftIconClick = async (product: Product, userObj: SubUser) => {
    await authenticateUser(currentUser, signOut);
    if (await ConnectionService.canGift(currentUser, userObj)) {
      localStorage.setItem(TO_GIFT_ID, userObj?.id);
    };
    setSelectedProduct(product);
    setGiftingFormOpen(true);
  };

  const handleCelebrationProductsChange = (updatedCelebration: Celebration) => {
      setFeedItems(feedItems?.map(feedItemObj => feedItemObj?.id === updatedCelebration?.id ? { ...feedItemObj, ...updatedCelebration } : feedItemObj)
      );
  };

  const confirmDeleteClick = async () => {
    setShowDeleteModal(false);
    setIsLoading(true);
    setFeedItems(feedItems?.filter(feedItemObj => feedItemObj?.id !== celebrationToDelete?.id));
    await CelebrationService.deleteCelebrationAndImage(celebrationToDelete);
    setIsLoading(false);
  };

  const getFeedItemComponent = (feedItem: FeedItem, index: number) => {
    if (feedItem?.hasOwnProperty(GIFTEE) && feedItem?.hasOwnProperty(GIFTER)) {
      const offerObj = feedItem as Offer;
      return (
        <Offer offer={offerObj} setAddressModalOpen={setAddressModalOpen} setSelectedOffer={setSelectedOffer} key={offerObj?.id+index}
          currentUser={currentUser} userSent={offerObj?.gifter?.id === currentUser?.id} setOfferForBadge={setOfferForBadge}
          setShowBadgeModal={setShowBadgeModal} />
      );
    } else if (feedItem?.hasOwnProperty(AUTHOR)) {
      const celebrationObj = feedItem as Celebration;
      return (
        <Celebration celebration={celebrationObj} setCelebrationModalOpen={setCelebrationModalOpen} personal={currentUser?.id === celebrationObj?.author?.id}
          handleGiftIconClick={handleGiftIconClick} handleCelebrationProductsChange={handleCelebrationProductsChange} setCelebrationToEdit={setCelebrationToEdit}
          setShowProductsToSelect={setShowProductsToSelect} displayingOnFeed={true} key={celebrationObj?.id+index} celebrationEdited={celebrationEdited}
          setImageToModal={setImageToModal} setDisplayImageModalCSS={setDisplayImageModalCSS} setCelebrationToDelete={setCelebrationToDelete} confetti={confetti}
          setShowDeleteModal={setShowDeleteModal} congratulated={checkIfIdInArray(celeIdsCongratulated, celebrationObj?.id)}
          setCelebrationToShowCongrats={setCelebrationToShowCongrats} setCongratsModalOpen={setCongratsModalOpen} newNotif={accCeleIdsWithNotifs?.includes(celebrationObj?.id)} />
      );
    };
  };

  const getFeedItemsAndCongrats = async (initial?: boolean) : Promise<void> => {
    setIsLoading(true);
    try {
      let tupleItems: [FeedItem[], any, any];
      if (initial) {
        tupleItems = await FeedCompositeService.getItemsAndCutoffSnapshots(currentUser?.id);
      } else {
        tupleItems = await FeedCompositeService.getItemsAndCutoffSnapshots(currentUser?.id, true, lastOffer, lastCelebration);
      };
      if (tupleItems?.at(0)?.length === 0) {
        sethasMoreItems(false);
        setIsLoading(false);
        return;
      };
      tupleItems?.at(0) && setFeedItems(feedItems?.concat(tupleItems?.at(0)));
      tupleItems?.at(1) && setLastOffer(tupleItems?.at(1));
      tupleItems?.at(2) && setLastCelebration(tupleItems?.at(2));
      await getCongratsFromUser(currentUser?.id, setCeleIdsCongratulated);
      const celeIdsWithNotif: string[] = await getCongratsNotifs(currentUser?.id);
      if (celeIdsWithNotif) {
        setAccCeleIdsWithNotifs(celeIdsWithNotif);
      };
    } catch (err) {
      console.error("Error loading offers and celebrations for feed: ", err);
    };
    setIsLoading(false);
  };

  const getFeedEndComponent = () => {
    if (isLoading) {
      return (<CenterDiv><CircularProgress /></CenterDiv>);
    };
    return (
      <CenterDiv><TextBigger>No new activity at the moment.</TextBigger></CenterDiv>
    );
  };

  useEffect(() => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
        signOut();
      } else {
        getFeedItemsAndCongrats(true);
      }
    });

    if (currentUser) {
      displayShareInfoModal(currentUser?.username, setUserLink, setShowShareInfoModal);
    };
  }, []);

  useEffect(() => {
    if (celebrationEdited) {
      setFeedItems(feedItems?.map(feedItemObj => feedItemObj?.id === celebrationEdited?.id ? { ...feedItemObj, ...celebrationEdited } : feedItemObj)
      );
    };
  }, [celebrationEdited]);

  useEffect(() => {
    if (offerEdited) {
      setFeedItems(feedItems?.map(feedItemObj => feedItemObj?.id === offerEdited?.id ? { ...feedItemObj, ...offerEdited } : feedItemObj)
      );
    };
  }, [offerEdited]);

  return (<>
    {!currentUser && <LandingPage />}
    {currentUser && <>
      <MainDiv>
        {!giftingFormOpen && <>
          <Card className='mobile-card center-width-24rem'>
            <CardHeader style={{ textAlign: LEFT, padding: "0px" }}
              avatar={
                <ProfileImg40x40Left id="myImg" src={StorageService.getImageOrDefault(currentUser?.images)}
                  className="profile-img" onClick={() => window.location.assign(`/${currentUser?.username}`)} />
              }
              title={
                <TypeButton onClick={() => setCelebrationModalOpen(true)}>
                  Post a celebration
                </TypeButton>
              }
            />
            <CardContent style={{ textAlign: LEFT, padding: "0px" }}>
            <CardActions>
              <MuiGrid container direction="row" justifyContent="space-around" alignItems="center">
                <ActionButtons onClick={() => setCelebrationModalOpen(true)}>
                  <Icon className="fa-regular fa-images" style={{ color: "rgb(25, 118, 210)" }} />Photo
                </ActionButtons>
                <ActionButtons onClick={() => { setShowProductsToSelect(true); setCelebrationModalOpen(true); }}>
                  <Icon className="fas fa-gift" style={{ color: "rgb(172, 172, 230)" }} />Wishlist
                </ActionButtons>
              </MuiGrid>
            </CardActions>
            </CardContent>
          </Card>
          <InfiniteScroll dataLength={feedItems?.length} next={getFeedItemsAndCongrats} hasMore={hasMoreItems} loader={<CenterDiv><CircularProgress /></CenterDiv>}
            endMessage={getFeedEndComponent()}>
            {feedItems?.map((feedItem, index) => getFeedItemComponent(feedItem, index))}
          </InfiniteScroll></>
        }
        {giftingFormOpen && <GiftingForm productObj={selectedProduct} setGiftingForm={setGiftingFormOpen} />}
      </MainDiv>
      <PromoModal showShareInfoModal={showShareInfoModal} setShowShareInfoModal={setShowShareInfoModal} />
      <BadgeModal showBadgeModal={showBadgeModal} offerForBadge={offerForBadge} setShowBadgeModal={setShowBadgeModal} />
      <CelebrationFormModal celebrationToEdit={celebrationToEdit} celebrationModalOpen={celebrationModalOpen} setCelebrationModalOpen={setCelebrationModalOpen}
        showProductsToSelect={showProductsToSelect} setShowProductsToSelect={setShowProductsToSelect} setCelebrationEdited={setCelebrationEdited} />
      <ShippingAddressModal offer={selectedOffer} setOfferEdited={setOfferEdited} addressModalOpen={addressModalOpen} setAddressModalOpen={setAddressModalOpen}
        setAlertIsOpen={setAlertIsOpen} setAlertType={setAlertType} setAlertMessage={setAlertMessage} />
      <ImageModal displayCSS={displayImageModalCSS} setDisplayCSS={setDisplayImageModalCSS} selectedImage={imageToModal} />
      <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} confirmDeleteClick={confirmDeleteClick} />
      <CongratsModal currentUser={currentUser} signOut={signOut} celebrationObj={celebrationToShowCongrats} congratsModalOpen={congratsModalOpen}
        setCongratsModalOpen={setCongratsModalOpen} setAlertIsOpen={setAlertIsOpen} setAlertType={setAlertType} setAlertMessage={setAlertMessage} />
      <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} /></>
    }
  </>);
};

export default Home;
