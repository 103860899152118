import { FC, useState, useContext, useEffect, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { FormControlLabel, Grid as MuiGrid, Radio, RadioGroup, useAutocomplete } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { User, onAuthStateChanged } from "firebase/auth";

import UserService from '../../services/userService';
import StripeService from '../../services/stripeService';
import { defaultOfferScope, emptyCheckout, emptyOffer } from '../../services/emptyObjs';
import { ACTIVE, CONTACTS, EMPTY_STRING, ERROR, EVERYONE, EVERYONE_OPTION, GBP, JON_ID, LOGIN_PATH,
	ONLY_MY_CONTACTS_OPTION, PENDING, TO_GIFT_ID } from '../../constants';
import { AuthContext } from '../../providers/AuthProvider';
import { auth } from '../../firebase';
import { Clickable, ColumnTwo, ProductImg, Shop, SmallSpinner, Text, ZeroMarginGrid as ColumnOne, Chip, ChipImg, NoWidthButton, Info } from '../children/styled';
import Alert from '../children/Alert';
import ProductService from '../../services/productService';
import ConnectionService from '../../services/connectionService';
import { trimPrice, trimText } from '../../services/helpers';
import StorageService from '../../services/storageService';
import StripeWrapper from './StripeWrapper';
import OfferService from '../../services/offerService';

const Main = styled(MuiGrid)`
   margin-top: 40px;
	 margin-bottom: 130px;
`

const Input = styled.input`
  border: 1px solid rgba(206,206,206,1.00);
  outline: none;
  border-radius: 20px;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(30, 28, 36, 0.85);
  width: 100%;
  max-width: 333px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 7px;
  margin-left: 7px;
`

const Title = styled.h3`
	margin-bottom: 16px;
	margin-top: 32px;
	color: rgb(0, 0, 0);
	line-height: 42.6px;
	letter-spacing: -1.5px;
	font-family: ABCGintoNormal-Black;
	font-weight: normal;
	font-size: 25px;
`

const Price = styled(Text)`
	font-weight: 900;
	margin: 0px 10px;
`

const Ul = styled.ul`
	border-radius: 16px;
	width: 100%;
	max-width: 333px;
	list-style: none;
	background-color: rgba(255,255,255,1.00);
	cursor: pointer;
	box-sizing: border-box;
	outline: none;
	border: 1px solid #EBEBEB;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.04em;
	text-align: left;
	color: rgba(30, 28, 36, 0.85);
	padding: 8px;
	box-shadow: 0 12px 12px rgba(0, 0, 0, 0.2),inset 0 0 0 0 rgba(255, 255, 255, 0.5);
	position: absolute;
	top: 90px;
	display: block;
`

const AccountsImg = styled.img`
   height: 44px;
   width: 44px;
   margin-right: 12px;
   border-radius: 100px;
   cursor: pointer;
   object-fit: cover;
`

const AccountFigure = styled.figure`
   margin: 0;
   display: flex;
   align-items: center;
   position: relative;
   text-decoration: none;
   padding: 8px;

   :hover {
    background-color: rgba(68, 73, 80, 0.15);
    border-radius: 16px;
  }
`

const AccountNames = styled.figcaption`
   margin-left: 0px;
`

const AccountHandle = styled.div`
   color: rgb(38, 38, 38);
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
`

const AccountName = styled.div`
   color: rgb(142, 142, 142);
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
   font-weight: 400;
   font-size: 14px;
`

const CloseChip = styled.span`
   padding-left: 20px;
   color: #888;
   font-weight: bold;
   float: right;
   font-size: 21px;
   cursor: pointer;
   position: relative;
   bottom: 2px;

   &:hover {
      color: #000;
    }
`

const LoadingDiv = styled.div`
   text-align: center;
   color: #E4E6EB;
   padding: 5px;
`

const Error = styled.div`
  color: rgb(255, 90, 84);
  font-size: 12px;
  line-height: 15.8px;
  width: 100%;
  max-width: 333px;
  margin-bottom: 0px !important;
  margin-top: 5px;
  padding-left: 10px
`

const Icon = styled.i`
	font-size: 15px;
	margin-left: 3px;
`

interface GiftingFormProps {
	productObj?: Product;
	setGiftingForm: (val: boolean) => void
};
const GiftingForm: FC<GiftingFormProps> = ({ productObj, setGiftingForm }) => {
	const { currentUser, setCurrentUserToStorage, signOut } = useContext(AuthContext);
	const [recipient, setRecipient] = useState<Account | null>();
	const [suggestionCSS, setSuggestionCSS] = useState<string>(EMPTY_STRING);
	const [searchVal, setSearchVal] = useState<string>(EMPTY_STRING);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
	const [message, setMessage] = useState<string>(EMPTY_STRING);
	const [error, setError] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
	const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
	const [currency, setCurrency] = useState<string>(EMPTY_STRING);
	const [usersList, setUsersList] = useState<Account[] | null>([]);
	const [productLink, setProductLink] = useState<string>(EMPTY_STRING);
	const [linkDetails, setLinkDetails] = useState<string>(EMPTY_STRING);
	const [connections, setConnections] = useState<Connection[]>([]);
	const [gifterOfferScope, setGifterOfferScope] = useState<string>(EVERYONE);
	const [isCheckout, setIsCheckout] = useState<boolean>(false);
	const [pendingOffer, setSelectedOffer] = useState<Offer | null>();

	const { getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
		options: usersList,
		getOptionLabel: (option: Account) => option.username,
		isOptionEqualToValue: (option, value) => option.valueOf === value.valueOf
	});

	const handleSearchChange = async (event: any) => {
		const val = event.target.value;
		setSearchVal(val);
		if (val.trim() === EMPTY_STRING) {
			setSuggestionCSS(EMPTY_STRING);
			setShowSuggestions(false);
			setUsersList([]);
			return;
		}
		setSuggestionCSS(ACTIVE);
		setShowSuggestions(true);
		const resList: Account[] = await UserService.getAccountsStartingWith(val.replace("@", EMPTY_STRING).toLowerCase());
		if (resList) {
			const noCurrentUserList: Account[] = resList.filter(account => account?.id !== currentUser.id);
			const gifteeList: Account[] = [];
			for (let i = 0; i < noCurrentUserList?.length; i++) {
				const acc = noCurrentUserList[i];
				if (ConnectionService.canGiftPerUser(currentUser, acc, connections)) {
					gifteeList.push(acc);
				}
			}
			if (gifteeList?.length > 0) {
				setIsLoading(false);
				setUsersList(gifteeList);
				return;
			} else {
				const gifteeMissedDueToLimit: Account = await UserService.getAccountByUsername(val.replace("@", EMPTY_STRING).toLowerCase());
				if (gifteeMissedDueToLimit && ConnectionService.canGiftPerUser(currentUser, gifteeMissedDueToLimit, connections)) {
					setIsLoading(false);
					setUsersList([gifteeMissedDueToLimit]);
					return;
				}
			}
		}
		setUsersList([]);
		setIsLoading(true);
	}

	const handleAccountClick = (account: Account) => {
		if (account && account?.username !== EMPTY_STRING) {
			setRecipient(account);
			setSuggestionCSS(EMPTY_STRING);
			setShowSuggestions(false);
			setSearchVal(EMPTY_STRING);
			setUsersList([]);
		}
	}

	const handleClearClick = () => {
		setRecipient(null);
		setSuggestionCSS(EMPTY_STRING);
		setShowSuggestions(false);
		setSearchVal(EMPTY_STRING);
		setUsersList([]);
	}

	const handleCloseChip = () => {
		setSearchVal(EMPTY_STRING);
		setRecipient(null);
		setUsersList([]);
	}

	const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setMessage(event.target.value);
	}

	const handleProfileClick = () => {
    window.location.assign(`/${recipient?.username}`);
    window.scrollTo(0, 0);
  }

	const alertUser = (type: string, msg: string) => {
		setAlertIsOpen(true);
		setAlertType(type);
		setAlertMessage(msg);
	}

	const handleLinkDetailsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setLinkDetails(event.target.value);
	}

	const handleProductLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
		setProductLink(event.target.value);
  }

	const handleOfferPrivacyChange = (event: any) => {
    setGifterOfferScope(event.target.value);
  }

	const handleCheckoutClick = async () => {
		if (!recipient || (productLink.trim() === EMPTY_STRING && !productObj)) {
			setError(true);
			return;
		};
		setSubmitting(true);
		try {
			if (productObj) {
				const priceObj = productObj?.price;
				const amount: string = priceObj?.amount;
				const shippingFee: string = priceObj?.shippingFee;
				const processFee: string = ProductService.getProcessingFee(amount, shippingFee);
				const total: string = ProductService.getTotalPrice(amount, shippingFee, processFee);
				// Todo: remove check for jon id
				const newPaymentId: any = await StripeService.createPayment(currentUser.id, {
					amount: currentUser?.id === JON_ID ? "1.00" : total,
					currency: priceObj?.currencyCode,
					customer: EMPTY_STRING,
					off_session: false
				});
				let tags: string[] = productObj?.tags;
				if (!tags) {
					const latestProduct: Product = await ProductService.getProductById(productObj?.id);
					tags = latestProduct?.tags;
				};
				const pendingOffer: Offer = {
					...emptyOffer,
					gifter: {
						id: currentUser.id,
						username: currentUser.username,
						name: currentUser.name,
						image: StorageService.getImageOrEmpty(currentUser?.images),
						phone: currentUser?.phone,
						email: currentUser?.email,
						offersScope: currentUser?.offersScope
					},
					giftee: {
						id: recipient.id,
						username: recipient.username,
						name: recipient.name,
						image: StorageService.getImageOrEmpty(recipient?.images),
						phone: recipient?.phone,
						email: recipient?.email,
						offersScope: recipient?.offersScope
					},
					message: message.trim(),
					status: PENDING,
					scope: {
						...defaultOfferScope,
						gifter: gifterOfferScope
					},
					createdAt: Date.now(),
					updatedAt: Date.now(),
					product: {
						id: productObj?.id,
						title: productObj?.title,
						url: productObj?.url,
						description: productObj?.description,
						shop: productObj?.shop,
						images: productObj?.images,
						price: productObj?.price,
						tags
					},
					checkout: {
						...emptyCheckout,
						paymentId: newPaymentId
					}
				};
				await OfferService.addOffer(pendingOffer);
				setSelectedOffer(pendingOffer);
				setIsCheckout(true);
				window.scrollTo(0, 0);
				return;
			};
		} catch (error) {
			alertUser(ERROR, "Sorry, please try again later.");
		};
	};

	useEffect(() => {
		setError(false);
	}, [searchVal, message]);

	useEffect(() => {
		const setUp = () => {
			onAuthStateChanged(auth, (loggedInUser: User) => {
				if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
					signOut();
					window.location.assign(LOGIN_PATH);
					return;
				}
			});
		}

		const updateCachedUser = async (): Promise<void> => {
			const account = await UserService.getAccountById(currentUser.id);
			setCurrentUserToStorage(account);
		}

		const getPotentialGiftee = async () => {
			const gifteeId = localStorage.getItem(TO_GIFT_ID);
			if (gifteeId) {
				const giftee: Account = await UserService.getAccountById(gifteeId);
				if (await ConnectionService.canGift(currentUser, giftee)) {
					setRecipient(giftee);
				}
				localStorage.removeItem(TO_GIFT_ID);
			}
		}

		const getConnections = async () => {
			const conns: Connection[] = await ConnectionService.getConnectionsByUserId(currentUser?.id);
			setConnections(conns);
		}

		window.scrollTo(0, 0);
		setUp();
		updateCachedUser();
		getPotentialGiftee();
		getConnections();
	}, []);

	useEffect(() => {
		if (productObj) {
			setCurrency(productObj?.price?.currencyCode?.toLowerCase())
		}
	}, [productObj]);

	return (
		<>
			{ isCheckout && <StripeWrapper offer={pendingOffer} setIsCheckout={setIsCheckout} /> }
			{ !isCheckout &&
				<Main container justifyContent="center" spacing={1} columns={{ xs: 1, sm: 1, md: 12 }}>
					<ColumnOne item xs={1} md={4}>
						{productObj ?
							<>
								<ProductImg src={productObj?.images[0]} onClick={() => window.open(productObj?.url)} className="width-80-max-397" />
								<Clickable>
									<Title onClick={() => window.open(productObj?.url)}>{productObj.title}</Title>
									<Text>{productObj.description}</Text>
									<Shop onClick={() => window.open(productObj?.shop.url)}>{productObj.shop.name}</Shop>
								</Clickable>
								<Price>
									{currency === GBP ? "£" : currency.toUpperCase()}
									{trimPrice(productObj?.price?.amount)}
								</Price>
							</>
							:
							<>
								<ProductImg style={{ width: "150px", cursor: "none" }} src="https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/media%2Fwhite-gift.png?alt=media&token=3c8badc5-47d6-4355-9a89-29a6f193ab58" />
								<Text className='center' style={{ width: "80%", maxWidth: "330px", marginBottom: "20px", marginTop: "20px" }}>Add the link to the product that you wish to gift and we will send it like other gifts.</Text>
								<Label style={{ marginLeft: "0px", textAlign: "center" }}>Link to product</Label>
								<input value={productLink} onChange={handleProductLinkChange} className='input-textarea center' type='text' placeholder="https://" />
								{ error && (productLink.trim() === EMPTY_STRING) && <Error style={{ textAlign: "center", padding: "0px", maxWidth: "none" }}>Please add a link url.</Error> }
								<Label style={{ marginTop: "30px", marginLeft: "0px", textAlign: "center" }}>Details (optional)</Label>
								<textarea value={linkDetails} onChange={handleLinkDetailsChange} className='input-textarea center' rows={3} placeholder='Add details about the variant, color, size, etc.' />
							</>
						}
					</ColumnOne>
					<ColumnTwo item xs={1} md={4}>
						<div className="dropdown">
							<Label>To</Label>
							{recipient ?
								<Chip>
									<ChipImg onClick={handleProfileClick} src={StorageService.getImageOrDefault(recipient?.images)} style={{ cursor: "pointer" }} />
									<span onClick={handleProfileClick} style={{ cursor: "pointer" }}>
										{trimText(UserService.getNameOrUsername(recipient.name, recipient.username), 15)}
									</span>
									<CloseChip onClick={handleCloseChip}>&times;</CloseChip>
								</Chip>
								:
								<>
									<div className='search-bar' style={{ marginBottom: "30px" }}>
										<Input {...getInputProps()} type='text' className='default_option' placeholder='Search usernames' value={searchVal} onChange={handleSearchChange} />
										<i onClick={handleClearClick} className={`fas fa-times ${suggestionCSS}`} />
										{ error && !recipient && <Error>Please select a recipient</Error> }
									</div>
									{showSuggestions ? (
										<Ul {...getListboxProps()}>
											{isLoading || usersList?.length === 0 ?
												<LoadingDiv><Spinner animation="border" /></LoadingDiv>
												:
												<>{usersList?.length > 0 && (groupedOptions as typeof usersList)?.map((option, index) => (
													<li {...getOptionProps({ option, index })}>
														<AccountFigure onClick={() => handleAccountClick(option)}>
															<AccountsImg src={StorageService.getImageOrDefault(option?.images)} />
															<AccountNames>
																<AccountHandle>{option?.username}</AccountHandle>
																<AccountName>{option?.name}</AccountName>
															</AccountNames>
														</AccountFigure>
													</li>
												))}
												</>}
										</Ul>
									) : null}
								</>
							}
						</div>
						<Label>Personal note (optional)</Label>
						<textarea className='input-textarea' value={message} onChange={handleMessageChange} rows={4} placeholder='Write something nice'
						style={{ marginBottom: "0px" }}/>

						<Label style={{ marginBottom: '7px', marginTop: '10px' }}>Who can see your gift offer?</Label>
						<RadioGroup onChange={handleOfferPrivacyChange} value={gifterOfferScope} style={{ marginLeft: "7px" }}>
							<FormControlLabel style={{ height: "25px", marginBottom: "5px" }} value={EVERYONE} control={<Radio />} label={EVERYONE_OPTION} />
							<FormControlLabel style={{ height: "25px" }} value={CONTACTS} control={<Radio />} label={ONLY_MY_CONTACTS_OPTION} />
						</RadioGroup>

						<Info style={{ padding: '0px', marginTop: "20px", marginBottom: "0px", marginLeft: "7px" }}>
							You will be refunded in full if the gift is not accepted.
						</Info>

						<MuiGrid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "20px", maxWidth: "333px" }}>
							<NoWidthButton onClick={() => setGiftingForm(false)} className="margin-bottom-300">Cancel</NoWidthButton>
							<NoWidthButton onClick={handleCheckoutClick}>
								{submitting ? <SmallSpinner animation="border" /> : <>Checkout <Icon className="fa-solid fa-arrow-right" /></>}
							</NoWidthButton>
						</MuiGrid>
					</ColumnTwo>
				</Main>
			}
			<Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} />
		</>
	);
};

export default GiftingForm;
