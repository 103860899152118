import { FC, useState, ChangeEvent, useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select as MuiSelect,
  Paper as MuiPaper, Tooltip } from '@mui/material';
import { StorageReference } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { Modal, ModalTitle } from 'react-bootstrap';

import { AuthContext } from '../../providers/AuthProvider';
import { CenterDiv, ConnectionsIconButton, DetailsGiftsList, IconButtonPadding7MarginLR2, IconSize25, Img as ImgHover, ImgContainter, Item, Label, LinkTextA,
  ModalBody, NoWidthButton, ProductImgGiftsList, ProfileImg60x60Left, ScrollableDiv, SubText, TextGiftsList, TextMargin0, TopRightIconButtonBackground,
  Button, LowPriorityButton, TagDiv } from './styled';
import { EVERYONE, MAX_FILE_SIZE_KB, MAX_COMPRESSED_FILE_SIZE_KB, EMPTY_STRING, NONE_TYPE, BIRTHDAY, HOLIDAY, ANNIVERSARY, NEW_BABY, MILESTONE,
  ACHIEVEMENT, NEW_JOB, MARRIED, ENGAGED, GOAL, GRADUATION, SOMETHING_NEW, CONTACTS, CELEBRATION_IMAGES_FOLDER, ERROR, SLASH, WISHLIST, LIKES,
  ONLY_MY_CONTACTS_OPTION, EVERYONE_OPTION, SUCCESS, WEDDING, NEW_HOME, WORK_ANNIVERSARY, EMPTY_ARRAY, TAGS_LIST } from '../../constants';
import ProductService from '../../services/productService';
import StorageService from '../../services/storageService';
import Alert from './Alert';
import CelebrationService from '../../services/celebrationService';
import { emptyCelebration } from '../../services/emptyObjs';
import { addOrDeleteTagHelper, resetTagsHelper } from '../../services/helpers';
import TagsStrip from './TagsStrip';
import TagsModal from './TagsModal';

const Select = styled(MuiSelect)`
  width: 170px;
`

const Spinner = styled(CircularProgress)`
  margin-right: 7px !important;
  margin-left: 2px !important;
  width: 22px !important;
  height: 22px !important;
  position: relative !important;
  top: 7px !important;
`

const BackIconButton = styled(TopRightIconButtonBackground)`
  right: 0px;
  top: 0px;
  left: 0px;
`

const Img = styled(ImgHover)`
  &:hover {
    opacity: 1;
  }
`

const CloseIconButtonNoHover = styled(TopRightIconButtonBackground)`
  background-color: #fff;

  &:hover {
    background-color: #fff;
  }
`

const Row = styled(Grid)`
  padding: 8px;
`

const Paper = styled(MuiPaper)`
  padding: 0px 8px;
  border: 1px solid #CED0D4;
  border-radius: 8px;
`

const Title = styled(ModalTitle)`
  color: #050505;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.25rem;
  margin-bottom: 7px;
  margin-right: auto;
  margin-left: auto;
`

const ModalHeader = styled(Modal.Header)`
  text-align: center;
  display: block;
  min-height: 70px;
`

const TextArea = styled.textarea`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  font-size: 16px;
  padding: 5px 10px;
  width: 100%;
`

const Text = styled(TextMargin0)`
  color: #050505;
  line-height: 1.3333;
  font-size: .9375rem;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  word-wrap: break-word;
`

const ChosenGiftsDiv = styled.div`
  overflow: auto;
  white-space: nowrap;
  height: 120px;
`

const ChosenGift = styled.div`
  position: relative;
  width: 90px;
  margin-top: 20px;
  display: inline-block;
`

const ChosenGiftImage = styled(ProfileImg60x60Left)`
  width: 70px;
  height: 70px;
  float: none;
  margin-right: 0px;
  border-radius: 0px;
`

const RemoveIconButton = styled(CloseIconButtonNoHover)`
  width: 25px;
  height: 25px;
  top: -8px;
  right: 0px;
  position: absolute;
`

const FixedButton = styled(Button)`
  position: absolute;
  left: 25%;
  bottom: 20px;
`

interface CelebrationFormProps {
  celebrationToEdit?: Celebration;
  celebrationModalOpen: boolean;
  showProductsToSelect: boolean;
  setShowProductsToSelect: (val: boolean) => void;
  setCelebrationModalOpen: (val: boolean) => void;
  setCelebrationEdited?: (cele: Celebration) => void;
  setCelebrationAdded?: (cele: Celebration) => void;
};
const CelebrationFormModal: FC<CelebrationFormProps> = ({ celebrationToEdit, celebrationModalOpen, showProductsToSelect,
  setShowProductsToSelect, setCelebrationModalOpen, setCelebrationEdited, setCelebrationAdded }) => {
  const { currentUser } = useContext(AuthContext);
  const [type, setType] = useState<string>(celebrationToEdit?.type || EMPTY_STRING);
  const [description, setDescription] = useState(celebrationToEdit?.description || EMPTY_STRING);
  const [scope, setScope] = useState<string>(celebrationToEdit?.scope || EVERYONE);
  const [images, setImages] = useState<string[]>(celebrationToEdit?.images || EMPTY_ARRAY);
  const [selectedProductsOnForm, setSelectedProductsOnForm] = useState<Product[]>(celebrationToEdit?.selectedProducts || []);
  const [productIsLoading, setProductIsLoading] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [showProductsArrray, setShowProductsArrray] = useState<boolean[]>([]);
  const [imgUploadLoading, setImgUploadLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
  const [tagsOnArray, setTagsOnArray] = useState<boolean[]>(new Array(TAGS_LIST?.length).fill(false));

  const hiddenFileInput = useRef(null);

  const handleRemoveProductClick = (productId: string) => {
    setSelectedProductsOnForm(selectedProductsOnForm?.filter(selectedProduct => selectedProduct?.id !== productId));
    let indexToFalse: number;
    allProducts?.forEach((product, index) => {
      if (product?.id === productId) {
        indexToFalse = index;
      }
    });
    showProductsArrray[indexToFalse] = true;
    setShowProductsArrray([ ...showProductsArrray ]);
  };

  const handleAddProductClick = (productObj: Product, index: number) => {
    if (selectedProductsOnForm?.length === 5) {
      setAlertIsOpen(true);
      setAlertType(ERROR);
      setAlertMessage("Wishlist can contain 5 items. Please consider removing items from your wishlist before adding.");
      return;
    }
    for (const product of selectedProductsOnForm) {
      if (product?.id === productObj?.id) {
        return;
      }
    }
    showProductsArrray[index] = false;
    setShowProductsArrray([ ...showProductsArrray ]);
    setSelectedProductsOnForm([ ...selectedProductsOnForm, productObj ]);
  };

  const handleKindChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === NONE_TYPE) {
      setType(EMPTY_STRING);
      return;
    }
    setType(event.target.value as string);
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }

  const handleScopeChange = (event: any) => {
    setScope(event.target.value);
  }

  const displayGeneralError = () => {
    setAlertIsOpen(true);
    setAlertType(ERROR);
    setAlertMessage("Sorry, something went wrong. Please try again later.");
  };

  const isOnHomeOrCelebrationsPaths = () : boolean => {
    const path = window.location.pathname;
    if (path === SLASH) {
      return true;
    };
    const pathArray = path?.split(SLASH);
    return pathArray?.includes(currentUser?.username) && !pathArray?.includes(WISHLIST) && !pathArray?.includes(LIKES);
  };

  const resetForm = () => {
    setType(EMPTY_STRING);
    setDescription(EMPTY_STRING);
    setScope(EVERYONE);
    setImages(EMPTY_ARRAY);
    setSelectedProductsOnForm([]);
  };

  const saveCelebrationToDB = async (updatedCelebration: Celebration, updateImageOnly?: boolean) : Promise<void> => {
    if (!updateImageOnly) {
      setSubmitting(true);
    };
    try {
      if (celebrationToEdit) {
        await CelebrationService.updateCelebration(updatedCelebration);
        setCelebrationEdited(updatedCelebration);
      } else {
        const newCelebrationId: string = await CelebrationService.addCelebration(updatedCelebration);
        if (isOnHomeOrCelebrationsPaths()) {
          window.location.reload();
          return;
        };
        if (setCelebrationAdded) {
          setCelebrationAdded({
            ...updatedCelebration,
            id: newCelebrationId
          });
        };
      };
      if (!updateImageOnly) {
        setCelebrationModalOpen(false);
        resetForm();
      };
    } catch (err) {
      console.error(err);
      displayGeneralError();
    };
    setSubmitting(false);
  };

  const handleDeleteImageClick = async () => {
    try {
      if (images?.at(0) && images?.at(0) !== EMPTY_STRING) {
        await StorageService.deleteImage(StorageService.getFileNameFromURL(images?.at(0)), CELEBRATION_IMAGES_FOLDER);
      };
    } catch (error) {
      console.error(error);
      displayGeneralError();
    };
    if (celebrationToEdit) {
      await saveCelebrationToDB({
        ...celebrationToEdit,
        images: EMPTY_ARRAY,
        updatedAt: Date.now()
      }, true);
    };
    setImages(EMPTY_ARRAY);
    setAlertIsOpen(true);
    setAlertType(SUCCESS);
    setAlertMessage("Photo removed.");
  };

  const uploadCompressedFileDeleteOld = async (compressedFile: Blob) : Promise<void> => {
    if((compressedFile?.size / 1024) > MAX_COMPRESSED_FILE_SIZE_KB) {
      setAlertIsOpen(true);
      setAlertType(ERROR);
      setAlertMessage("Sorry, image is too big. Please select an image of smaller size.");
      return;
    }
    setImgUploadLoading(true);
    const imgref: StorageReference = await StorageService.uploadImage(compressedFile, CELEBRATION_IMAGES_FOLDER);
    const newImgUrl: string = await StorageService.getImageUrl(imgref);
    const oldImageUrl: string = images?.at(0);
    setImages([newImgUrl]);
    setImgUploadLoading(false);
    if (oldImageUrl && oldImageUrl !== EMPTY_STRING) {
      await StorageService.deleteImage(StorageService.getFileNameFromURL(oldImageUrl), CELEBRATION_IMAGES_FOLDER);
    };
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    let compressedFile: any;
    try {
      if(file && ((file.size / 1024) > MAX_FILE_SIZE_KB)) {
        compressedFile = await imageCompression(file, {
          maxSizeMB: 0.4,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        });
      } else {
        compressedFile = file;
      }
      await uploadCompressedFileDeleteOld(compressedFile);
    } catch (error) {
      console.error(error);
    }
  }

  const handleUploadButtonClick = () => {
    hiddenFileInput.current.click();
  };

  const handleModalClosed = async (event?: any) => {
    if (event) {
      event.preventDefault();
    };
    // if (images?.at(0) && images?.at(0) !== EMPTY_STRING) {
    //   await StorageService.deleteImage(StorageService.getFileNameFromURL(images?.at(0)), CELEBRATION_IMAGES_FOLDER);
    //   setImages(EMPTY_ARRAY);
    // };
    setCelebrationModalOpen(false);
    resetForm();
    setShowProductsToSelect(false);
  };

  const handleSubmitClick = async (event: any) => {
    event.preventDefault();
    if (type === EMPTY_STRING && description === EMPTY_STRING) {
      if (showProductsToSelect) {
        setShowProductsToSelect(false);
      };
      setAlertIsOpen(true);
      setAlertType(ERROR);
      setAlertMessage("Please add a celebration type and/or description.");
      return;
    };
    await saveCelebrationToDB({
      ...(celebrationToEdit ? celebrationToEdit : emptyCelebration),
      type,
      author: {
        id: currentUser?.id,
        username: currentUser?.username,
        name: currentUser?.name,
        image: StorageService.getImageOrEmpty(currentUser?.images),
        phone: currentUser?.phone,
        email: currentUser?.email,
        offersScope: currentUser?.offersScope,
      },
      description: description?.trim(),
      scope,
      images,
      selectedProducts: selectedProductsOnForm,
      createdAt: celebrationToEdit ? celebrationToEdit?.createdAt : Date.now(),
      updatedAt: Date.now()
    });
    setAlertIsOpen(true);
    setAlertType(SUCCESS);
    setAlertMessage("Celebration posted! 🎉");
    setShowProductsToSelect(false);
  };

  const handleAddOrDeleteTag = async (index: number, isAdding: boolean) => {
    await addOrDeleteTagHelper(index, isAdding, tagsOnArray, setProductIsLoading, setTagsOnArray, setAllProducts);
  };

  const handleResetClick = async () => {
    await resetTagsHelper(setProductIsLoading, setTagsOnArray, setAllProducts);
  };

  const getProductItem = (product: Product, index: number) => {
    return (
      <Item container direction="row" justifyContent="flex-start" alignItems="center" key={product?.id}
        className='center-width-24rem' style={{ marginRight: "0px", marginLeft: "0px" }}>
        <ProductImgGiftsList src={product?.images[0]} onClick={() => window.open(product?.url)} />
        <DetailsGiftsList item onClick={() => window.open(product?.url)}>
          <TextGiftsList style={{ fontSize: "15px", marginBottom: "5px" }}>{product?.title}</TextGiftsList>
          <SubText>{product?.shop?.name}</SubText>
          <SubText>£{product?.price?.amount}</SubText>
        </DetailsGiftsList>
        <ConnectionsIconButton style={{ position: "absolute", right: "0" }} onClick={() => handleAddProductClick(product, index)}>
          <IconSize25 className="fa-solid fa-plus" />
        </ConnectionsIconButton>
      </Item>
    );
  };

  const getProducts = async () => {
    try {
      setProductIsLoading(true);
      const productsList: Product[] = await ProductService.getProducts();
      setAllProducts(productsList);
      const showArray: boolean[] = new Array(productsList?.length).fill(true);
      productsList?.forEach((product, index) => {
        if (selectedProductsOnForm?.map(product => product?.id)?.includes(product?.id)) {
          showArray[index] = false;
        };
      });
      setShowProductsArrray(showArray);
      setProductIsLoading(false);
    } catch (err) {
      console.error("Error loading Products: ", err)
    }
  };

  const updateProductsListToPickFrom = (newSelectedProducts: Product[]) : void => {
    setProductIsLoading(true);
    const showArray: boolean[] = new Array(allProducts?.length).fill(true);
    allProducts?.forEach((pro, index) => {
      if (newSelectedProducts?.map(product => product?.id)?.includes(pro?.id)) {
        showArray[index] = false;
      };
    });
    setShowProductsArrray(showArray);
    setProductIsLoading(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (celebrationToEdit) {
      setType(celebrationToEdit?.type);
      setDescription(celebrationToEdit?.description);
      setScope(celebrationToEdit?.scope);
      if (celebrationToEdit?.images?.length > 0 && celebrationToEdit?.images?.at(0) !== EMPTY_STRING) {
        setImages(celebrationToEdit?.images);
      };
      if (celebrationToEdit?.selectedProducts?.length > 0) {
        setSelectedProductsOnForm(celebrationToEdit?.selectedProducts);
        updateProductsListToPickFrom(celebrationToEdit?.selectedProducts);
      };
    };
  }, [celebrationToEdit]);

  return (
    <><Modal show={celebrationModalOpen} onHide={handleModalClosed} centered>
        <ModalHeader>
          {!showProductsToSelect && <>
            <ProfileImg60x60Left src={StorageService.getImageOrDefault([currentUser?.images?.at(0)])}
              className='smaller-mobile-profile-img-40' style={{ position: "absolute", top: "16px", left: "16px" }} />
            <TopRightIconButtonBackground onClick={handleModalClosed}><i className="fa-solid fa-xmark" /></TopRightIconButtonBackground>
            <Title className='celebrations-title-mobile'>Post a celebration 🎉</Title>
            <FormControl size="small" margin='dense'>
              <InputLabel>Type</InputLabel>
              <Select value={type} label="Celebration category" onChange={handleKindChange}>
                <MenuItem value={NONE_TYPE}><span style={{ fontStyle: "italic" }}>{NONE_TYPE}</span></MenuItem>
                <MenuItem value={ACHIEVEMENT}>{ACHIEVEMENT}</MenuItem>
                <MenuItem value={ANNIVERSARY}>{ANNIVERSARY}</MenuItem>
                <MenuItem value={BIRTHDAY}>{BIRTHDAY}</MenuItem>
                <MenuItem value={ENGAGED}>{ENGAGED}</MenuItem>
                <MenuItem value={GOAL}>{GOAL}</MenuItem>
                <MenuItem value={GRADUATION}>{GRADUATION}</MenuItem>
                <MenuItem value={HOLIDAY}>{HOLIDAY}</MenuItem>
                <MenuItem value={MARRIED}>{MARRIED}</MenuItem>
                <MenuItem value={MILESTONE}>{MILESTONE}</MenuItem>
                <MenuItem value={NEW_BABY}>{NEW_BABY}</MenuItem>
                <MenuItem value={NEW_HOME}>{NEW_HOME}</MenuItem>
                <MenuItem value={NEW_JOB}>{NEW_JOB}</MenuItem>
                <MenuItem value={SOMETHING_NEW}>{SOMETHING_NEW}</MenuItem>
                <MenuItem value={WEDDING}>{WEDDING}</MenuItem>
                <MenuItem value={WORK_ANNIVERSARY}>{WORK_ANNIVERSARY}</MenuItem>
              </Select>
            </FormControl>
          </>}
          {showProductsToSelect && <div style={{ position: "relative" }}>
            <TopRightIconButtonBackground style={{ right: "0px", top: "0px" }} onClick={handleModalClosed}><i className="fa-solid fa-xmark" /></TopRightIconButtonBackground>
            <Title className='celebrations-title-mobile' style={{ marginBottom: "0px" }}>Add to your celebration wishlist</Title>
            <Text style={{ fontWeight: "500", marginTop: "5px" }}>Wishlist can contain 5 items.</Text>
            <BackIconButton onClick={() => setShowProductsToSelect(false)}><i className="fa-solid fa-arrow-left" /></BackIconButton>
            {selectedProductsOnForm?.length > 0 && <ChosenGiftsDiv>
              {selectedProductsOnForm?.map(productObj => (
                <ChosenGift key={productObj?.id}>
                  <ChosenGiftImage src={productObj?.images?.at(0)} onClick={() => window.open(productObj?.url)} />
                  <RemoveIconButton onClick={() => handleRemoveProductClick(productObj?.id)} className='mobile-w-h-22'>
                    <i className="fa-solid fa-xmark" style={{ fontSize: "15px" }} />
                  </RemoveIconButton>
                </ChosenGift>
              ))}
            </ChosenGiftsDiv>}
          </div>}
        </ModalHeader>
        <ModalBody style={{ marginBottom: "0px" }}>
        {!showProductsToSelect && <><form>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: "20px" }}>
            <Label style={{ padding: '0px 5px', marginBottom: '15px', textAlign: 'left', marginTop: "0px" }}>Description</Label>
            <TextArea value={description} onChange={handleDescriptionChange} rows={4} />
          </Grid>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: "20px" }}>
            <Label style={{ padding: '0px 5px', marginBottom: '15px', textAlign: 'left', marginTop: "0px" }}>Who can see your celebration?</Label>
            <RadioGroup onChange={handleScopeChange} value={scope}>
              <FormControlLabel style={{ height: "25px", marginBottom: "5px" }} value={EVERYONE} control={<Radio />} label={EVERYONE_OPTION} />
              <FormControlLabel style={{ height: "25px" }} value={CONTACTS} control={<Radio />} label={ONLY_MY_CONTACTS_OPTION} />
            </RadioGroup>
          </Grid>
          {images?.at(0) && images?.at(0) !== EMPTY_STRING &&
            <ImgContainter style={{ maxWidth: "none", marginBottom: "20px", position: "relative" }}>
              <CloseIconButtonNoHover onClick={handleDeleteImageClick}>
                <i className="fa-solid fa-xmark" />
              </CloseIconButtonNoHover>
              <Img style={{ maxWidth: "none" }} variant="top" src={images?.at(0)} />
            </ImgContainter>
          }
          <Paper>
            <Row container direction="row" justifyContent="space-between" alignItems="center">
              <Text>Add to celebration</Text>
              <div>
                {imgUploadLoading && <Spinner /> }
                {!imgUploadLoading && <>
                  <Tooltip title="Photo" enterDelay={2000} enterNextDelay={1000} placement='top'>
                    <IconButtonPadding7MarginLR2 onClick={handleUploadButtonClick}>
                      <i className="fa-regular fa-images" style={{ color: "#1976d2", opacity: 0.7 }} />
                    </IconButtonPadding7MarginLR2>
                  </Tooltip>
                  <input type="file" onChange={handleUpload} ref={hiddenFileInput} style={{display: 'none'}} accept="image/*" multiple={false} />
                </>}
                <Tooltip title="Wishlist" enterDelay={2000} enterNextDelay={1000} placement='top'>
                  <IconButtonPadding7MarginLR2 onClick={() => setShowProductsToSelect(true)}>
                    <i className="fas fa-gift" style={{ color: "#acace6" }} />
                  </IconButtonPadding7MarginLR2>
                </Tooltip>
              </div>
            </Row>
          </Paper>
          {selectedProductsOnForm?.length > 0 &&
            <LinkTextA style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "2px" }} onClick={() => setShowProductsToSelect(true)}>
              {selectedProductsOnForm?.length} item{selectedProductsOnForm?.length > 1 ? "s" : null} added to wishlist
            </LinkTextA>
          }
          {submitting &&
            <CenterDiv style={{ height: "50px", marginTop: "30px" }}>
              <CircularProgress style={{ width: "30px", height: "30px" }} />
            </CenterDiv>
          }
          {!submitting &&
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "30px" }}>
              <LowPriorityButton onClick={handleModalClosed}>Cancel</LowPriorityButton>
              <NoWidthButton onClick={handleSubmitClick}>{celebrationToEdit ? "Save" : "Post"}</NoWidthButton>
            </Grid>
          }
        </form>
        </>}
        {showProductsToSelect && !productIsLoading && <>
          <TagDiv className='center' style={{ maxWidth: "24rem" }}>
              <TagsStrip setShowTagsModal={setShowTagsModal} tagsOnArray={tagsOnArray} handleAddOrDeleteTag={handleAddOrDeleteTag} />
          </TagDiv>
          <ScrollableDiv style={{ maxHeight: "400px", height: "45vh" }}>
            {allProducts?.map((product, index) => showProductsArrray?.at(index) && getProductItem(product, index))}
          </ScrollableDiv>
          {submitting &&
            <CenterDiv style={{ height: "50px", marginTop: "30px", position: "absolute", bottom: "20px" }}>
              <CircularProgress style={{ width: "30px", height: "30px" }} />
            </CenterDiv>
          }
          {!submitting &&
            <FixedButton onClick={handleSubmitClick}>{celebrationToEdit ? "Save" : "Post"}</FixedButton>
          }</>
        }
        {showProductsToSelect && productIsLoading && <>
          <TagDiv className='center' style={{ maxWidth: "24rem" }}>
            <TagsStrip setShowTagsModal={setShowTagsModal} tagsOnArray={tagsOnArray} handleAddOrDeleteTag={handleAddOrDeleteTag} />
          </TagDiv>
          <div style={{ maxHeight: "400px", height: "45vh", overflowY: "scroll", padding: "10px" }}>
            <CenterDiv style={{ height: "50px", marginTop: "30px" }}>
              <CircularProgress style={{ width: "30px", height: "30px" }} />
            </CenterDiv>
          </div></>
        }
      </ModalBody>
    </Modal>
    <TagsModal showTagsModal={showTagsModal} setShowTagsModal={setShowTagsModal} tagsOnArray={tagsOnArray} handleAddOrDeleteTag={handleAddOrDeleteTag}
      handleResetClick={handleResetClick} />
    <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} /></>
  );
};

export default CelebrationFormModal;
