import { query, getDocs, collection, orderBy, startAfter, limit, where, and, or } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { OFFERS, CREATED_AT, CELEBRATIONS, EVERYONE, CONTACTS, GIFTER_ID, NOT_EQUALS, JON_ID, DESC,
  UNCONFIRMED, STATUS, EQUALS, COMPLETED, ACCEPTED } from "../constants";
import ConnectionService from "./connectionService";

const getInitialBatch = (entity: string) => {
  if (entity === OFFERS) {
    return query(
      collection(firestoreDb, entity),
      and(
        where(GIFTER_ID, NOT_EQUALS, JON_ID),
        or(where(STATUS, EQUALS, COMPLETED), where(STATUS, EQUALS, ACCEPTED))
      ),
      orderBy(CREATED_AT, DESC),
      limit(18)
    );
  };
  return query(
    collection(firestoreDb, entity),
    orderBy(CREATED_AT, DESC),
    limit(18)
  );
};

const getNextBatch = (entity: string, lastVisible: any) => {
  if (entity === OFFERS) {
    return query(
      collection(firestoreDb, entity),
      and(
        where(GIFTER_ID, NOT_EQUALS, JON_ID),
        or(where(STATUS, EQUALS, COMPLETED), where(STATUS, EQUALS, ACCEPTED))
      ),
      orderBy(CREATED_AT, DESC),
      startAfter(lastVisible),
      limit(18)
    );
  };
  return query(
    collection(firestoreDb, entity),
    orderBy(CREATED_AT, DESC),
    startAfter(lastVisible),
    limit(18)
  );
};

const isVisibleOffer = async (offerObj: Offer, currentUserId: string) : Promise<boolean> => {
  const gifterScope: string = offerObj?.scope?.gifter;
  const gifteeScope: string = offerObj?.scope?.giftee;
  if (offerObj?.giftee?.id === currentUserId || offerObj?.gifter?.id === currentUserId ||
    (gifteeScope === EVERYONE && gifterScope === EVERYONE)) {
    return true;
  };
  if (gifteeScope === UNCONFIRMED || gifterScope === UNCONFIRMED) {
    return false;
  };
  const gifterContacts: Connection[] = await ConnectionService.getConnectionsByUserId(gifterScope);
  const currentUserInGifterContants: boolean = gifterContacts?.map(conn => conn?.id).includes(currentUserId);
  if (gifterScope === CONTACTS && gifteeScope === EVERYONE) {
    return currentUserInGifterContants;
  };
  const gifteeContacts: Connection[] = await ConnectionService.getConnectionsByUserId(gifteeScope);
  const currentUserInGifteeContants: boolean = gifteeContacts?.map(conn => conn?.id).includes(currentUserId);
  if (gifteeScope === CONTACTS && gifterScope === EVERYONE) {
    return currentUserInGifteeContants;
  };
  if (gifterScope === CONTACTS && gifteeScope === CONTACTS) {
    return currentUserInGifterContants && currentUserInGifteeContants;
  };
  return true;
}

const isVisibleCelebration = async (celeObj: Celebration, currentUserId: string) : Promise<boolean> => {
  if (celeObj?.scope === EVERYONE || celeObj?.author?.id === currentUserId) {
    return true;
  };
  const authorContacts: Connection[] = await ConnectionService.getConnectionsByUserId(celeObj?.author?.id);
  return authorContacts?.map(conn => conn?.id).includes(currentUserId);
};

const getItemsAndCutoffSnapshots = async (currentUserId: string, next?: boolean, lastOffer?: any, lastCelebration?: any) : Promise<[FeedItem[], any, any]> => {
  const feedItems: FeedItem[] = [];
  let offersQuery: any;
  let celebrationsQuery: any;
  if (next) {
    if (lastOffer) {
      offersQuery = getNextBatch(OFFERS, lastOffer);
    };
    if (lastCelebration) {
      celebrationsQuery = getNextBatch(CELEBRATIONS, lastCelebration);
    };
  } else {
    offersQuery = getInitialBatch(OFFERS);
    celebrationsQuery = getInitialBatch(CELEBRATIONS);
  };
  let offersSnapshots: any;
  let celebrationsSnapshots: any;
  if (offersQuery) {
    offersSnapshots = await getDocs(offersQuery);
    if (offersSnapshots.size > 0) {
      const offerList: Offer[] = offersSnapshots?.docs?.map((offer: any) => offer.data() as Offer);
      for (const offerObj of offerList) {
        if (await isVisibleOffer(offerObj, currentUserId)) {
          feedItems.push(offerObj);
        };
      };
    };
  };
  if (celebrationsQuery) {
    celebrationsSnapshots = await getDocs(celebrationsQuery);
    if (celebrationsSnapshots.size > 0) {
      const celeList: Celebration[] = celebrationsSnapshots?.docs?.map((cele: any) => cele.data() as Celebration);
      for (const cele of celeList) {
        if (isVisibleCelebration(cele, currentUserId)) {
          feedItems.push(cele);
        };
      };
    };
  };
  return [
    feedItems.sort((item1, item2) => item2.createdAt - item1.createdAt),
    offersSnapshots ? offersSnapshots.docs[offersSnapshots.docs?.length - 1] : null,
    celebrationsSnapshots ? celebrationsSnapshots.docs[celebrationsSnapshots.docs?.length - 1]: null
  ];
};

const FeedCompositeService = {
  getItemsAndCutoffSnapshots
};

export default FeedCompositeService;
