import { FC, useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

import { EMPTY_STRING, STRIPE_RETURN_URL } from '../../constants';
import { ButtonGroup, PageHeading, ProductImg, NoWidthButton, ProfileImg40x40Left, Text, Title,
  ZeroMarginGridPadding10, ZeroMarginGrid, SmallSpinner } from './styled';
import ProductService from '../../services/productService';
import UserService from '../../services/userService';
import Status from './Status';
import StorageService from '../../services/storageService';
import { addMoneyAmounts, goToHomeOnClick, trimPrice } from '../../services/helpers';

const Row = styled(Stack)`
  margin-top: 30px;
  margin-bottom: 100px !important;
`

const ColumnTwo = styled.div`
  padding: 0px 10px;
`

const ColumnOne = styled(Stack)`
  margin-bottom: 30px;
`

interface CheckoutProps {
  offer: Offer;
}
const Checkout: FC<CheckoutProps> = ({ offer }) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [fullname, setFullname] = useState<string | null>(null);
  const [productPrice, setProductPrice] = useState<Price | null>(null);
  const [currency, setCurrency] = useState<string>(EMPTY_STRING);
  const [processingFee, setProcessingFee] = useState<string>(EMPTY_STRING);
  const [totalPrice, setTotalPrice] = useState<string>(EMPTY_STRING);

  const stripe = useStripe();
  const elements = useElements();

  const handleProfileClick = () => {
    window.location.assign(`/${offer?.giftee?.username}`);
    window.scrollTo(0, 0);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    };
    setIsDisabled(true);
    setSubmitting(true);
    try {
      const errorObj: never | { error: StripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: { return_url: STRIPE_RETURN_URL },
      });
      if (errorObj?.error) {
        setErrorMessage(errorObj?.error.message);
      };
    } catch (e) {
      console.error("Error in making payment: ", e);
    };
    setIsDisabled(false);
    setSubmitting(false);
  };

  useEffect(() => {
    const checkForStripeErrors = async () => {
      if (elements) {
        const res = await elements.fetchUpdates();
        if (Object.keys(res)?.length !== 0 && res?.error?.message) {
          setIsError(true);
        } else {
          setIsLoading(false);
        }
      }
    }

    checkForStripeErrors();
  }, [elements]);

  useEffect(() => {
    if (offer) {
      const priceObj = offer?.product?.price;
      const amount = priceObj?.amount;
      const shippingFee = priceObj?.shippingFee;
      const processFee = ProductService.getProcessingFee(amount, shippingFee);
      const total = ProductService.getTotalPrice(amount, shippingFee, processFee);
      const cur = ProductService.getCurrency(priceObj?.currencyCode);
      setFullname(offer?.giftee?.name);
      setProductPrice(priceObj);
      setCurrency(cur)
      setProcessingFee(processFee)
      setTotalPrice(total);
    }
  }, [offer]);

  return (<>
    {isError && <Status title='Sorry, something went wrong.' message='Please try again later or email jon@giftify.social for help.' button1="Home" button2="Gifts sent" /> }
    {!isError && !isLoading &&
      <Row direction={{ md: 'column', lg: 'row' }} justifyContent="center" alignItems="center" spacing={{ xs: 8, sm: 8, md: 8, lg: 4 }}>
        <ColumnOne>
          <PageHeading style={{ marginBottom: "20px" }}>Payment</PageHeading>
          <ZeroMarginGrid container direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "5px" }}>
            <ProfileImg40x40Left style={{ marginRight: "10px" }} onClick={handleProfileClick} id="myImg" src={StorageService.getImageOrDefault([offer.giftee?.image])} />
            <Text className='center-text-1580-px' style={{ fontWeight: 500, fontSize: "16px", marginBottom: "5px" }}>
              {fullname &&
                <><span onClick={handleProfileClick} style={{ cursor: "pointer", fontWeight: "bold" }}>{UserService.capitalizeFullname(fullname)}</span> will receive your gift at the address they saved with Giftify.</>
              }
              {!fullname &&
                <span onClick={handleProfileClick} style={{ cursor: "pointer", fontWeight: "bold" }}>{offer?.giftee?.username}</span>
              }
            </Text>
          </ZeroMarginGrid>
          <ZeroMarginGridPadding10 container direction="row" justifyContent="center" alignItems="center">
            <ProductImg src={offer?.product?.images[0]} onClick={() => window.open(offer?.product?.url)}
              style={{ width: "150px", height: "150px", marginBottom: "10px", marginTop: "10px" }} />
            <div style={{ marginLeft: "0px", cursor: "pointer", width: "100%" }}>
              <Title onClick={() => window.open(offer?.product?.url)} style={{ textAlign: "center" }}>{offer?.product?.title}</Title>
            </div>
          </ZeroMarginGridPadding10>
          <ZeroMarginGridPadding10 container spacing={3} justifyContent="space-around" columns={{ xs: 5, sm: 5, md: 12 }}>
            <ZeroMarginGridPadding10 item xs={2} md={4}>
              <Text>Item</Text>
            </ZeroMarginGridPadding10>
            <ZeroMarginGridPadding10 item xs={2} md={4}>
              <Text>{currency}{trimPrice(productPrice?.amount)}</Text>
            </ZeroMarginGridPadding10>
          </ZeroMarginGridPadding10>
          <ZeroMarginGridPadding10 container spacing={3} justifyContent="space-around" columns={{ xs: 5, sm: 5, md: 12 }}>
            <ZeroMarginGridPadding10 item xs={2} md={4}>
              <Text>Shipping & processing</Text>
            </ZeroMarginGridPadding10>
            <ZeroMarginGridPadding10 item xs={2} md={4}>
              <Text>{currency}{addMoneyAmounts(productPrice?.shippingFee, processingFee)}</Text>
            </ZeroMarginGridPadding10>
          </ZeroMarginGridPadding10>
          <ZeroMarginGridPadding10 container spacing={3} justifyContent="space-around" columns={{ xs: 5, sm: 5, md: 12 }}>
            <ZeroMarginGridPadding10 item xs={2} md={4}>
              <Text style={{ fontWeight: "bold", marginTop: "10px" }}>Total</Text>
            </ZeroMarginGridPadding10>
            <ZeroMarginGridPadding10 item xs={2} md={4}>
              <Text style={{ fontWeight: "bold", marginTop: "10px" }}>
                {currency}{trimPrice(totalPrice)}
              </Text>
            </ZeroMarginGridPadding10>
          </ZeroMarginGridPadding10>
        </ColumnOne>
        <ColumnTwo className='margin-bottom-1199'>
          <Text style={{ fontWeight: 500, fontSize: "16px", textAlign: "center", marginBottom: "15px" }}>Enter your card
            details and billing postcode to complete payment:</Text>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            {stripe && elements &&
              <ButtonGroup container direction="row" justifyContent="center" alignItems="center">
                <NoWidthButton onClick={goToHomeOnClick} type="button">Cancel</NoWidthButton>
                <NoWidthButton disabled={!stripe || isDisabled} type="submit">
                  {submitting ? <SmallSpinner animation="border" /> : <>Submit</>}
                </NoWidthButton>
              </ButtonGroup>
            }
            {errorMessage && <div>{errorMessage}</div>}
          </form>
        </ColumnTwo>
      </Row>
    }</>
  );
};

export default Checkout;
