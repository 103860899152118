import { addDoc, collection, doc, getDocs, getDoc, query, updateDoc, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { ACCOUNT_DOT_ID, BADGES, EQUALS } from "../constants";

const getUserBadge = async (userId: string): Promise<Badge | null> => {
  try {
    const badgesSnapshot = await getDocs(query(collection(firestoreDb, BADGES), where(ACCOUNT_DOT_ID, EQUALS, userId)));
    if (badgesSnapshot.size === 1) {
      return badgesSnapshot.docs.at(0).data() as Badge;
    }
    return null;
  } catch (e) {
    console.error("Error getting badge by userId: ", e);
  }
}

const addBadge = async (badge: Badge): Promise<string | null> => {
  try {
    const badgesSnapshot = await getDocs(query(collection(firestoreDb, BADGES), where(ACCOUNT_DOT_ID, EQUALS, badge?.account?.id)));
    if (badgesSnapshot.size === 1) {
      return null;
    }
    const badgeRef = await addDoc(collection(firestoreDb, BADGES), badge);
    await updateDoc(badgeRef, { id: badgeRef.id });
    return badgeRef.id;
  } catch (e) {
    console.error("Error adding badge: ", e);
  }
}

const updateBadge = async (badgeObj: Badge): Promise<void> => {
  try {
    const badgeRef = doc(firestoreDb, BADGES, badgeObj.id);
    const badgeSnapshot = await getDoc(badgeRef);
    if (badgeSnapshot.exists()) {
      await updateDoc(badgeRef, { ...badgeObj });
    }
  } catch (e) {
    console.error("Error updating badge: ", e);
  }
}

const getPointsIncrease = (purchaseValue: number) : number => {
  if (purchaseValue <= 1500) {
    return 3;
  } else if (purchaseValue > 1500 && purchaseValue <= 5000) {
    return 5;
  } else if (purchaseValue > 5000) {
    return 8;
  } else {
    return 0;
  }
};

const BadgeService = {
  getUserBadge,
  addBadge,
  updateBadge,
  getPointsIncrease
}

export default BadgeService;
