import { ChangeEvent, FC, useContext, useEffect, useState, KeyboardEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Chip, IconButton as MuiIconButton, Skeleton, Stack } from '@mui/material';
import { Send } from '@mui/icons-material';
import styled from '@emotion/styled';
import moment from 'moment';

import { ChipImg as BaseChipImg, BaseLeftIcon, MainDiv as BaseMainDiv, MessageBody as BaseMessageBody, NoWidthButton,
  ProfileImg40x40Left, ProfileImg60x60Left, SubText, Text, TextMargin0, DotSeparator } from '../children/styled';
import { EMPTY_STRING, ENTER, HOME_PATH, INBOX_PATH, MSGS_LIMIT, RESIZE, SLASH, T } from '../../constants';
import { AuthContext } from '../../providers/AuthProvider';
import RoomService from '../../services/roomService';
import { getCorrespondent, getSubUserObj, isSmallScreen, setUp, trimText } from '../../services/helpers';
import StorageService from '../../services/storageService';
import UserService from '../../services/userService';
import NotificationService from '../../services/notifcationService';

const MainDiv = styled(BaseMainDiv)`
  max-width: 100%;
  position: relative;
  margin-top: 0px;
  padding: 0px;
  top: 100px;
`

const Main = styled(Stack)`
  padding: 5px 16px;
  margin-top: 90px;
  margin-bottom: 60px;
`

const ContentRow = styled(Stack)``

const LeftColumn = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 0px 0px 0px 16px;
  height: 80vh;
  max-height: 900px;
`

const RoomItemsDiv = styled.div`
  overflow-y: scroll;
  height: 100%;
  max-height: 700px;
`

const RightColumn = styled(Stack)`
  border-left: 1px solid rgb(218, 219, 221);
  width: 100%;
  height: 80vh;
  position: relative;
`

const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
`

const TopStrip = styled.div`
  padding-bottom: 16px;
  padding-left: 16px;
  width: 100%;
  border-bottom: 1px solid rgb(218, 219, 221);
`

const TopStripMobile = styled(TopStrip)`
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 70px;
`

const CenteredDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const BackIconButton = styled(MuiIconButton)`
  background-color: transparent;
  padding: 7px 0px;
  padding-bottom: 3px;
  margin-right: 10px !important;

  &:focus {
    background-color: transparent;
  }
`

const SendIconButton = styled(MuiIconButton)`
  background-color: transparent;
  padding: 10px;

  &:focus {
    background-color: transparent;
  }
`

const Input = styled.input`
  border-radius: 10px;
  border: 1px solid rgba(206,206,206,1.00);
  font-size: 16px;
  padding: 5px 10px;
  width: 100%;

  &:focus {
    outline: none;
  }
`

const BottomInput = styled(Stack)`
  background-color: #fff;
  padding: 10px 30px 16px 10px;
  margin-top: 0px !important;
  width: 100%;
  width: -webkit-fill-available;
  width: fill-available;
`

const BlueNotification = styled.span`
  right: 16px;
  height: 8px;
  width: 8px;
  font-size: 11px;
  line-height: 15px;
  background-color: rgb(0, 149, 246);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  border: 1px solid rgb(0, 149, 246);
  text-align: center;
  color: white;
  z-index: 1;
`

const RoomItem = styled(Stack)`
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 8px 0px;

  &:hover {
    background-color: #fafafa;
  }
`

const MessageItem = styled(Stack)`
  margin-bottom: 10px;
  cursor: pointer;
`

const Info = styled.p`
  color: rgb(115, 115, 115);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`

const LoadingLine = styled(Skeleton)`
  width: 80% !important;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`

const MessageBody = styled(BaseMessageBody)`
  width: auto;
  max-width: 85%;
  margin-left: 5px;
`

const ChipImg = styled(BaseChipImg)`
  height: 32px;
  width: 32px;
  margin: 0px;
  cursor: pointer;
`

const TimeText = styled(Text)`
  margin-bottom: 18px;
  font-size: 12px;
  color: rgb(142, 142, 142);
`

const ReverseDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 16px;
  overflow-y: scroll;
`

const ColumnStackWithTime = styled(Stack)`
  margin-bottom: 10px;
`

const formatMessage = (message: string, index: number, previousMessage: string) => {
  if (message === EMPTY_STRING && previousMessage !== EMPTY_STRING) {
    return (<br key={index} />);
  };
  return (<TextMargin0 style={{ lineHeight: "20px", color: "inherit" }} key={index}>{message}</TextMargin0>);
};

const Inbox: FC = () => {
  const { currentUser, signOut } = useContext(AuthContext);
  const [roomsList, setRoomsList] = useState<Room[]>();
  const [messagesInSelectedRoom, setMessagesInSelectedRoom] = useState<RoomMessage[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<Room>();
  const [messageBeingTyped, setMessageBeingTyped] = useState<string>(EMPTY_STRING);
  const [newContact, setNewContact] = useState<SubUser>();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [correspondent, setCorrespondent] = useState<SubUser>();
  const [correspondentImg, setCorrespondentImg] = useState<string>();
  const [loadingRooms, setLoadingRooms] = useState<boolean>(false);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [hasMoreMsgs, setHasMoreMsgs] = useState<boolean>(false);
  const [lastRetrievedMsg, setLastRetrievedMsg] = useState<RoomMessage>();
  const [messageIdToShowTime, setMessageIdToShowTime] = useState<string>(EMPTY_STRING);

  const { roomId } = useParams();
  const bottomOfRoomRef = useRef(null);
  const inputRef = useRef(null);

  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
  };

  const handleEnterPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER) {
      await handleSendMessage();
    };
  };

  const handleSendMessage = async () => {
    if (messageBeingTyped.trim() !== EMPTY_STRING) {
      setMessageBeingTyped(EMPTY_STRING);
      scrollToBottom();
      inputRef.current.focus();
      await RoomService.addMessage(roomId, getSubUserObj(currentUser), messageBeingTyped);
    };
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessageBeingTyped(event.target.value);
  };

  const handleMessageItemClick = (messageId: string) => {
    if (messageIdToShowTime === messageId) {
      setMessageIdToShowTime(EMPTY_STRING);
      return;
    };
    setMessageIdToShowTime(messageId);
  };

  const handleBackButtonClickOnMobile = () => {
    setLoadingMessages(true);
    window.location.assign(INBOX_PATH);
  };

  const handleRoomItemClickOnMobile = (roomId: string) => {
    setLoadingMessages(true);
    window.location.assign(INBOX_PATH + SLASH + T + SLASH + roomId);
  };

  const showNotification = (roomObj: Room) : boolean => {
    return currentUser?.id !== roomObj?.lastSenderId && !roomObj?.seen;
  };

  const getCorrespondentImg = (members: SubUser[]) : string => {
    return StorageService.getImageOrDefault([getCorrespondent(currentUser?.id, members)?.image]);
  };

  const getCorrespondentNameOrUsername = (members: SubUser[]) : string => {
    const correspondent: SubUser = getCorrespondent(currentUser?.id, members);
    return UserService.getNameOrUsername(correspondent?.name, correspondent?.username);
  };

  const scrollToBottom = () => {
    bottomOfRoomRef?.current?.scrollIntoView({ behavior: 'instant' });
  };

  const getMessageBodyStyle = (message: RoomMessage) => {
    return { backgroundColor: `${message?.sender?.id === currentUser?.id ? "#3797F0" : "#F0F2F5"}`, color: `${message?.sender?.id === currentUser?.id ? "#FFFFFF" : "#050505"}` };
  };

  const getRoomObjAndUpdateDetails = async () : Promise<void> => {
    const roomObj: Room = await RoomService.getRoomById(roomId);
    if (!roomObj || !roomObj?.memberIds?.includes(currentUser?.id)) {
      signOut();
      window.location.assign(HOME_PATH);
      return;
    };
    setSelectedRoom(roomObj);
    const correspondentMember: SubUser = roomObj?.memberObjs?.find(member => member?.id !== currentUser?.id);
    setCorrespondent(correspondentMember);
    setCorrespondentImg(StorageService.getImageOrDefault([correspondentMember?.image]));
    await RoomService.updateRoomSetSeen(roomId);
    await NotificationService.removeMsgsNotifForRoom(currentUser?.id, roomId);
  };

  const fetchMsgsForRoom = async (initial?: boolean) => {
    setLoadingMessages(true);
    await getRoomObjAndUpdateDetails();
    let tupleItems: [RoomMessage[], any];
    if (initial) {
      tupleItems = await RoomService.getMessagesAndCutoff(roomId);
    } else {
      if (!lastRetrievedMsg) {
        return;
      };
      tupleItems = await RoomService.getMessagesAndCutoff(roomId, true, lastRetrievedMsg);
    };
    const retreivedMsgs: RoomMessage[] = tupleItems?.at(0);
    const cutOffMsg: RoomMessage = tupleItems?.at(1);
    if (retreivedMsgs?.length > 0) {
      retreivedMsgs && setMessagesInSelectedRoom(messagesInSelectedRoom?.concat(retreivedMsgs));
      cutOffMsg && setLastRetrievedMsg(cutOffMsg);
      if (retreivedMsgs?.length < MSGS_LIMIT) {
        setHasMoreMsgs(false);
      } else {
        setHasMoreMsgs(true);
      };
    };
    if (initial) {
      scrollToBottom();
    };
    setLoadingMessages(false);
  };

  const fetchRooms = async () => {
    setLoadingRooms(true);
    const allRooms: Room[] = await RoomService.getAllRoomsForUser(currentUser?.id);
    setRoomsList(allRooms);
    setLoadingRooms(false);
  };

  const addLatestMsgs = (newMsgs: RoomMessage[]) => {
    setMessagesInSelectedRoom(newMsgs.concat(messagesInSelectedRoom));
  };

  useEffect(() => {
    setUp(currentUser, signOut);

    if (currentUser) {
      fetchRooms();
      if (roomId) {
        fetchMsgsForRoom(true);
      };
    };

    window.addEventListener(RESIZE, handleWindowSizeChange);
    return () => {
      window.removeEventListener(RESIZE, handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (roomId && currentUser) {
      const unsubscribe = RoomService.listenForMessagesInRoom(roomId, messagesInSelectedRoom?.at(0), (msgs) => addLatestMsgs(msgs));
      return () => unsubscribe();
    };
  }, [roomId]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = RoomService.listenForRooms(currentUser?.id, (rooms) => setRoomsList(rooms));
      return () => unsubscribe();
    };
  }, [currentUser]);

  return (<>
    {isSmallScreen(width) && <>
      {!roomId && !newContact &&
        <Main justifyContent="flex-start" alignItems="flex-start" direction="column">
          <Title className='center-width-24rem'>Messages</Title>
          {!loadingRooms && !loadingMessages && roomsList && roomsList?.length > 0 &&
            <div className='center-width-24rem' style={{ overflowY: "scroll" }}>
              {roomsList?.map((roomObj, index) => (
                <RoomItem direction="row" justifyContent="flex-start" alignItems="center" spacing={2} key={roomObj?.id+index}
                  onClick={() => handleRoomItemClickOnMobile(roomObj?.id)}>
                  <ProfileImg60x60Left src={getCorrespondentImg(roomObj?.memberObjs)} style={{ width: "50px", height: "50px" }} />
                  <div>
                    <Text style={{ marginBottom: "0px", fontSize: "14px", fontWeight: `${showNotification(roomObj) ? 700 : 400}` }}>
                      {getCorrespondentNameOrUsername(roomObj?.memberObjs)}
                    </Text>
                    <div style={{ height: "6px" }} />
                    <SubText style={{ lineHeight: "13px", fontSize: "12px" }}>
                      {roomObj?.lastSenderId === currentUser?.id && <>You: </>}
                      {trimText(roomObj?.lastMessage, 10)}
                      <DotSeparator style={{ bottom: "1px" }}>•</DotSeparator>
                      {moment(roomObj?.lastMessageTimestamp).fromNow(true)}
                    </SubText>
                  </div>
                  {showNotification(roomObj) && <BlueNotification className='msg-notification-under-767' />}
                </RoomItem>))
              }
            </div>
          }
          {!loadingRooms && !loadingMessages && (!roomsList || roomsList?.length === 0) &&
            <Text className='center-width-24rem' style={{ marginTop: "20px" }}>
              No messages yet
            </Text>
          }
          {loadingRooms && <>
            {[1,2,3,4,5,6].map(i => (
              <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2} key={i}
                className='center-width-24rem' style={{ height: "60px" }}>
                <Skeleton variant="circular" width={40} height={40} />
                <LoadingLine variant="rounded" />
              </Stack>
            ))}
            </>
          }
        </Main>
      }
      {!loadingMessages && !loadingRooms && (roomId || newContact) && <>
        <TopStripMobile style={{ position: "fixed" }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" key={selectedRoom?.id} spacing={1}>
            <BackIconButton onClick={handleBackButtonClickOnMobile}>
              <i className="fa-solid fa-chevron-left" style={{ fontSize: "17px" }} />
            </BackIconButton>
            {correspondent && <>
              <ProfileImg40x40Left src={correspondentImg} onClick={() => window.location.assign(`/${correspondent?.username}`)} />
              <Text style={{ marginBottom: "0px", marginLeft: "10px", cursor: "pointer" }} onClick={() => window.location.assign(`/${correspondent?.username}`)}>
                {UserService.getNameOrUsername(correspondent?.name, correspondent?.username)}
              </Text></>
            }
            {!correspondent && <>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rounded" width={150} height={15} /></>
            }
          </Stack>
        </TopStripMobile>
        <ReverseDiv style={{ marginTop: "126px", marginBottom: "80px", height: `${window.screen.height - 70 - 57 - 76 - 1}px` }}>
          <div ref={bottomOfRoomRef} />
          {messagesInSelectedRoom?.map((message, index) => (
            <div key={message?.id + index}>
              {message?.sender?.id === currentUser?.id &&
                <ColumnStackWithTime direction="column" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "flex-end" }}>
                  <MessageItem direction="row-reverse" justifyContent="flex-start" alignItems="flex-end" spacing={0.5}
                    onClick={() => handleMessageItemClick(message?.id)} style={{ float: "right" }}>
                    <ChipImg src={StorageService.getImageOrDefault([message?.sender?.image])} />
                    <MessageBody item className='width-80-max-397' style={getMessageBodyStyle(message)}>
                      {message.content?.split(/\r?\n/)?.map((text, i, arr) => (
                        formatMessage(text, i, arr[i - 1])
                      ))}
                    </MessageBody>
                  </MessageItem>
                  {messageIdToShowTime === message?.id && <TimeText style={{ marginRight: "40px", float: "right" }}>{moment(message?.createdAt).format('lll')}</TimeText>}
                </ColumnStackWithTime>
              }
              {message?.sender?.id !== currentUser?.id && <>
                <MessageItem direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0.5}
                  onClick={() => handleMessageItemClick(message?.id)}>
                  <ChipImg src={StorageService.getImageOrDefault([message?.sender?.image])} />
                  <MessageBody item className='width-80-max-397' style={getMessageBodyStyle(message)}>
                    {message.content?.split(/\r?\n/)?.map((text, i, arr) => (
                      formatMessage(text, i, arr[i - 1])
                    ))}
                  </MessageBody>
                </MessageItem>
                {messageIdToShowTime === message?.id && <TimeText style={{ marginLeft: "40px" }}>{moment(message?.createdAt).format('lll')}</TimeText>}</>
              }
            </div>
          ))}
          {hasMoreMsgs &&
            <div style={{ width: "100%", textAlign: "center", marginBottom: "5px" }}>
              <Chip onClick={() => fetchMsgsForRoom()} label="Older messages" variant="outlined" style={{ fontSize: "13px", lineHeight: "16px", height: "25px" }} />
            </div>
          }
        </ReverseDiv>
        <BottomInput direction="row" justifyContent="center" alignItems="center" spacing={0.5} className="bottom-input-under-767">
          <Input ref={inputRef} type="text" placeholder='Message...' value={messageBeingTyped} onChange={handleMessageChange} onKeyDown={handleEnterPress} />
          <SendIconButton onClick={handleSendMessage}>
            <Send style={{ color: "#222325" }} />
          </SendIconButton>
        </BottomInput></>
      }
      {loadingMessages &&
        <CenteredDiv>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Skeleton variant="circular" width={80} height={80} />
            <Skeleton variant="rounded" width={210} height={15} style={{ borderRadius: "15px", marginTop: "15px" }} />
            <Skeleton variant="text" width={90} />
          </Stack>
        </CenteredDiv>
      }
    </>}
    {!isSmallScreen(width) &&
      <MainDiv>
        <ContentRow direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
          <LeftColumn>
            <Title style={{ margin: "10px 0px 20px 0px" }}>Messages</Title>
            {!loadingRooms && roomsList && roomsList?.length > 0 &&
              <RoomItemsDiv>
                {roomsList?.map((roomObj, index) => (
                  <RoomItem direction="row" justifyContent="flex-start" alignItems="center" spacing={2} key={roomObj?.id+index}
                    onClick={() => window.location.assign(INBOX_PATH + SLASH + T + SLASH + roomObj?.id)}
                    style={{ backgroundColor: `${selectedRoom?.id === roomObj?.id ? "#f0ecec": "$fff"}`, padding: "8px", borderRadius: "8px" }}>
                    <ProfileImg60x60Left src={getCorrespondentImg(roomObj?.memberObjs)} />
                    <div>
                      <Text style={{ marginBottom: "0px", fontSize: "14px", fontWeight: `${showNotification(roomObj) ? 700 : 400}` }}>
                        {getCorrespondentNameOrUsername(roomObj?.memberObjs)}
                      </Text>
                      <div style={{ height: "6px" }} />
                      <SubText style={{ lineHeight: "13px", fontSize: "12px", maxWidth: "170px" }}>
                        {roomObj?.lastSenderId === currentUser?.id && <>You: </>}
                        {trimText(roomObj?.lastMessage, 10)}
                        <DotSeparator style={{ bottom: "1px" }}>•</DotSeparator>
                        {moment(roomObj?.lastMessageTimestamp).fromNow(true)}
                      </SubText>
                    </div>
                    {showNotification(roomObj) && <BlueNotification />}
                  </RoomItem>))
                }
              </RoomItemsDiv>
            }
            {!loadingRooms && (!roomsList || roomsList?.length === 0) &&
              <Text style={{ marginTop: "20px" }}>No messages yet</Text>
            }
            {loadingRooms && <>
              {[1,2,3,4,5,6,7,8,9,10].map(i => (
                <Stack direction="row" alignItems="center" justifyContent="flex-start" style={{ width: "90%", height: "60px" }} spacing={2} key={i}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <LoadingLine variant="rounded" />
                </Stack>
              ))}
              </>
            }
          </LeftColumn>
          <RightColumn direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            {!loadingMessages && !roomId && !newContact &&
              <CenteredDiv>
                <Info style={{ marginBottom: "0px" }}>Search Giftify for a contact to start a chat.</Info>
              </CenteredDiv>
            }
            {!loadingMessages && (roomId || (!roomId && newContact)) &&
              <TopStrip>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" key={selectedRoom?.id} spacing={2}>
                  {correspondent && <>
                    <ProfileImg40x40Left src={correspondentImg} onClick={() => window.location.assign(`/${correspondent?.username}`)} />
                    <Text style={{ marginBottom: "0px", cursor: "pointer" }} onClick={() => window.location.assign(`/${correspondent?.username}`)}>
                      {UserService.getNameOrUsername(correspondent?.name, correspondent?.username)}
                    </Text></>
                  }
                  {!correspondent && <>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" width={210} height={15} /></>
                  }
                </Stack>
              </TopStrip>
            }
            {!loadingMessages && roomId && <>
              <ReverseDiv className="msgs-height-various">
                <div ref={bottomOfRoomRef} />
                {messagesInSelectedRoom?.map((message, index) => (
                  <div key={message?.id + index}>
                    {message?.sender?.id === currentUser?.id &&
                      <ColumnStackWithTime direction="column" spacing={1} sx={{ justifyContent: "flex-start", alignItems: "flex-end" }}>
                        <MessageItem direction="row-reverse" justifyContent="flex-start" alignItems="flex-end" spacing={0.5}
                          onClick={() => handleMessageItemClick(message?.id)} style={{ float: "right" }}>
                          <ChipImg src={StorageService.getImageOrDefault([message?.sender?.image])} />
                          <MessageBody item style={getMessageBodyStyle(message)}>
                            {message.content?.split(/\r?\n/)?.map((text, i, arr) => (
                              formatMessage(text, i, arr[i - 1])
                            ))}
                          </MessageBody>
                        </MessageItem>
                        {messageIdToShowTime === message?.id && <TimeText style={{ marginRight: "40px", float: "right" }}>{moment(message?.createdAt).format('lll')}</TimeText>}
                      </ColumnStackWithTime>
                    }
                    {message?.sender?.id !== currentUser?.id && <>
                      <MessageItem direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0.5}
                        onClick={() => handleMessageItemClick(message?.id)}>
                        <ChipImg src={StorageService.getImageOrDefault([message?.sender?.image])} />
                        <MessageBody item style={getMessageBodyStyle(message)}>
                          {message.content?.split(/\r?\n/)?.map((text, i, arr) => (
                            formatMessage(text, i, arr[i - 1])
                          ))}
                        </MessageBody>
                      </MessageItem>
                      {messageIdToShowTime === message?.id && <TimeText style={{ marginLeft: "40px" }}>{moment(message?.createdAt).format('lll')}</TimeText>}</>
                    }
                  </div>
                ))}
                {hasMoreMsgs &&
                  <div style={{ width: "100%", textAlign: "center", marginBottom: "5px" }}>
                    <Chip onClick={() => fetchMsgsForRoom()} label="Older messages" variant="outlined" style={{ fontSize: "13px", lineHeight: "16px", height: "25px" }} />
                  </div>
                }
              </ReverseDiv>
              <BottomInput direction="row" justifyContent="center" alignItems="center" spacing={0.5} style={{ left: "300px" }}>
                <Input ref={inputRef} type="text" placeholder='Message...' value={messageBeingTyped} onChange={handleMessageChange} onKeyDown={handleEnterPress} />
                <SendIconButton onClick={handleSendMessage}>
                  <Send style={{ color: "#222325" }} />
                </SendIconButton>
              </BottomInput></>
            }
            {!loadingMessages && !roomId && newContact &&
              <CenteredDiv>
                <Stack direction="column" justifyContent="center" alignItems="center">
                  <Info className='center-width-24rem'>
                    Looks like {UserService.getNameOrUsername(correspondent?.name, correspondent?.username)} is not in your connections. You can start a conversation if you send a connection request or a gift that they accept.
                  </Info>
                  <NoWidthButton><BaseLeftIcon className="fas fa-gift" /> Send gift</NoWidthButton>
                </Stack>
              </CenteredDiv>
            }
            {loadingMessages &&
              <CenteredDiv>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                  <Skeleton variant="circular" width={80} height={80} />
                  <Skeleton variant="rounded" width={210} height={15} style={{ borderRadius: "15px", marginTop: "15px" }} />
                  <Skeleton variant="text" width={90} />
                </Stack>
              </CenteredDiv>
            }
          </RightColumn>
        </ContentRow>
      </MainDiv>
    }</>
  );
};

export default Inbox;
