import { FC, useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { CircularProgress, Grid as MuiGrid } from '@mui/material';
import { User, onAuthStateChanged } from "firebase/auth";

import { AuthContext } from '../../providers/AuthProvider';
import { auth } from '../../firebase';
import Offer from '../children/Offer';
import { PRODUCTS_PATH, EMPTY_STRING, LOGIN_PATH, RECEIVED_PATH, SENT_PATH,
  GIFTS_ACCEPTED, GIFTS_RECEIVED, GIFTEE_ID, GIFTER_ID } from '../../constants';
import OfferService from '../../services/offerService';
import ShippingAddressModal from '../children/ShippingAddressModal';
import UserService from '../../services/userService';
import { ButtonDiv, PageHeading, TabHover, Tab, TabButtonText, TabHr, CenterDiv, MainDiv } from '../children/styled';
import Alert from '../children/Alert';
import { displayShareInfoModal, removeNotification, sendToProfile } from '../../services/helpers';
import BadgeModal from '../children/BadgeModal';
import NotificationService from '../../services/notifcationService';
import PromoModal from '../children/PromoModal';

const Main = styled(MuiGrid)`
   margin-top: 40px;
   margin-bottom: 130px;
`

const Text = styled.p`
  color: #050505;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  font-size: 1.0625rem;
  margin-bottom: 30px;
`

interface OffersProps {
   userSent: boolean;
};
const Offers: FC<OffersProps> = ({ userSent }) => {
  const { currentUser, setCurrentUserToStorage, signOut } = useContext(AuthContext);
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [receivedGifts, setReceivedGifts] = useState<Offer[]>([]);
  const [sentGifts, setSentGifts] = useState<Offer[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>();
  const [showShareInfoModal, setShowShareInfoModal] = useState<boolean>(false);
  const [userLink, setUserLink] = useState<string>(EMPTY_STRING);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [showReceived, setShowReceived] = useState<boolean>(!userSent);
  const [offerEdited, setOfferEdited] = useState<Offer | null>();
  const [showBadgeModal, setShowBadgeModal] = useState<boolean>(false);
  const [offerForBadge, setOfferForBadge] = useState<Offer | null>();
  const [sentNotifs, setSentNotifs] = useState<number>(0);

  const handleFindClick = () => {
    window.location.assign(PRODUCTS_PATH);
    window.scrollTo(0, 0);
  }

  const getOffers = async (firstTimeOnly?: boolean) : Promise<void> => {
    setLoading(true);
    try {
      const receivedList: Offer[] = await OfferService.getOffersByUser(currentUser?.id, GIFTEE_ID);
      const sentList: Offer[] = await OfferService.getOffersByUser(currentUser?.id, GIFTER_ID);
      setReceivedGifts(receivedList);
      setSentGifts(sentList);
      if (firstTimeOnly && receivedList?.length === 0 && sentList?.length > 0) {
        setShowReceived(false);
      };
      setLoading(false);
    } catch (err) {
      console.error("Error loading offers: ", err)
    }
  };

  const handleTabClick = async (receivedClicked: boolean) => {
    if (receivedClicked) {
      setShowReceived(true);
    } else {
      setShowReceived(false);
    };
    await getOffers();
  };

  const updateCachedUser = async (): Promise<void> => {
    const account = await UserService.getAccountById(currentUser?.id);
    setCurrentUserToStorage(account);
  };

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
    });
  };

  const checkForNotifs = async () => {
    const notifs: Notifications = await NotificationService.getNotifications(currentUser?.id);
    setSentNotifs(notifs?.giftsAccepted);
  }

  useEffect(() => {
    setUp();
    if (!displayShareInfoModal(currentUser?.username, setUserLink, setShowShareInfoModal)) {
      sendToProfile();
    };
    updateCachedUser();
    getOffers(true);
  }, []);

  useEffect(() => {
    if (showReceived) {
      window.history.pushState(null, EMPTY_STRING, RECEIVED_PATH);
      removeNotification(currentUser?.id, GIFTS_RECEIVED);
    } else {
      window.history.pushState(null, EMPTY_STRING, SENT_PATH);
      removeNotification(currentUser?.id, GIFTS_ACCEPTED);
    };
  }, [showReceived]);

  useEffect(() => {
    if (offerEdited) {
      setReceivedGifts(receivedGifts?.map(receivedOffer => receivedOffer?.id === offerEdited?.id ? { ...receivedOffer, ...offerEdited } : receivedOffer));
    };
  }, [offerEdited]);

  useEffect(() => {
    if (currentUser) {
      checkForNotifs();
    };
  }, [currentUser]);

  return (
     <MainDiv>
      <Main container justifyContent="flex-start" alignItems="flex-start" direction="column" className='margin-top-20-397'>
        <PageHeading className='center-width-24rem' style={{ marginBottom: "7px" }}>Gifts</PageHeading>
        <MuiGrid container direction="row" justifyContent="space-around" alignItems="center" className='center-width-24rem'
          style={{ marginBottom: "40px", borderBottom: "1px solid #CED0D4" }}>
          {showReceived && <>
            <Tab><TabButtonText>Received</TabButtonText><TabHr /></Tab>
            <TabHover onClick={() => handleTabClick(false)}>Sent {sentNotifs > 0 && <>({sentNotifs})</>}</TabHover></>
          }
          {!showReceived && <>
            <TabHover onClick={() => handleTabClick(true)}>Received</TabHover>
            <Tab><TabButtonText>Sent</TabButtonText><TabHr /></Tab></>
          }
        </MuiGrid>
        {loading &&
          <CenterDiv style={{ height: "50px", marginTop: "30px" }}>
            <CircularProgress style={{ width: "30px", height: "30px" }} />
          </CenterDiv>
        }
        {!loading && <>
          {showReceived && receivedGifts?.length > 0 &&
            <>{receivedGifts.sort((a, b) => b.updatedAt - a.updatedAt)?.map(offer => (<Offer offer={offer} userSent={!showReceived} setAddressModalOpen={setAddressModalOpen} currentUser={currentUser}
                key={offer.id} setSelectedOffer={setSelectedOffer} setShowBadgeModal={setShowBadgeModal} setOfferForBadge={setOfferForBadge} />))}
            </>
          }
          {showReceived && receivedGifts?.length === 0 &&
            <div className='center-width-24rem'>
              <Text>You haven't received any gifts yet.</Text>
            </div>
          }
          {!showReceived && sentGifts?.length > 0 &&
            <>{sentGifts.sort((a, b) => b.updatedAt - a.updatedAt)?.map(offer => (<Offer offer={offer} userSent={!showReceived} setAddressModalOpen={setAddressModalOpen} currentUser={currentUser}
                key={offer.id} setSelectedOffer={setSelectedOffer} setShowBadgeModal={setShowBadgeModal} setOfferForBadge={setOfferForBadge} />))}
            </>
          }
          {!showReceived && sentGifts?.length === 0 &&
            <div className='center-width-24rem'>
              <Text>You haven't sent any gifts yet.</Text>
              <ButtonDiv onClick={handleFindClick}>Start gifting</ButtonDiv>
            </div>
          }
        </>}
      </Main>
      <PromoModal showShareInfoModal={showShareInfoModal} setShowShareInfoModal={setShowShareInfoModal} />
      <ShippingAddressModal offer={selectedOffer} setOfferEdited={setOfferEdited} addressModalOpen={addressModalOpen} setAddressModalOpen={setAddressModalOpen}
        setAlertIsOpen={setAlertIsOpen} setAlertType={setAlertType} setAlertMessage={setAlertMessage} />
      <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} />
      <BadgeModal showBadgeModal={showBadgeModal} offerForBadge={offerForBadge} setShowBadgeModal={setShowBadgeModal} />
     </MainDiv>
  );
};

export default Offers;
