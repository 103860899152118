import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from "@emotion/styled/macro";
import { Location } from 'react-router-dom';

import {
  ABOUT_PATH, ACTIVE, PRODUCTS_PATH, HOME_PATH, LOGIN_PATH, MD_DOWN, REGISTER_PATH,
  GIFTS_N, CONNECTIONS_N, INVITE_LINK_N, LOGOUT_N, EARN_POINTS_N, PRODUCTS_N
} from '../../constants';
import { AccountsImg, PrimaryLink, NavNotifButton, Title, GrayLink, GrayLinkNoHover } from './styled';
import Search from './Search';
import { goToEarnSetup } from '../../services/helpers';
import UserService from '../../services/userService';

const Ul = styled.ul`
   list-style: none;
   font-size: 14px;
   font-weight: 300;
   letter-spacing: 0.04em;
   text-align: left;
   color: rgba(30, 28, 36, 0.85);
   padding: 16px;
`

const Item = styled.li`
  padding: 8px;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;

  :hover {
    background-color: rgba(68, 73, 80, 0.15);
    border-radius: 16px;
  }
`

const Button = styled(PrimaryLink)`
  background-color: #8dd4f6 !important;
  cursor: pointer;
`

const NavItem = styled.span`
  left: 60px;
  top: 10px;
  position: absolute;
`

interface NavModalProps {
  currentUser: Account
  profileImg: string
  notifications: Notifications
  fullNavbar: boolean
  requestsNotifCSS: boolean
  giftsNotifCSS: boolean
  giftsAcceptedCSS: boolean
  congratsNotifCSS: boolean
  addedNotifCSS: boolean
  location: Location
  setFullNavbar: (val: boolean) => void
  handleGiftIconClick: () => void
  handleProfileClick: () => void
  handleConnectionsClick: () => void
  handleGetInviteClick: () => void
  handleRequestsClick: () => void
  handleLogoutClick: () => void
  handleFindClick: () => void
  handleRegisterClick: () => void
  handleLoginClick: () => void
};
const NavModal: FC<NavModalProps> = ({
    currentUser,
    profileImg,
    notifications,
    fullNavbar,
    requestsNotifCSS,
    giftsNotifCSS,
    giftsAcceptedCSS,
    congratsNotifCSS,
    addedNotifCSS,
    location,
    setFullNavbar,
    handleGiftIconClick,
    handleProfileClick,
    handleConnectionsClick,
    handleGetInviteClick,
    handleLogoutClick,
    handleFindClick,
    handleRegisterClick,
    handleLoginClick
  }) => {

  const handleOnClose = () => {
    setFullNavbar(false);
  }

  const handleAboutClick = () => {
    setFullNavbar(false);
    window.location.assign(ABOUT_PATH);
  };

  return (
    <Modal show={fullNavbar} fullscreen={MD_DOWN} onHide={handleOnClose}>
      <Modal.Header closeButton>
        { currentUser && <Search /> }
        { !currentUser && <Title style={{ fontSize: "20px", padding: "0px 10px" }}>Join Giftify and get gifts 🎁</Title> }
      </Modal.Header>
      <Ul>
        {currentUser && <>
          <Item onClick={handleProfileClick}>
            <AccountsImg src={profileImg} id="img" style={{ height: "34px", width:"34px", marginRight: "17px" }} />
            {UserService.getNameOrUsername(currentUser?.name, currentUser?.username)}
            {notifications?.congratsReceived && notifications?.congratsReceived?.length > 0 && <>
              <NavNotifButton className={`request-notification ${congratsNotifCSS ? ACTIVE : null }`}>
                {notifications?.congratsReceived?.length > 9 ?
                  <>9+</>
                  :
                  notifications?.congratsReceived?.length
                }
              </NavNotifButton></>
            }
          </Item>
          <Item onClick={handleGiftIconClick}>
            <i className="fa-solid fa-gift" style={{ fontSize: "17px", margin: "3px 9px 0px 8px" }} />
            <NavItem>{GIFTS_N}</NavItem>
            {!isNaN(notifications?.giftsReceived + notifications?.giftsAccepted) &&
              <NavNotifButton className={`gift-notification ${(giftsNotifCSS || giftsAcceptedCSS) ? ACTIVE : null}`}>
                { notifications?.giftsReceived + notifications?.giftsAccepted > 9 ?
                  <>9+</>
                  :
                  notifications?.giftsReceived + notifications?.giftsAccepted
                }
              </NavNotifButton>
            }
          </Item>
          <Item onClick={goToEarnSetup}>
            <i className="fa-solid fa-award" style={{ fontSize: "18px", margin: "3px 9px 0px 10px", position: "relative", top: "1px" }} />
            <NavItem>{EARN_POINTS_N}</NavItem>
          </Item>
          <Item onClick={handleFindClick}>
            <i className="fa-solid fa-store" style={{ fontSize: "18px", margin: "3px 9px 0px 7px", position: "relative", top: "1px" }} />
            <NavItem>{PRODUCTS_N}</NavItem>
          </Item>
          <Item onClick={handleConnectionsClick}>
            <i className="fa-solid fa-user-group" style={{ fontSize: "15px", margin: "5px 9px 0px 7px" }} />
            <NavItem>{CONNECTIONS_N}</NavItem>
            {!isNaN(notifications?.connectionsAdded + notifications?.connectionRequests) &&
              <NavNotifButton className={`request-notification ${(addedNotifCSS || requestsNotifCSS) ? ACTIVE : null}`}>
                { notifications?.connectionsAdded + notifications?.connectionRequests > 9 ?
                  <>9+</>
                  :
                  notifications?.connectionsAdded + notifications?.connectionRequests
                }
              </NavNotifButton>
            }
          </Item>
          <Item onClick={handleGetInviteClick}>
            <i className="fa-solid fa-share-nodes" style={{ fontSize: "17px", margin: "5px 0px 0px 7px" }} />
            <NavItem>{INVITE_LINK_N}</NavItem>
          </Item>
          <Item onClick={handleLogoutClick}>
            <i className="fa-solid fa-right-from-bracket" style={{ fontSize: "17px", margin: "5px 0px 0px 7px" }} />
            <NavItem>{LOGOUT_N}</NavItem>
          </Item>
          <GrayLink style={{ top: "2px" }} onClick={handleAboutClick}>About</GrayLink>
          <GrayLinkNoHover style={{ marginLeft: "3px", marginRight: "3px", top: "2px" }}>·</GrayLinkNoHover>
          <GrayLinkNoHover style={{ top: "2px" }}>Giftify © 2024</GrayLinkNoHover></>
        }
        {!currentUser && <>
          { location.pathname !== PRODUCTS_PATH && <Button onClick={handleFindClick} className="navmodal-buttons">Find a gift</Button> }
          { location.pathname !== HOME_PATH && <Button onClick={handleAboutClick} className="navmodal-buttons">About</Button> }
          { location.pathname !== LOGIN_PATH && <Button onClick={handleLoginClick} className="navmodal-buttons">Login</Button> }
          { location.pathname !== REGISTER_PATH && <Button onClick={handleRegisterClick} className="navmodal-buttons">Sign up</Button> }</>
        }
      </Ul>
    </Modal>
  );
}

export default NavModal;
