import { FC, useState, ChangeEvent, useContext, useEffect, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { UserCredential, User, signInWithEmailAndPassword, signInWithPopup, sendEmailVerification } from "firebase/auth";
import { CircularProgress } from '@mui/material';

import { auth, googleAuthProvider } from '../../firebase';
import { AuthContext } from '../../providers/AuthProvider';
import UserService from '../../services/userService';
import { ButtonStretched, Container, Error, FooterContainer, GoogleButton, GoogleIconImg, LoginRegisterHeading,
  LoginSignUpText, WhiteSpinnerSpan } from '../children/styled';
import { CREATE_PATH, CURRENT_USER, EMPTY_STRING, ENTER, HOME_PATH, LOGIN_RECOVER_PATH, REGISTER_PATH } from '../../constants';
import GoogleIcon from "../../images/google.svg";
import { emptyAccount } from '../../services/emptyObjs';
import { sendToProfile } from '../../services/helpers';
import Footer from '../children/Footer';

const Input = styled.input`
  width: 95%;
  max-width: 364px;
  margin-bottom: 18px;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const Span = styled.span`
  font-family: ABCGintoNormal-Bold;
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: bold;
`

const Login: FC = () => {
  const [email, setEmail] = useState(EMPTY_STRING);
  const [password, setPassword] = useState(EMPTY_STRING);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [googleIsLoading, setGoogleIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginError(false);
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginError(false);
    setPassword(event.target.value);
  }

  const handleSignUpClick = () => {
    setLoginError(false);
    window.location.assign(REGISTER_PATH);
    window.scrollTo(0, 0);
  }

  const handleForgotPasswordClick = async () => {
    setLoginError(false);
    window.location.assign(LOGIN_RECOVER_PATH);
    window.scrollTo(0, 0);
  };

  const handleSignInGoogleClick = async () => {
    setGoogleIsLoading(true);
    try {
      const userCredential: UserCredential = await signInWithPopup(auth, googleAuthProvider);
      if (!userCredential) {
        return;
      }
      const authUser: User = userCredential.user;
      if (authUser && setCurrentUserToStorage) {
        const account: Account = await UserService.getAccountById(authUser.uid);
        if (account) {
          setCurrentUserToStorage({ ...account });
          window.location.assign(HOME_PATH);
          return;
        }
        setCurrentUserToStorage({
          ...emptyAccount,
          id: authUser.uid,
          email: authUser.email,
          createdAt: Date.now(),
          updatedAt: Date.now()
        });
        window.location.assign(CREATE_PATH);
      }
      setGoogleIsLoading(false);
    } catch (error) {
      setLoginError(true);
      setErrorMessage("Cannot log you in with that account. Please try with another.");
    }
  };

  const handleLoginClick = async () => {
    if (email === EMPTY_STRING || password === EMPTY_STRING) {
      setLoginError(true);
      setErrorMessage("Please enter your email and password.");
      return;
    }
    setLoading(true);
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      const authUser: User = userCredential.user;
      if (authUser && setCurrentUserToStorage) {
        const account: Account = await UserService.getAccountById(authUser.uid);
        if (account) {
          setCurrentUserToStorage({ ...account });
          if (auth.currentUser.emailVerified) {
            window.location.assign(HOME_PATH);
            return;
          }
          await sendEmailVerification(auth.currentUser);
          sendToProfile();
          return;
        };
        if (!localStorage.getItem(CURRENT_USER)) {
          setCurrentUserToStorage({
            ...emptyAccount,
            id: authUser.uid,
            email: authUser.email,
            createdAt: Date.now(),
            updatedAt: Date.now()
          });
        }
        window.location.assign(CREATE_PATH);
      };
    } catch (error) {
      setLoginError(true);
      setErrorMessage("Cannot find account with that email and password. Please make sure your email and password are correct.");
    }
    setLoading(false);
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER) {
      handleLoginClick()
    }
  };

  useEffect(() => {
    if (currentUser) {
      window.location.assign(HOME_PATH);
    }
  }, []);

  return (
    <FooterContainer>
      <Container>
        <LoginRegisterHeading>Sign In</LoginRegisterHeading>
        <form>
          {!googleIsLoading && <>
            <Input type='email' placeholder='Email' value={email} onChange={handleEmailChange} required />
            { loginError && <Error style={{ marginRight: "auto", marginLeft: "auto" }}>{errorMessage}</Error> }
            <Input type='password' placeholder='Password' value={password} onChange={handlePasswordChange} onKeyDown={handleEnterPress} required />
            <ButtonStretched onClick={handleLoginClick} style={{ marginBottom: "20px" }}>
              {loading &&
                <WhiteSpinnerSpan>
                  <CircularProgress style={{ width: "30px", height: "30px", color: "#fff" }} />
                </WhiteSpinnerSpan>
              }
              {!loading && <>Sign In</>}
            </ButtonStretched>
            <LoginSignUpText style={{ fontSize: "15px" }}>or</LoginSignUpText></>
          }
          <GoogleButton onClick={handleSignInGoogleClick}>
            <GoogleIconImg src={GoogleIcon} />
            {googleIsLoading ?
              <span style={{ width: "180px", textAlign: "center" }}>
                <CircularProgress style={{ width: "30px", height: "30px" }} />
              </span>
              :
              <>Continue with Google</>
            }
          </GoogleButton>
        </form>
        <LoginSignUpText>Don't have an account? <Span onClick={handleSignUpClick}>Sign up</Span></LoginSignUpText>
        <LoginSignUpText><Span onClick={handleForgotPasswordClick}>Forgotten password?</Span></LoginSignUpText>
      </Container>
      <Footer />
    </FooterContainer>
  );
};

export default Login;
