import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { CELEBRATION_ID, CONGRATS, EQUALS, IS_ACTIVE, SENDER_ID } from "../constants";

const getAllUserCongrats = async (userId: string): Promise<Congrats[] | null> => {
  try {
    const congratsSnapshot = await getDocs(query(
      collection(firestoreDb, CONGRATS), where(SENDER_ID, EQUALS, userId)
    ));
    if (congratsSnapshot.size > 0) {
      return congratsSnapshot?.docs?.map(congratsObj => congratsObj.data() as Congrats);
    };
    return null;
  } catch (e) {
    console.error("Error getting congrats by userId: ", e);
  }
}

const getAllActiveCongratsForCele = async (celebrationId: string): Promise<Congrats[] | null> => {
  try {
    const congratsSnapshot = await getDocs(query(
      collection(firestoreDb, CONGRATS),
      where(CELEBRATION_ID, EQUALS, celebrationId),
      where(IS_ACTIVE, EQUALS, true)
    ));
    if (congratsSnapshot.size > 0) {
      return congratsSnapshot?.docs?.map(congratsObj => congratsObj.data() as Congrats);
    };
    return null;
  } catch (e) {
    console.error("Error getting active congrats by celebrationId: ", e);
  }
}

const upsertCongrats = async (congrats: Congrats): Promise<void> => {
  try {
    const congratsSnapshot = await getDocs(query(
      collection(firestoreDb, CONGRATS),
      where(SENDER_ID, EQUALS, congrats?.sender?.id),
      where(CELEBRATION_ID, EQUALS, congrats?.celebration?.id)
    ));

    let congratsRef: any;
    if (congratsSnapshot.size === 0) {
      congratsRef = await addDoc(collection(firestoreDb, CONGRATS), { ...congrats });
      await updateDoc(congratsRef, { id: congratsRef.id });
    } else if (congratsSnapshot.size === 1) {
      const congratsList = congratsSnapshot.docs;
      congratsRef = doc(firestoreDb, CONGRATS, congratsList.at(0)?.id);
      await updateDoc(congratsRef, {
        isActive: congrats?.isActive,
        updatedAt: congrats?.updatedAt,
      });
    }
  } catch (e) {
    console.error("Error adding congrats: ", e);
  }
}

const CongratsService = {
  getAllUserCongrats,
  getAllActiveCongratsForCele,
  upsertCongrats
}

export default CongratsService;
