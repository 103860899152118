import styled from "@emotion/styled/macro";
import { Grid as MuiGrid } from "@mui/material";

const FooterStyled = styled.footer`
  background-color: rgba(236,238,233,1.00);
  padding-top: 30px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
`

const FooterText = styled.p`
  color: rgb(75 85 99);
  line-height: 18.5px;
  font-size: 16px;
  margin-bottom: 5px;
`

const FooterLink = styled.a`
  line-height: 18.5px;
  font-size: 16px;
`

const Grid = styled(MuiGrid)`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`

const Footer = () => {
  return (
    <FooterStyled className="footer-mobile">
      <Grid container direction="column" justifyContent="space-between" alignItems="center">
        <div style={{ marginBottom: "25px" }}>
          <FooterText>👋 Facing issues or want to share feedback?</FooterText>
          <span style={{ marginRight: "5px" }}>📬</span>
          <FooterLink type="email">jon@giftify.social</FooterLink>
        </div>
        <FooterText>© 2024 Giftify</FooterText>
      </Grid>
    </FooterStyled>
  )
};

export default Footer;
