import { FC, useEffect, useRef } from 'react';
import styled from "@emotion/styled/macro";
import { NavItem as BootNavItem } from 'reactstrap';
import tw from "twin.macro";
import { Location } from 'react-router-dom';
import { IconButton as MuiIconButton } from '@mui/material';

import { ABOUT_PATH, ACTIVE, PRODUCTS_PATH, LOGIN_PATH, GIFTS_N, CONNECTIONS_N, INVITE_LINK_N, PRODUCTS_N, EARN_POINTS_N } from '../../constants';
import { ProfileImgNotif, AccountsImg, NavLink, PrimaryLink, NavNotifButton, GrayLink, GrayLinkNoHover, ProfileImgArrow, MsgNotification } from './styled';
import { getTotalNotifsForAllRooms, goToEarnSetup, goToInbox } from '../../services/helpers';
import UserService from '../../services/userService';

const NavLinks = styled.div`
  ${tw`my-5`};
  a { ${tw`no-underline`} }
`

const NavItem = styled(BootNavItem)`
  ${NavLinks} {
    ${tw`flex flex-col md:flex-row items-center`}
  }
`

const IconButton = styled(MuiIconButton)`
  padding: 7px;
  color: #050505;
  margin-right: 8px;
  background-color: #E4E6EB;
  padding: 10px 12px;
  font-size: 21px;

  &:hover {
    background-color: #d8dadf;
  }
`

const ProfileUl = styled.ul`
  border-radius: 16px;
  width: 200px;
  list-style: none;
  background-color: rgba(255,255,255,1.00);
  box-sizing: border-box;
  outline: none;
  border: 1px solid #EBEBEB;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(30, 28, 36, 0.85);
  padding: 8px;
  position: absolute;
  top: 60px;
  right: 37px;
  z-index: 1;
  position: fixed;
`

const Item = styled.p`
  padding: 8px 0px 8px 8px;
  margin: 0px;
  position: relative;
  cursor: pointer;

  :hover {
    background-color: rgba(68, 73, 80, 0.15);
    border-radius: 16px;
  }
`

const AccountButton = styled.div`
  cursor: pointer;
`

const useOutsideClick = (ref: any, setDropdownCSS: any) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && event.target.id !== "img") {
        setDropdownCSS(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

interface NavRightSectionProps {
  currentUser: Account;
  profileImg: string;
  notifications: Notifications;
  requestsNotifCSS: boolean;
  giftsNotifCSS: boolean;
  giftsAcceptedCSS: boolean;
  congratsNotifCSS: boolean;
  addedNotifCSS: boolean;
  dropdownCSS: boolean;
  location: Location;
  totalNotifs: number;
  setDropdownCSS: (val: boolean) => void;
  setCelebrationModalOpen: (val: boolean) => void;
  handleAccountClick: () => void;
  handleGiftIconClick: () => void;
  handleProfileClick: () => void;
  handleConnectionsClick: () => void;
  handleGetInviteClick: () => void;
  handleLogoutClick: () => void;
  handleFindClick: () => void;
  handleRegisterClick: () => void;
  handleLoginClick: () => void;
};
const NavRightSection: FC<NavRightSectionProps> = ({
    currentUser,
    profileImg,
    notifications,
    requestsNotifCSS,
    giftsNotifCSS,
    giftsAcceptedCSS,
    congratsNotifCSS,
    addedNotifCSS,
    dropdownCSS,
    location,
    totalNotifs,
    setDropdownCSS,
    setCelebrationModalOpen,
    handleAccountClick,
    handleGiftIconClick,
    handleProfileClick,
    handleConnectionsClick,
    handleGetInviteClick,
    handleLogoutClick,
    handleFindClick,
    handleRegisterClick,
    handleLoginClick
  }) => {

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setDropdownCSS);

  return (
    <NavItem>
      <NavLinks>
        {currentUser ? <>
          {/* Post celebration */}
          <IconButton onClick={() => setCelebrationModalOpen(true)}>
            <i className="fa-regular fa-square-plus" />
          </IconButton>
          {/* Inbox */}
          <div style={{ position: "relative" }}>
            <IconButton onClick={() => goToInbox()}>
              <i className="fa-solid fa-comment" />
            </IconButton>
            {(getTotalNotifsForAllRooms(notifications?.totalNotifsForRooms) > 0) &&
              <MsgNotification onClick={() => goToInbox()} />
            }
          </div>
          {/* Account */}
          <AccountButton>
            {profileImg &&
              <div>
                <AccountsImg src={profileImg} style={{ marginRight: "0px" }} onClick={handleAccountClick} />
                {totalNotifs > 0 &&
                  <ProfileImgNotif onClick={handleAccountClick}>
                    {totalNotifs > 9 ? <>9+</> : totalNotifs}
                  </ProfileImgNotif>
                }
                <ProfileImgArrow onClick={handleAccountClick}>
                  <i className="fa-solid fa-chevron-down" style={{ position: "relative", bottom: "3px" }} />
                </ProfileImgArrow>
              </div>
            }
          </AccountButton>
          {/* Dropdown */}
          <ProfileUl className={`profile-dropdown ${dropdownCSS ? ACTIVE : null}`} ref={wrapperRef}>
            <Item onClick={handleProfileClick}>
              <i className="fa-solid fa-user" style={{ fontSize: "15px", margin: "0px 11px 0px 3px" }} />
              {UserService.getNameOrUsername(currentUser?.name, currentUser?.username)}
              {notifications?.congratsReceived && notifications?.congratsReceived?.length > 0 && <>
                <NavNotifButton className={`request-notification ${congratsNotifCSS ? ACTIVE : null }`}>
                  {notifications?.congratsReceived?.length > 9 ?
                    <>9+</>
                    :
                    notifications?.congratsReceived?.length
                  }
                </NavNotifButton></>
              }
            </Item>
            <Item onClick={handleGiftIconClick}>
              <i className="fa-solid fa-gift" style={{ fontSize: "17px", margin: "0px 10px 0px 2px" }} />
              {GIFTS_N}
              {!isNaN(notifications?.giftsReceived + notifications?.giftsAccepted) && <>
                <NavNotifButton className={`request-notification ${(giftsNotifCSS || giftsAcceptedCSS) ? ACTIVE : null }`}>
                  {notifications?.giftsReceived + notifications?.giftsAccepted > 9 ?
                    <>9+</>
                    :
                    notifications?.giftsReceived + notifications?.giftsAccepted
                  }
                </NavNotifButton>
              </>}
            </Item>
            <Item onClick={goToEarnSetup}>
              <i className="fa-solid fa-award" style={{ fontSize: "18px", margin: "0px 12px 0px 4px", position: "relative", top: "1px" }} />
              {EARN_POINTS_N}
            </Item>
            <Item onClick={handleFindClick}>
              <i className="fa-solid fa-store" style={{ fontSize: "17px", margin: "0px 9px 0px 2px" }} />
              {PRODUCTS_N}
            </Item>
            <Item onClick={handleConnectionsClick}>
              <i className="fa-solid fa-user-group" style={{ fontSize: "15px", margin: "0px 9px 0px 2px" }} />
              {CONNECTIONS_N}
              {!isNaN(notifications?.connectionsAdded + notifications?.connectionRequests) && <>
                <NavNotifButton className={`request-notification ${(addedNotifCSS || requestsNotifCSS) ? ACTIVE : null }`}>
                  {notifications?.connectionsAdded + notifications?.connectionRequests > 9 ?
                    <>9+</>
                    :
                    notifications?.connectionsAdded + notifications?.connectionRequests
                  }
                </NavNotifButton>
              </>}
            </Item>
            <Item onClick={handleGetInviteClick}>
              <i className="fa-solid fa-share-nodes" style={{ fontSize: "17px", margin: "0px 13px 0px 2px" }} />
              {INVITE_LINK_N}
            </Item>
            <Item onClick={handleLogoutClick} style={{ marginLeft: "2px", marginBottom: "7px" }}>
              <i className="fa-solid fa-right-from-bracket" style={{ fontSize: "16px", margin: "0px 11px 0px 2px" }} />
              Log out
            </Item>
            <GrayLink onClick={() => window.location.assign(ABOUT_PATH)}>About</GrayLink>
            <GrayLinkNoHover style={{ marginLeft: "3px", marginRight: "3px" }}>·</GrayLinkNoHover>
            <GrayLinkNoHover>Giftify © 2024</GrayLinkNoHover>
          </ProfileUl></>
          :
          <>
            { location.pathname !== PRODUCTS_PATH && <PrimaryLink onClick={handleFindClick}>Find a gift</PrimaryLink> }
            { location.pathname === LOGIN_PATH && <NavLink onClick={handleRegisterClick}>Sign up</NavLink> }
            { location.pathname !== LOGIN_PATH && <NavLink onClick={handleLoginClick}>Login</NavLink> }
          </>
        }
      </NavLinks>
    </NavItem>
  );
}

export default NavRightSection;
