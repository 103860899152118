import { doc, updateDoc, getDoc, addDoc, collection, where, getDocs, query, orderBy, deleteDoc } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { AUTHOR_ID, CELEBRATIONS, CELEBRATION_IMAGES_FOLDER, CREATED_AT, DESC, EMPTY_STRING, EQUALS } from "../constants";
import StorageService from "./storageService";

const getCelebrationById = async (celebrationId: string) : Promise<Celebration> => {
  try {
    const celebrationRef = doc(firestoreDb, CELEBRATIONS, celebrationId);
    const celebration = await getDoc(celebrationRef);
    if (celebration.exists()) {
      return celebration.data() as Celebration;
    }
    return null;
  } catch (e) {
    console.error("Error getting celebration by id: ", e);
  }
}

const getUserCelebrations = async (userId: string) : Promise<Celebration[]> => {
  try {
    const celebrationsRef = collection(firestoreDb, CELEBRATIONS);
    const celebrationsSnapshot = await getDocs(query(celebrationsRef, where(AUTHOR_ID, EQUALS, userId), orderBy(CREATED_AT, DESC)));
    if (celebrationsSnapshot.size > 0) {
      return celebrationsSnapshot?.docs?.map(celebration => celebration.data() as Celebration);
    }
    return null;
  } catch (e) {
    console.error("Error getting all celebrations for given user: ", e);
  }
}

const addCelebration = async (celebration: Celebration) : Promise<string> => {
  try {
    const celebrationRef = await addDoc(collection(firestoreDb, CELEBRATIONS), celebration);
    await updateDoc(celebrationRef, { id: celebrationRef.id });
    return celebrationRef.id;
  } catch (e) {
    console.error("Error adding celebration: ", e);
  }
}

const updateCelebration = async (celebrationObj: Celebration) : Promise<void> => {
  try {
    const celebrationRef = doc(firestoreDb, CELEBRATIONS, celebrationObj.id);
    const celebrationSnapshot = await getDoc(celebrationRef);
    if (celebrationSnapshot.exists()) {
      await updateDoc(celebrationRef, { ...celebrationObj });
    }
  } catch (e) {
    console.error("Error updating celebration: ", e);
  }
}

const deleteCelebrationAndImage = async (celebration: Celebration): Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, CELEBRATIONS, celebration?.id));
    if (celebration?.images?.length > 0 && celebration?.images?.at(0) !== EMPTY_STRING) {
      await StorageService.deleteImage(StorageService.getFileNameFromURL(celebration?.images?.at(0)), CELEBRATION_IMAGES_FOLDER);
    }
  } catch (e) {
    console.error("Error deleting celebration by id: ", e);
  }
}

const CelebrationService = {
  getCelebrationById,
  addCelebration,
  getUserCelebrations,
  updateCelebration,
  deleteCelebrationAndImage
}

export default CelebrationService;
