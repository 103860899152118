import { FC, useContext } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';

import { Container } from '../children/styled';
import { LOGIN_PATH, EMPTY_STRING } from '../../constants';
import { auth } from '../../firebase';
import { AuthContext } from '../../providers/AuthProvider';
import RegularSetup from '../children/RegularSetup';

interface SetupProps {
  stepTwo?: boolean;
};
const Setup: FC<SetupProps> = ({ stepTwo }) => {
  const { currentUser, signOut } = useContext(AuthContext);

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
    });
  }

  setUp();

  return (
    <Container style={{ paddingBottom: "100px", marginTop: "110px", paddingTop: "0px" }}>
      <RegularSetup stepTwo={stepTwo} />
    </Container>
  );
};

export default Setup;
