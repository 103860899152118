import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { UPDATED_AT, PRODUCT_ID, REACTIONS, USER_ID, DESC, EQUALS } from "../constants";

const upsertReaction = async (reaction: Reaction) : Promise<void> => {
  try {
    const q = query(
      collection(firestoreDb, REACTIONS),
      where(USER_ID, EQUALS, reaction?.user?.id),
      where(PRODUCT_ID, EQUALS, reaction?.product?.id)
    );
    const reactionsSnapshot = await getDocs(q);
    const reactions = reactionsSnapshot.docs;
    let reactionsRef: any;
    if (reactions?.length === 0) {
      reactionsRef = await addDoc(collection(firestoreDb, REACTIONS), { ...reaction });
      await updateDoc(reactionsRef, { id: reactionsRef.id });
    } else if (reactions?.length === 1) {
      reactionsRef = doc(firestoreDb, REACTIONS, reactions.at(0)?.id);
      await updateDoc(reactionsRef, {
        status: reaction?.status,
        updatedAt: reaction?.updatedAt,
      });
    }
  } catch (e) {
    console.error("Error adding or updating reaction: ", e);
  }
}

const getReactionsByUserId = async (userId: string) : Promise<Reaction[] | null> => {
  try {
    const q = query(collection(firestoreDb, REACTIONS), where(USER_ID, EQUALS, userId));
    const reactionsSnapshot = await getDocs(q);
    if (reactionsSnapshot.size > 0) {
      return reactionsSnapshot?.docs?.map(reaction => reaction.data() as Reaction);
    }
    return null;
  } catch (e) {
    console.error("Error getting reaction by userId: ", e);
  }
}

const getReactionsByProductId = async (productId: string) : Promise<Reaction[] | null> => {
  try {
    const q = query(collection(firestoreDb, REACTIONS), where(PRODUCT_ID, EQUALS, productId));
    const reactionsSnapshot = await getDocs(q);
    const reactions = reactionsSnapshot.docs;
    if (reactions?.length > 0) {
      return reactions?.map(reaction => reaction.data() as Reaction);
    }
    return null;
  } catch (e) {
    console.error("Error getting reaction by productId: ", e);
  }
}

const getAllReactions = async () : Promise<Reaction[]> => {
  try {
    const q = query(collection(firestoreDb, REACTIONS), orderBy(UPDATED_AT, DESC));
    const reactionsSnapshot = await getDocs(q);
    return reactionsSnapshot?.docs?.map(doc => doc.data() as Reaction);
  } catch (e) {
    console.error("Error getting all reactions: ", e);
  }
}

const deleteReaction = async (reactionId: string) : Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, REACTIONS, reactionId));
  } catch (e) {
    console.error("Error deleting reaction by id: ", e);
  }
}

const ReactionService = {
  upsertReaction,
  getReactionsByUserId,
  getReactionsByProductId,
  getAllReactions,
  deleteReaction
}

export default ReactionService;
