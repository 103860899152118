import { FC, SyntheticEvent } from 'react';
import { Snackbar as MuiSnackbar, Alert as MuiAlert } from '@mui/material';
import styled from '@emotion/styled';

import { SUCCESS } from '../../constants';

const Snackbar = styled(MuiSnackbar)`
  margin-bottom: 10px;
`

interface ErrorProps {
  alertType: string
  alertIsOpen: boolean
  alertMessage: string
  setAlertIsOpen: (val: boolean) => void
}

const Alert: FC<ErrorProps> = ({ alertType, alertIsOpen, alertMessage, setAlertIsOpen }) => {

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertIsOpen(false);
  };

  return (
    <Snackbar open={alertIsOpen} autoHideDuration={4000} onClose={handleClose}>
      {alertType === SUCCESS ?
        <MuiAlert severity="success">{alertMessage}</MuiAlert>
        :
        <MuiAlert severity="error">{alertMessage}</MuiAlert>
      }
    </Snackbar>
  );
}

export default Alert;
