import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { ButtonStretched, Chip as baseChip, ChipImg, Container, FooterContainer, Heading, Text as baseText } from '../children/styled';
import { GBP, REGISTER_PATH } from '../../constants';
import { useParams } from 'react-router-dom';
import InviteService from '../../services/inviteService';
import UserService from '../../services/userService';
import StorageService from '../../services/storageService';
import Footer from '../children/Footer';

const Text = styled(baseText)`
  padding: 5px 0px;
`

const TextDiv = styled.div`
  color: var(--fir-color-grey);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 2px;
  padding: 5px 0px;
`

const Code = styled.p`
  font-size: 38px;
  text-align: center;
  font-family: sans-serif;
  margin: 20px 0px;
  letter-spacing: 5px;
`

const Background = styled.div`
  padding: 15px 10px;
  background-color: #e8fcf4;
  border-radius: 10px;
  margin: 20px 0px 5px 0px;
`

const Chip = styled(baseChip)`
  color: var(--fir-color-grey);
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 2px;
  border: 0px;
  padding-right: 0px;
  cursor: pointer;
`

const getNameOrNothing = (name: string) : string => {
  if (name && name.trim() !== "") {
    return ", " + UserService.capitalizeFullname(name.trim());
  }
  return "";
}

const InvitePage: FC = () => {
  const [invite, setInvite] = useState<Invite | null>();
  const { shareId } = useParams();

  const handleSignUpClick = async () => {
    window.location.assign(REGISTER_PATH);
  }

  const handleInviterClick = async () => {
    window.location.assign(`/${invite?.inviter?.username}`);
  }

  useEffect(() => {
    const fetchAccount = async () => {
      const inviteObj = await InviteService.getInviteByShareId(shareId);
      setInvite(inviteObj)
    };

    fetchAccount();
  }, []);

  return (
    <FooterContainer>
      <Container>
        <Heading style={{ margin: "5px 0px", textAlign: "center" }}>Welcome{getNameOrNothing(invite?.invitee?.name)}! <span style={{ marginRight: "5px" }} />🥳</Heading>
        <Background>
          <TextDiv>
            <Chip onClick={handleInviterClick} style={{ background: "transparent" }}><ChipImg style={{ marginRight: "7px" }} src={StorageService.getImageOrDefault([invite?.inviter?.image])} />
              <span style={{ fontWeight: "bold" }}>{UserService.getNameOrUsername(invite?.inviter?.name, invite?.inviter?.username)}</span>
            </Chip> is excited for you to join Giftify.
          </TextDiv>
          <Text>Giftify helps you easily send gifts to your family and friends without asking for their addresses.</Text>
        </Background>
        <Text style={{ fontWeight: "bold", padding: "10px" }}>
          Use the code below to get {invite?.inviteePromotion?.discount?.currencyCode === GBP ? "£" : invite?.inviteePromotion?.discount?.currencyCode.toUpperCase()}
          {invite?.inviteePromotion?.discount?.amount} 💸 off the first gift that you send.
        </Text>
        <Code>{invite?.inviteePromotion?.code}</Code>
        <ButtonStretched onClick={handleSignUpClick} style={{ marginTop: "30px" }}>Use discount</ButtonStretched>
      </Container>
      <Footer />
    </FooterContainer>
  );
};

export default InvitePage;
