import { addDoc, collection, doc, query, collectionGroup, where, getDocs, onSnapshot, getDoc } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { EMPTY_STRING, EQUALS, ID, STRIPE_CUSTOMERS, STRIPE_PAYMENTS } from "../constants";
import { sleep } from "./helpers";

const getPaymentById = async (userId: string, paymentId: string) : Promise<any> => {
  try {
    const stripePaymentRef = doc(firestoreDb, STRIPE_CUSTOMERS, userId, STRIPE_PAYMENTS, paymentId);
    let waitForLatestUpdate = true;
    const unsubscribeCallback = onSnapshot(stripePaymentRef, { includeMetadataChanges: true }, async (paymentObj) => {
      while (waitForLatestUpdate) {
        if (paymentObj?.data().customer && paymentObj?.data().customer !== EMPTY_STRING) {
          waitForLatestUpdate = false;
        };
        await sleep(25);
      };
    });
    while (waitForLatestUpdate) {
      await sleep(25);
    };
    const stripePayment = await getDoc(stripePaymentRef);
    unsubscribeCallback();
    if (stripePayment.exists()) {
      return stripePayment.data() as any;
    };
    return null;
  } catch (e) {
    console.error("Error getting stripePayment by id: ", e);
  };
};

const getPaymentIdByIntentId = async (intentId: string) : Promise<string> => {
  try {
    const paymentsQuery = query(collectionGroup(firestoreDb, STRIPE_PAYMENTS), where(ID, EQUALS, intentId));
    const paymentsSnapshot = await getDocs(paymentsQuery);
    if (paymentsSnapshot.size === 1) {
      return paymentsSnapshot.docs.at(0).id;
    };
    return null;
  } catch (e) {
    console.error("Error getting stripePayment by stripeId: ", e);
  }
}

const createPayment = async (userId: string, payment: any) : Promise<string> => {
  try {
    const stripePaymentRef = await addDoc(collection(firestoreDb, STRIPE_CUSTOMERS, userId, STRIPE_PAYMENTS), payment);
    return stripePaymentRef.id;
  } catch (e) {
    console.error("Error creating stripePayment: ", e);
  }
}

const StripeService = {
  createPayment,
  getPaymentById,
  getPaymentIdByIntentId
}

export default StripeService;
