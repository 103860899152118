import { FC } from 'react';
import { Chip, IconButton as MuiIconButton, Stack } from '@mui/material';
import { Close, FilterList } from '@mui/icons-material';
import styled from '@emotion/styled';

import { TAGS_LIST } from '../../constants';

const TagsContainer = styled(Stack)`
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 20px;
`

const IconButton = styled(MuiIconButton)`
  background-color: #fff;
  padding: 5px;
  border: 1px solid #bdbdbd;
  margin-bottom: 20px !important;
`

interface TagsModalProps {
  tagsOnArray: boolean[];
  setShowTagsModal: (val: boolean) => void;
  handleAddOrDeleteTag: (index: number, adding: boolean) => void;
};
const TagsStrip: FC<TagsModalProps> = ({ tagsOnArray, setShowTagsModal, handleAddOrDeleteTag }) => {

  return (
    <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
      <TagsContainer direction="row" spacing={1}>
        {TAGS_LIST.map((tag: string, index: number) => (
          <Chip key={tag + index} label={tag} onClick={() => handleAddOrDeleteTag(index, true)} onDelete={() => handleAddOrDeleteTag(index, false)}
            deleteIcon={tagsOnArray?.at(index) ? <Close /> : <></>} variant={tagsOnArray?.at(index) ? "filled" : "outlined"} />
        ))}
      </TagsContainer>
      <IconButton disableFocusRipple onClick={() => setShowTagsModal(true)}
        style={{ backgroundColor: `${tagsOnArray?.find(item => item === true)  ? "rgba(0, 0, 0, 0.08)" : "#fff"}` }}>
        <FilterList style={{ fontSize: "20px" }} />
      </IconButton>
    </Stack>
  );
};

export default TagsStrip;
