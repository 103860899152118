import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import { CONGRATS_RECEIVED, EMPTY_STRING, NOTIFICATIONS } from "../constants";
import { firestoreDb } from "../firebase";

/**
 * @param userId
 * @param type: connectionRequests | giftsReceived | connectionsAdded | giftsAccepted
 */
const removeNotification = async (userId: string, type: string) : Promise<void> => {
  try {
    const notifRef = doc(firestoreDb, NOTIFICATIONS, userId);
    await setDoc(
      notifRef,
      { [type]: 0 },
      { merge: true }
    )
  } catch (e) {
    console.error("Error removing notification: ", e);
  }
}

const removeMsgsNotifForRoom = async (userId: string, roomId: string) : Promise<void> => {
  try {
    // Todo: put below in transaction
    const notifRef = doc(firestoreDb, NOTIFICATIONS, userId);
    const notifSnapshot = await getDoc(notifRef);
    if (notifSnapshot.exists()) {
      const nofitDoc: Notifications = notifSnapshot.data() as Notifications;
      await updateDoc(notifRef, {
        totalNotifsForRooms: { ...nofitDoc?.totalNotifsForRooms, [roomId]: 0 }
      });
    };
  } catch (e) {
    console.error("Error removing notification for room: ", e);
  }
};

const getNotifications = async (userId: string) : Promise<Notifications> => {
  try {
    const notifRef = doc(firestoreDb, NOTIFICATIONS, userId);
    const notif = await getDoc(notifRef);
    if (notif.exists()) {
      return notif.data() as Notifications;
    } else {
      return null;
    }
  } catch (e) {
    console.error("Error getting notifications by userId: ", e);
  }
}

const removeCongratsNotification = async (accountId: string, celebrationId: string) : Promise<void> => {
  if (!accountId || !celebrationId || accountId === EMPTY_STRING || celebrationId === EMPTY_STRING) {
    return;
  };
  try {
    const notifRef = doc(firestoreDb, NOTIFICATIONS, accountId);
    const docSnap = await getDoc(notifRef);
    if (docSnap.exists()) {
      const notifList: string[] = docSnap.data()[CONGRATS_RECEIVED];
      if (notifList && notifList?.length > 0) {
        await setDoc(
          notifRef,
          { [CONGRATS_RECEIVED]: notifList.filter(celeId => celeId !== celebrationId) },
          { merge: true }
        );
      };
    };
  } catch (e) {
    console.error("Error removing congrats notification: ", e);
  }
}

const NotificationService = {
  removeNotification,
  removeCongratsNotification,
  removeMsgsNotifForRoom,
  getNotifications
}

export default NotificationService;
