import { FC, useContext, useEffect, useState } from 'react';
import { Card as RbCard } from 'react-bootstrap';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';

import { AuthContext } from '../../providers/AuthProvider';
import { EMPTY_STRING, GBP, LIKED, LOGIN_PATH, SKIPPED } from '../../constants';
import { Body, Button, Card, CardText, IconImage, Img, ImgContainter, UnderlineDiv } from './styled';
import { authenticateUser, saveReactionProductUser, saveWishlistProductUser, trimPrice, trimText } from '../../services/helpers';
import WishlistSolid from "../../images/wishlist-solid.png";
import WishlistRegular from "../../images/wishlist-regular.png";
import HeartSolid from "../../images/heart-solid.png";
import HeartRegular from "../../images/heart-regular.png";

const Reactions = styled(Grid)`
  margin-bottom: 20px;
`

interface ProductProps {
  product: Product;
  userReactions?: Reaction[];
  userWishlistItems?: WishlistItem[];
  setGiftingForm?: (val: boolean) => void;
  setSelectedProduct?: (product: Product) => void;
  setProductSuggestionOpen?: (val: boolean) => void;
}
const Product: FC<ProductProps> = ({ product, userReactions, userWishlistItems, setGiftingForm, setSelectedProduct, setProductSuggestionOpen }) => {
  const [currency, setCurrency] = useState<string>(EMPTY_STRING);
  const [userReaction, setUserReaction] = useState<string>(EMPTY_STRING);
  const [wishlistStatus, setWishlistStatus] = useState<boolean>(false);
  const [wishlistItem, setWishlistItem] = useState<WishlistItem>();

  const { currentUser, signOut } = useContext(AuthContext);

  const handleGiftClick = async () => {
    const openGiftingForm: boolean = await authenticateUser(currentUser, signOut);
    if (openGiftingForm) {
      setProductSuggestionOpen(false);
      setSelectedProduct(product);
      setGiftingForm(true);
    };
  };

  const handleLikeClick = async () => {
    const openGiftingForm: boolean = await authenticateUser(currentUser, signOut);
    if (openGiftingForm) {
      let newUserReaction: string;
      if (userReaction === LIKED) {
        newUserReaction = SKIPPED;
      } else {
        newUserReaction = LIKED;
      };
      setUserReaction(newUserReaction);
      await saveReactionProductUser(currentUser, newUserReaction, product);
    };
  };

  const handleWishlistClick = async () => {
    const openGiftingForm: boolean = await authenticateUser(currentUser, signOut);
    if (openGiftingForm) {
      let removed: boolean = wishlistStatus === true ? true : false;
      setWishlistStatus(!wishlistStatus);
      await saveWishlistProductUser(currentUser, product, removed, wishlistItem);
    };
  };

  useEffect(() => {
    if (currentUser) {
      if (userReactions) {
        userReactions?.forEach(reaction => {
          if (reaction?.status === LIKED && reaction?.product?.id === product?.id) {
            setUserReaction(LIKED);
          }
        });
      }
      if (userWishlistItems) {
        userWishlistItems?.forEach(wishlistItemObj => {
          if (wishlistItemObj?.product?.id === product?.id) {
            setWishlistStatus(true);
            setWishlistItem(wishlistItemObj);
          }
        });
      }
    }
    setCurrency(product?.price?.currencyCode?.toLowerCase());
  }, [product]);

  return (
    <Card>
      <ImgContainter>
        <Img variant="top" src={product?.images[0]} onClick={() => window.open(product?.url)} />
      </ImgContainter>
      <Body>
        <UnderlineDiv>
          <RbCard.Title className="mobile-title-height" onClick={() => window.open(product?.url)}>{product?.title}</RbCard.Title>
          <CardText>{trimText(product?.description, 45)}</CardText>
          <CardText onClick={() => window.open(product?.shop?.url)}>{product?.shop?.name}</CardText>
        </UnderlineDiv>
        <CardText style={{ 'fontWeight': 900 }}>
          {currency === GBP ? "£" : currency?.toUpperCase()}
          {trimPrice(product?.price?.amount)}
        </CardText>
        <Reactions container direction="row" justifyContent="center" alignItems="center" spacing={0.5} columns={{ xs: 12 }}>
          <IconImage onClick={handleWishlistClick} src={wishlistStatus ? WishlistSolid : WishlistRegular} />
          <IconImage onClick={handleLikeClick} src={userReaction === LIKED ? HeartSolid : HeartRegular}
            style={{ marginLeft: "20px", top: `${userReaction === LIKED ? "1px" : "0px"}`, position: `${userReaction === LIKED ? "relative" : "unset"}` }}  />
        </Reactions>
        <Button onClick={handleGiftClick} style={{ marginBottom: "5px" }}>GIFT</Button>
      </Body>
    </Card>
  );
};

export default Product;
