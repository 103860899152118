import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { ButtonStretched, Container, Error, Heading as BaseHeading, Label, Text as baseText } from '../children/styled';
import { EMPTY_STRING, LOGIN_PATH } from '../../constants';
import { AuthContext } from '../../providers/AuthProvider';
import ProductSuggestionService from '../../services/productSuggestionService';

const Text = styled(baseText)`
  padding: 5px 0px;
`

const Heading = styled(BaseHeading)`
    margin: 15px auto;
    width: 100%;
    max-width: 330px;
`

interface WishlistFormProps {
	link: string;
}
const WishlistForm: FC<WishlistFormProps> = ({ link }) => {
  const [productLink, setProductLink] = useState<string>(EMPTY_STRING);
  const [linkDetails, setLinkDetails] = useState<string>(EMPTY_STRING);
  const [error, setError] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);

  const { currentUser, signOut } = useContext(AuthContext);

  const handleProductLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProductLink(event.target.value);
  }

  const handleLinkDetailsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setLinkDetails(event.target.value);
	}

  const handleSubmitClick = async () => {
    if (!currentUser || currentUser?.username?.trim() === "") {
      signOut();
      window.location.assign(LOGIN_PATH);
      return;
    } else if (productLink.trim() === EMPTY_STRING) {
      setError(true);
      return;
    }
    await ProductSuggestionService.addProductSuggestion({
      userId: currentUser?.id,
      productUrl: productLink,
      productDetails: linkDetails
    });
    setIsSent(true);
  }

  const handleAnotherClick = () => {
		setProductLink(EMPTY_STRING);
    setLinkDetails(EMPTY_STRING);
    setIsSent(false);
	}

  useEffect(() => {
    if (link !== EMPTY_STRING) {
      setProductLink(link);
    }
  }, [link]);

  useEffect(() => {
		setError(false);
	}, [productLink]);

  return (
    <Container style={{ marginTop: "20px" }}>
      {isSent ?
        <>
          <Heading style={{ margin: "0px", textAlign: "center", maxWidth: "333px" }}>Got it, thanks! <span style={{ marginRight: "5px" }} /> ✅</Heading>
          <Text style={{ margin: "15px auto 15px auto", width: "100%", maxWidth: "300px" }}>We'll review the product and add it to the catalogue soon. <span style={{ marginRight: "5px" }} /> 🔜</Text>
          <ButtonStretched onClick={handleAnotherClick} style={{ marginTop: "30px" }}>Add another</ButtonStretched>
        </>
        :
        <>
          <Heading style={{ margin: "0px", textAlign: "center" }}>Add to your wishlist <span style={{ marginRight: "5px" }} /> 📝🎁</Heading>
          <Text style={{ margin: "15px auto 15px auto", width: "100%", maxWidth: "300px" }}>Tell us more about the gift that you want.</Text>
          <Label style={{ marginBottom: "10px" }} className="center">Link to product</Label>
          <input value={productLink} onChange={handleProductLinkChange} className='input-textarea center' type='text' placeholder="https://" />
          { error && (productLink.trim() === EMPTY_STRING) && <Error style={{ textAlign: "center", padding: "0px", maxWidth: "none" }}>Please add a link url.</Error> }
          <Label style={{ marginBottom: "10px", marginTop: "30px" }} className="center">Details (optional)</Label>
          <textarea value={linkDetails} onChange={handleLinkDetailsChange} className='input-textarea center' rows={3} placeholder='Add details about the variant, color, size, etc.' />
          <ButtonStretched onClick={handleSubmitClick} style={{ marginTop: "30px" }}>Submit</ButtonStretched>
        </>
      }
    </Container>
  );
};

export default WishlistForm;
