import { addDoc, collection, updateDoc } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { PRODUCT_RECOMMENDATIONS } from "../constants";

const addProductSuggestion = async (suggestionObj: ProductSuggestion): Promise<void> => {
  try {
    const productSuggestionRef = await addDoc(collection(firestoreDb, PRODUCT_RECOMMENDATIONS), suggestionObj);
    await updateDoc(productSuggestionRef, { id: productSuggestionRef.id });
  } catch (e) {
    console.error("Error adding productSuggestion: ", e);
  }
}

const ProductSuggestionService = {
  addProductSuggestion
}

export default ProductSuggestionService;
