export const CURRENT_USER = "currentUser";
export const TO_GIFT_ID = "toGiftId";
export const DEFAULT_PROFILE_IMG = "https://wallpapercave.com/wp/wp9566480.png"
export const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
export const MAX_FILE_SIZE_KB = 512;
export const MAX_COMPRESSED_FILE_SIZE_KB = 1024;
export const EMPTY_STRING = "";
export const ENTER = "Enter";
export const WISHLIST = "wishlist";
export const SLASH = "/";
export const BLOCK = "block";
export const JON_ID = "tbhB0mbjC5T8bzLD6IW5mxEna172";
export const PRODUCT = "product";
export const LIKE = "like";
export const EMPTY_ARRAY: string[] = [];
export const INBOX = "inbox";
export const T = "t";
export const SHARE_INFO_FREQUENCY = 21;

/**
 * Collection names
 */
export const PRODUCTS = "products";
export const SHOPS = "shops";
export const OFFERS = "offers";
export const ACCOUNTS = "accounts";
export const CHECKOUTS = "checkouts";
export const CHECKOUT_PAYMENT_ID = "checkout.paymentId";
export const STRIPE_CUSTOMERS = "stripe_customers";
export const STRIPE_PAYMENT_METHODS = "stripe_payment_methods";
export const STRIPE_PAYMENTS = "stripe_payments";
export const NOTIFICATIONS = "notifications";
export const CONNECTIONS = "connections";
export const INVITES = "invites";
export const REACTIONS = "reactions";
export const PRODUCT_RECOMMENDATIONS = "product_recommendations";
export const CELEBRATIONS = "celebrations";
export const ACCOUNT_SETTINGS = "account_settings";
export const BADGES = "badges";
export const CONGRATS = "congrats";
export const ROOMS = "rooms";
export const MESSAGES = "messages";

/**
 * Firestore
 */
export const DOMAIN = "domain";
export const USERNAME = "username"
export const MAX_IMAGES_NUMBER = 6;
export const ANONYMOUS = "anonymous";
export const NOT_EQUALS = "!=";
export const EQUALS = "==";
export const NOT_IN = "not-in";
export const GIFTER_USERNAME = "gifter.username";
export const UPDATED_AT = "updatedAt";
export const CREATED_AT = "createdAt";
export const DESC = "desc";
export const ASC = "asc";
export const AUTHOR_ID = "author.id";
export const ACCOUNT_ID = "accountId";
export const PRIVACY_SCOPES_LIKES = "privacyScopes.likes";
export const PRIVACY_SCOPES_WISHLIST = "privacyScopes.wishlist";
export const GIFTEE_ID = "giftee.id";
export const GIFTER_ID = "gifter.id";
export const ACCOUNT_DOT_ID = "account.id";
export const TYPE = "type";
export const ORG = "org";
export const SENDER_ID = "sender.id";
export const CELEBRATION_ID = "celebration.id";
export const STORAGE_OBJ_NOT_FOUND = "storage/object-not-found";
export const DOES_NOT_EXIST = "does not exist";
export const IS_ACTIVE = "isActive";
export const TAGS = 'tags'
export const ARRAY_CONTAINS_ANY = 'array-contains-any';
export const ARRAY_CONTAINS = 'array-contains';
export const MEMBER_IDS = 'memberIds';
export const MEMBER_OBJS = 'membersObjs';
export const LAST_MESSAGE = "lastMessage";
export const LAST_MESSAGE_TIMESTAMP = "lastMessageTimestamp";

/**
 * Storage
 */
export const PROFILE_IMAGES_FOLDER = "profiles/images/";
export const CELEBRATION_IMAGES_FOLDER = "celebrations/images/";

/**
 * CSS
 */
export const ACTIVE = "active";
export const NONE = "none";
export const MD_DOWN = "md-down";
export const RESIZE = "resize";

/**
 * Paths
 */
export const HOME_PATH = "/"
export const OFFER_PATH = "/offer"
export const RECEIVED_PATH = "/received"
export const SENT_PATH = "/sent"
export const CONNECTIONS_PATH = "/connections"
export const REQUESTS_PATH = "/requests"
export const PRODUCTS_PATH = "/products"
export const LOGIN_PATH = "/login"
export const REGISTER_PATH = "/register"
export const INVITE_PATH = "/invite"
export const CREATE_PATH = "/create"
export const PROFILE_PATH = "/profile"
export const LOGIN_RECOVER_PATH = "/login/recover"
export const ABOUT_PATH = "/about"
export const PAYMENT_PATH = "/payment"
export const SETTINGS = "/settings"
export const CUSTOM_GIFTS_VERIFICATION_PATH = "/custom-gifts-verification"
export const EARN_PATH = "/earn"
export const INBOX_PATH = "/inbox"

/**
 * Statuses
 */
export const ACCEPTED = "accepted"
export const DECLINED = "declined"
export const PENDING = "pending";
export const COMPLETED = "completed";
export const CANCELED = "canceled"
export const NEEDS_VERIFICATION = "needsVerification";
export const ERROR = "error"
export const SUCCESS = "success"

/**
 * Connections
 */
export const CONTACTS = "contacts";
export const EVERYONE = "everyone";
export const REQUESTER_ID = "requester.id";
export const RECEIVER_ID = "receiver.id";
export const CONNECTED = "connected";
export const REMOVED = "removed";
export const STATUS = "status";
export const REQUESTER_USERNAME = "requester.username";
export const GIFTEE = "giftee";
export const GIFTER = "gifter";

/**
 * Notification Types
 */
export const CONNECTION_REQUESTS = "connectionRequests";
export const GIFTS_RECEIVED = "giftsReceived";
export const CONNECTIONS_ADDED = "connectionsAdded";
export const GIFTS_ACCEPTED = "giftsAccepted";
export const CONGRATS_RECEIVED = "congratsReceived";

/**
 * Reactions
 */
export const USER_ID = "user.id";
export const PRODUCT_ID = "product.id";
export const LIKED = "liked";
export const SKIPPED = "skipped";
export const LIKES = "likes";
export const SKIP = "Skip";
export const LIKE_CAP = "Like";

/**
 * Base URLs
 */
const { REACT_APP_ENV } = process.env;
export const BASE_URL = REACT_APP_ENV === "PROD" ? "https://giftify.social" : "http://localhost:3000";

/**
 * Stripe
 */
export const GBP = 'gbp';
export const STRIPE_RETURN_URL = REACT_APP_ENV === "PROD" ? "https://giftify.social/payment" : "http://localhost:3000/payment";
export const SUCCEEDED = 'succeeded';
export const PROCESSING = 'processing';
export const REQUIRES_PAYMENT_METHOD = 'requires_payment_method';
export const ID = 'id';

/**
 * Invites
 */
export const ALPHA_NUMERIC_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
export const INVITE_ID_LENGTH = 8;

/**
 * Setup
 */
export const COMPLETED_SETUP = "completed-setup";
export const DAYS = "days";
export const IS_GIFTER = "isGifter";
export const SHARE_INFO = "share-info";
export const SUGGESTED = "suggested";

/**
 * Setup swiping
 */
export const RIGHT = "right";
export const LEFT = "left";
export const UP = "up";

/**
 * Celebration
 */
export const BIRTHDAY = "Birthday";
export const HOLIDAY = "Holiday";
export const ANNIVERSARY = "Anniversary";
export const NEW_BABY = "New baby";
export const MILESTONE = "Milestone";
export const ACHIEVEMENT = "Achievement";
export const NEW_JOB = "New job";
export const MARRIED = "Married";
export const ENGAGED = "Engaged";
export const GOAL = "Goal";
export const GRADUATION = "Graduation";
export const SOMETHING_NEW = "Something new";
export const NONE_TYPE = "None";
export const CELEBRATING = "Celebrating";
export const CELEBRATING_A = "Celebrating a";
export const CELEBRATING_AN = "Celebrating an";
export const JUST_GOT = "Just got";
export const AUTHOR = "author";
export const WEDDING = "Wedding";
export const NEW_HOME = "New home";
export const WORK_ANNIVERSARY = "Work anniversary";
export const HIT_A = "Hit a";

/**
 * Settings
 */
export const EVERYONE_OPTION = "Everyone";
export const ONLY_MY_CONTACTS_OPTION = "Only my contacts";
export const UNCONFIRMED = "unconfirmed";

/**
 * Nav items
 */
export const GIFTS_N = "Gifts";
export const CONNECTIONS_N = "Connections";
export const INVITE_LINK_N = "Invite link";
export const LOGOUT_N = "Log out";
export const SETTINGS_N = "Settings & privacy";
export const FEED_N = "Feed";
export const POST_N = "Post";
export const PRODUCTS_N = "Products";
export const EARN_POINTS_N = "Earn Points";
export const MESSAGES_N = "Messages";

/**
 * Tags
 */
export const TAGS_LIST = ["Women", "Candles", "Baby", "Dogs", "Cats", "Chocolate",
  "Sweets", "Toys", "Wellness", "Home", "Flowers", "Handbags", "Men", "Cooking", "Games"];

/**
 * Inbox
 */
export const MSGS_LIMIT = 25;
export const SUGGESTED_ACCOUNT_LIMIT = 20;
