import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { PRODUCT_ID, WISHLIST, USER_ID, EQUALS } from "../constants";

const addWishlist = async (wishlist: WishlistItem) : Promise<void> => {
  try {
    const q = query(collection(firestoreDb, WISHLIST), where(USER_ID, EQUALS, wishlist?.user?.id), where(PRODUCT_ID, EQUALS, wishlist?.product?.id));
    const wishlistsSnapshot = await getDocs(q);
    const wishlists = wishlistsSnapshot.docs;
    let wishlistsRef: any;
    if (wishlists?.length === 0) {
      wishlistsRef = await addDoc(collection(firestoreDb, WISHLIST), { ...wishlist });
      await updateDoc(wishlistsRef, { id: wishlistsRef.id });
    }
  } catch (e) {
    console.error("Error adding wishlist: ", e);
  }
}

const getWishlistsByUserId = async (userId: string) : Promise<WishlistItem[] | null> => {
  try {
    const q = query(collection(firestoreDb, WISHLIST), where(USER_ID, EQUALS, userId));
    const wishlistsSnapshot = await getDocs(q);
    if (wishlistsSnapshot.size > 0) {
      return wishlistsSnapshot?.docs?.map(wishlist => wishlist.data() as WishlistItem);
    }
    return null;
  } catch (e) {
    console.error("Error getting wishlist by userId: ", e);
  }
}

const getWishlistsByProductId = async (productId: string) : Promise<WishlistItem[] | null> => {
  try {
    const q = query(collection(firestoreDb, WISHLIST), where(PRODUCT_ID, EQUALS, productId));
    const wishlistsSnapshot = await getDocs(q);
    const wishlists = wishlistsSnapshot?.docs;
    if (wishlists?.length > 0) {
      return wishlists?.map(wishlist => wishlist.data() as WishlistItem);
    }
    return null;
  } catch (e) {
    console.error("Error getting wishlist by productId: ", e);
  }
}

const deleteWishlist = async (wishlistId: string) : Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, WISHLIST, wishlistId));
  } catch (e) {
    console.error("Error deleting wishlist by id: ", e);
  }
}

const WishlistService = {
  addWishlist,
  getWishlistsByUserId,
  getWishlistsByProductId,
  deleteWishlist
}

export default WishlistService;
