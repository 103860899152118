import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy,
  query, startAfter, updateDoc, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { EMPTY_STRING, ROOMS, MESSAGES, CREATED_AT, ARRAY_CONTAINS, EQUALS,
  MEMBER_IDS, DESC, LAST_MESSAGE, NOT_EQUALS, MSGS_LIMIT, LAST_MESSAGE_TIMESTAMP } from "../constants";

const getAllRoomsForUser = async (userId: string) : Promise<Room[] | null> => {
  try {
    const roomsSnapshot = await getDocs(query(
      collection(firestoreDb, ROOMS),
      where(MEMBER_IDS, ARRAY_CONTAINS, userId),
      where(LAST_MESSAGE, NOT_EQUALS, EMPTY_STRING),
      orderBy(LAST_MESSAGE_TIMESTAMP, DESC)
    ));
    if (roomsSnapshot.size > 0) {
      return roomsSnapshot.docs.map(room => room.data() as Room);
    };
    return null;
  } catch (e) {
    console.error("Error getting all rooms for user: ", e);
  };
};

const getMessagesAndCutoff = async (roomId: string, next?: boolean, lastRetreived?: any) : Promise<[RoomMessage[], any]> => {
  let q = query(
    collection(firestoreDb, ROOMS, roomId, MESSAGES),
    orderBy(CREATED_AT, DESC),
    limit(MSGS_LIMIT)
  );
  if (next) {
    q = query(
      collection(firestoreDb, ROOMS, roomId, MESSAGES),
      orderBy(CREATED_AT, DESC),
      startAfter(lastRetreived),
      limit(MSGS_LIMIT)
    );
  };
  try {
    const msgsSnapshots = await getDocs(q);
    return [
      msgsSnapshots?.docs?.map(msgDoc => msgDoc.data() as RoomMessage),
      msgsSnapshots ? msgsSnapshots.docs[msgsSnapshots.docs?.length - 1] : null
    ];
  } catch (e) {
    console.error("Error getting messages for room: ", e);
  };
};

const listenForMessagesInRoom = (roomId: string, lastRetrievedMsg: RoomMessage, callback: (messages: RoomMessage[]) => void) => {
  let q = query(
    collection(firestoreDb, ROOMS, roomId, MESSAGES),
    orderBy(CREATED_AT, DESC),
    limit(MSGS_LIMIT)
  );
  if (lastRetrievedMsg) {
    q = query(
      collection(firestoreDb, ROOMS, roomId, MESSAGES),
      orderBy(CREATED_AT, DESC),
      startAfter(lastRetrievedMsg)
    );
  };
  const unsubscribe = onSnapshot(q, (msgsSnapshot) => { callback(msgsSnapshot.docs.map(msg => msg.data() as RoomMessage)) });
  return unsubscribe;
};

const getOrCreateRoom = async (memberObjs: SubUser[]) : Promise<Room> => {
  const memberIds: string[] = memberObjs.map(m => m?.id).sort();
  try {
    const roomsSnapshot = await getDocs(
      query(collection(firestoreDb, ROOMS), where(MEMBER_IDS, EQUALS, memberIds))
    );
    if (!roomsSnapshot.empty) {
      return roomsSnapshot.docs.at(0).data() as Room;
    };
  } catch (e) {
    console.error("Error getting room for users: ", e);
  };
  const newRoom: Room = {
    id: EMPTY_STRING,
    memberIds,
    memberObjs,
    lastMessage: EMPTY_STRING,
    lastSenderId: EMPTY_STRING,
    lastMessageTimestamp: 0,
    seen: true,
    createdAt: Date.now(),
    updatedAt: Date.now()
  };
  try {
    const roomRef = await addDoc(collection(firestoreDb, ROOMS), newRoom);
    await updateDoc(roomRef, { id: roomRef.id });
    return { ...newRoom, id: roomRef.id };
  } catch (e) {
    console.error("Error creating new room: ", e);
  };
};

const getRoomById = async (roomId: string) : Promise<Room> => {
  try {
    const roomSnapshot = await getDoc(doc(firestoreDb, ROOMS, roomId));
    if (roomSnapshot.exists()) {
      return roomSnapshot.data() as Room;
    };
    return null;
  } catch (e) {
    console.error("Error getting room by id: ", e);
  }
};

const listenForRooms = (userId: string, callback: (roomsList: Room[]) => void) => {
  const unsubscribe = onSnapshot(
    query(
      collection(firestoreDb, ROOMS),
      where(MEMBER_IDS, ARRAY_CONTAINS, userId),
      where(LAST_MESSAGE, NOT_EQUALS, EMPTY_STRING),
      orderBy(LAST_MESSAGE_TIMESTAMP, DESC)
    ),
    (roomsSnapshot) => { callback(roomsSnapshot.docs.map(roomDoc => roomDoc.data() as Room)); }
  );
  return unsubscribe;
};

const addMessage = async (roomId: string, sender: SubUser, content: string) : Promise<string> => {
  try {
    const roomMessageRef = await addDoc(collection(firestoreDb, ROOMS, roomId, MESSAGES), {
      content,
      sender,
      roomId,
      createdAt: Date.now(),
      updatedAt: Date.now()
    });
    await updateDoc(roomMessageRef, { id: roomMessageRef.id });
    await updateDoc(doc(firestoreDb, ROOMS, roomId), {
      lastMessage: content,
      lastSenderId: sender?.id,
      seen: false,
      lastMessageTimestamp: Date.now()
    });
    return roomMessageRef.id;
  } catch (e) {
    console.error("Error adding message: ", e);
  };
};

const updateRoomSetSeen = async (roomId: string) : Promise<void> => {
  try {
    await updateDoc(doc(firestoreDb, ROOMS, roomId), { seen: true });
  } catch (e) {
    console.error("Error updating room: ", e);
  };
};

const RoomService = {
  getAllRoomsForUser,
  getMessagesAndCutoff,
  getOrCreateRoom,
  getRoomById,
  listenForMessagesInRoom,
  listenForRooms,
  updateRoomSetSeen,
  addMessage
}

export default RoomService;
