import { FC, useState, ChangeEvent, useContext, useEffect, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { UserCredential, User, createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification } from "firebase/auth";
import { CircularProgress } from '@mui/material';

import { auth, googleAuthProvider } from '../../firebase';
import { AuthContext } from '../../providers/AuthProvider';
import { ButtonStretched, Container, Error, FooterContainer, GoogleButton, GoogleIconImg, LoginRegisterHeading, LoginSignUpText, WhiteSpinnerSpan } from '../children/styled';
import { CREATE_PATH, EMPTY_STRING, ENTER, HOME_PATH, IS_GIFTER, LOGIN_PATH } from '../../constants';
import { emptyAccount } from '../../services/emptyObjs';
import GoogleIcon from "../../images/google.svg";
import UserService from '../../services/userService';
import Footer from '../children/Footer';

const Input = styled.input`
  width: 95%;
  max-width: 364px;
  margin-bottom: 18px;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const Span = styled.span`
  font-family: ABCGintoNormal-Bold;
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: bold;
`

interface RegisterProps {
  isGifter?: boolean
 }
 const Register: FC<RegisterProps> = ({ isGifter }) => {
  const [email, setEmail] = useState(EMPTY_STRING);
  const [password, setPassword] = useState(EMPTY_STRING);
  const [confirmPassword, setConfirmPassword] = useState(EMPTY_STRING);
  const [registerError, setRegisterError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [googleIsLoading, setGoogleIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      window.location.assign(HOME_PATH);
    };
    if (isGifter) {
      localStorage.setItem(IS_GIFTER, Date.now().toString());
    };
  }, []);

  const verifyEmail = async () => {
    await auth.currentUser.reload();
    if (!auth.currentUser.emailVerified) {
      await sendEmailVerification(auth.currentUser);
    };
  };

  const saveUserAndRedirect = async () => {
    setLoading(true);
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (!userCredential) {
        setLoading(false);
        return;
      };
      await verifyEmail();
      const authUser: User = userCredential.user;
      if (authUser && setCurrentUserToStorage) {
        setCurrentUserToStorage({
          ...emptyAccount,
          id: authUser.uid,
          email: authUser.email,
          createdAt: Date.now(),
          updatedAt: Date.now()
        });
        window.location.assign(CREATE_PATH);
      }
    } catch (error) {
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        await handleSignInGoogleClick();
        return;
      };
      setRegisterError(true);
      setErrorMessage("Sorry, we cannot register you with that email and password. Please try with another email or password.");
    }
    setLoading(false);
  }

  const handleSignInGoogleClick = async () => {
    setGoogleIsLoading(true);
    try {
      const userCredential: UserCredential = await signInWithPopup(auth, googleAuthProvider);
      if (!userCredential) {
        return;
      }
      const authUser: User = userCredential.user;
      if (authUser && setCurrentUserToStorage) {
        const account: Account = await UserService.getAccountById(authUser.uid);
        if (account) {
          setCurrentUserToStorage({ ...account });
          window.location.assign(HOME_PATH);
          return;
        }
        setCurrentUserToStorage({
          ...emptyAccount,
          id: authUser.uid,
          email: authUser.email,
          createdAt: Date.now(),
          updatedAt: Date.now()
        });
        window.location.assign(CREATE_PATH);
      }
      setGoogleIsLoading(false);
    } catch (error) {
      setRegisterError(true);
      setErrorMessage("Cannot log you in with that account. Please try with another.");
    }
  };

  const handleSignUpClick = async () => {
    if (email === EMPTY_STRING || password === EMPTY_STRING || confirmPassword === EMPTY_STRING) {
      setRegisterError(true);
      setErrorMessage("Please enter your email and password.");
      return;
    } else if (password !== confirmPassword) {
      setRegisterError(true);
      setErrorMessage("Passwords don't match.");
      return;
    } else if (password?.length < 6) {
      setRegisterError(true);
      setErrorMessage("Use 8 characters or more for your password.");
      return;
    }
    await saveUserAndRedirect();
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError(false);
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError(false);
    setPassword(event.target.value);
  }

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError(false);
    setConfirmPassword(event.target.value);
  }

  const handleSignInClick = () => {
    setRegisterError(false);
    window.location.assign(LOGIN_PATH);
    window.scrollTo(0, 0);
  }

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if(event.key === ENTER) {
      handleSignUpClick();
    }
  };

  return (
    <FooterContainer>
      <Container>
        <LoginRegisterHeading>Sign Up</LoginRegisterHeading>
        <form>
          {!googleIsLoading && <>
            <Input type='email' placeholder='Email' value={email} onChange={handleEmailChange} required />
            {registerError && <Error style={{ marginRight: "auto", marginLeft: "auto" }}>{errorMessage}</Error>}
            <Input type='password' placeholder='Password' value={password} onChange={handlePasswordChange} required />
            <Input type='password' placeholder='Confirm password' value={confirmPassword} onChange={handleConfirmPasswordChange} onKeyDown={handleEnterPress} required />
            <ButtonStretched onClick={handleSignUpClick} style={{ marginBottom: "20px" }}>
              {loading &&
                <WhiteSpinnerSpan>
                  <CircularProgress style={{ width: "30px", height: "30px", color: "#fff" }} />
                </WhiteSpinnerSpan>
              }
              {!loading && <>Sign Up</>}
            </ButtonStretched>
            <LoginSignUpText style={{ fontSize: "15px" }}>or</LoginSignUpText></>
          }
          <GoogleButton onClick={handleSignInGoogleClick}>
            <GoogleIconImg src={GoogleIcon} />
            {googleIsLoading ?
              <span style={{ width: "180px", textAlign: "center" }}>
                <CircularProgress style={{ width: "30px", height: "30px" }} />
              </span>
              :
              <>Continue with Google</>
            }
          </GoogleButton>
        </form>
        <LoginSignUpText>Already have an account? <Span onClick={handleSignInClick}>Sign in</Span></LoginSignUpText>
      </Container>
      <Footer />
    </FooterContainer>
  );
};

export default Register;
