import { FC } from 'react';
import { Modal, ModalTitle } from 'react-bootstrap';
import { Chip } from '@mui/material';
import { Close } from '@mui/icons-material';

import { ModalBodyPadding20, NoWidthButton, ButtonGroup, LowPriorityButton } from '../children/styled';
import { TAGS_LIST } from '../../constants';

interface TagsModalProps {
  showTagsModal: boolean;
  tagsOnArray: boolean[];
  setShowTagsModal: (val: boolean) => void;
  handleAddOrDeleteTag: (index: number, adding: boolean) => void;
  handleResetClick: () => void;
};
const TagsModal: FC<TagsModalProps> = ({ showTagsModal, tagsOnArray, setShowTagsModal, handleAddOrDeleteTag, handleResetClick }) => {

  return (
    <Modal show={showTagsModal} onHide={() => setShowTagsModal(false)} size="sm" centered>
      <Modal.Header style={{ padding: "20px 15px" }} closeButton={true}>
        <ModalTitle style={{ fontSize: "18px" }}>
          Filter by tags
        </ModalTitle>
      </Modal.Header>
      <ModalBodyPadding20>
        {TAGS_LIST.map((tag: string, index: number) => (
          <Chip key={tag + index} label={tag} onClick={() => handleAddOrDeleteTag(index, true)} onDelete={() => handleAddOrDeleteTag(index, false)}
            deleteIcon={tagsOnArray?.at(index) ? <Close /> : <></>} variant={tagsOnArray?.at(index) ? "filled" : "outlined"}
            style={{ marginRight: "7px", marginBottom: "7px" }} />
        ))}
        <ButtonGroup container direction="row" justifyContent="center" alignItems="center" className='center'>
          <LowPriorityButton onClick={handleResetClick}>Reset</LowPriorityButton>
          <NoWidthButton onClick={() => setShowTagsModal(false)}>Done</NoWidthButton>
        </ButtonGroup>
      </ModalBodyPadding20>
    </Modal>
  );
}

export default TagsModal;
