import { doc, setDoc, getDocs, collection, getDoc, updateDoc, limit, query, where } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { ARRAY_CONTAINS_ANY, EMPTY_STRING, GBP, PRODUCTS, TAGS } from "../constants";
import { trimText } from "./helpers";
import productsJson from "../services/products.json";

const updateProducts = async () : Promise<void> => {
  try {
    const products = productsJson.products;
    for (const ogProduct of products) {
      console.log("Updating product: " + ogProduct?.title);
      await updateDoc(doc(firestoreDb, PRODUCTS, ogProduct.id), { ...ogProduct });
      // const reactions: Reaction[] = await ReactionService.getReactionsByProductId(ogProduct.id);
      // const likedSubUsers: SubUser[] = [];
      // const skippedSubUsers: SubUser[] = [];
      // if (reactions && reactions?.length > 0) {
      //   for (const reaction of reactions) {
      //     if (reaction.status === LIKED) {
      //       likedSubUsers.push(reaction.user);
      //     } else if (reaction.status === SKIPPED) {
      //       skippedSubUsers.push(reaction.user);
      //     };
      //   }
      // }
      // await updateDoc(doc(firestoreDb, PRODUCTS, ogProduct.id), { ...ogProduct, userReactions: {
      //   liked: likedSubUsers,
      //   skipped: skippedSubUsers
      // }});
      // // Update Product wishlist items
      // const wishlistItems: WishlistItem[] = await WishlistService.getWishlistsByProductId(ogProduct.id);
      // const usersWishlisted: SubUser[] = [];
      // if (wishlistItems && wishlistItems?.length > 0) {
      //   for (const wishlistItem of wishlistItems) {
      //     usersWishlisted.push(wishlistItem.user);
      //   };
      // }
      // await updateDoc(doc(firestoreDb, PRODUCTS, ogProduct.id), { ...ogProduct, usersWishlisted });
    }
    console.log("Completed updates for product!");
    console.log("====================================");
  } catch (e) {
    console.error("Error in updating all products from json file: ", e);
  }
}

const updateProduct = async (product: Product) : Promise<void> => {
  try {
    const productRef = doc(firestoreDb, PRODUCTS, product.id);
    const productSnapShot = await getDoc(productRef);
    if (productSnapShot.exists()) {
      await updateDoc(productRef, { ...product });
      return;
    }
    await setDoc(productRef, { ...product });
  } catch (e) {
    console.error("Error updating product: ", e);
  }
}

const getProductsBasedOnTags = async (tagsList: string[]): Promise<Product[]> => {
  try {
    const productsSnapshot = await getDocs(query(
      collection(firestoreDb, PRODUCTS),
      where(TAGS, ARRAY_CONTAINS_ANY, tagsList)
    ));
    return productsSnapshot?.docs?.map(doc => doc.data() as Product);
  } catch (e) {
    console.error("Error in getting products with tags: ", e);
  }
}

const getProducts = async (maxNum? : number) : Promise<Product[]> => {
  try {
    let productsSnapshot: any;
    if (maxNum) {
      productsSnapshot = await getDocs(
        query(collection(firestoreDb, PRODUCTS), limit(maxNum))
      );
    } else {
      productsSnapshot = await getDocs(collection(firestoreDb, PRODUCTS));
    }
    return productsSnapshot?.docs?.map(doc => doc.data() as Product);
  } catch (e) {
    console.error("Error in getting products: ", e);
  }
}

const getProductById = async (productId: string) : Promise<Product> => {
  try {
    const productRef = doc(firestoreDb, PRODUCTS, productId);
    const product = await getDoc(productRef);
    if (product.exists()) {
      return product.data() as Product;
    }
    return null;
  } catch (e) {
    console.error("Error getting product by id: ", e);
  }
}

const getCurrency = (currencyCode: string) : string => {
  return currencyCode?.toLowerCase() === GBP ? "£" : currencyCode?.toUpperCase();
}

const getNumberOrZero = (number: string) : number => {
  if (!number || number === undefined || number.trim() === EMPTY_STRING) {
    return 0.00;
  }
  return parseFloat(number);
}

const getProcessingFee = (productCost: string, shipping: string) : string => {
  const totalBeforeStripe: number = getNumberOrZero(productCost) + getNumberOrZero(shipping);
  const processingfee: number = (totalBeforeStripe * 0.015) + 0.20;
  return processingfee.toFixed(2);
}

const getTotalPrice = (productCost: string, shipping: string, processingFee: string) : string => {
  const total: number = getNumberOrZero(productCost) + getNumberOrZero(shipping) + getNumberOrZero(processingFee);
  return total.toFixed(2);
}

const getProductTitleShortened = (productTitle: string) => {
  if (productTitle?.length > 35) {
    return trimText(productTitle, 30);
  }
  return productTitle;
}

const ProductService = {
  getProducts,
  getProductsBasedOnTags,
  getCurrency,
  getTotalPrice,
  getProcessingFee,
  getProductTitleShortened,
  getProductById,
  updateProduct,
  updateProducts
}

export default ProductService;
