import { doc, updateDoc, getDoc, setDoc, query, where, getDocs, collection, addDoc, deleteDoc, and, or } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { OFFERS, STATUS, NEEDS_VERIFICATION, EQUALS, GIFTER_USERNAME, NOT_IN, GIFTEE_ID, GIFTER_ID,
  CHECKOUT_PAYMENT_ID, ACCEPTED, COMPLETED } from "../constants";

const getOfferById = async (offerId: string) : Promise<Offer> => {
  try {
    const offerRef = doc(firestoreDb, OFFERS, offerId);
    const offerSnapShot = await getDoc(offerRef);
    if (offerSnapShot.exists()) {
      return offerSnapShot.data() as Offer;
    }
  } catch (e) {
    console.error("Error getting offer by id: ", e);
  }
}

const getOfferByPaymentId = async (paymentId: string) : Promise<Offer> => {
  try {
    const q = query(collection(firestoreDb, OFFERS), where(CHECKOUT_PAYMENT_ID, EQUALS, paymentId));
    const offersSnapshot = await getDocs(q);
    if (offersSnapshot.size === 1) {
      return offersSnapshot.docs.at(0).data() as Offer;
    };
    return null;
  } catch (e) {
    console.error("Error getting offer by paymentId: ", e);
  }
}

const addOffer = async (offer: Offer) : Promise<Offer> => {
  try {
    const offerRef = await addDoc(collection(firestoreDb, OFFERS), offer);
    await updateDoc(offerRef, { id: offerRef.id });
    return await getOfferById(offerRef.id);
  } catch (e) {
    console.error("Error adding offer: ", e);
  }
}

const updateOffer = async (offer: Offer) : Promise<Offer> => {
  try {
    const offerRef = doc(firestoreDb, OFFERS, offer.id);
    const offerSnapShot = await getDoc(offerRef);
    if (offerSnapShot.exists()) {
      await updateDoc(offerRef, { ...offer });
    } else {
      await setDoc(offerRef, { ...offer });
    }
    return await getOfferById(offer.id);
  } catch (e) {
    console.error("Error creating or updating offer: ", e);
  }
}

// const getUserSentOrReceivedOffers = async (userId: string, isGifter: boolean) : Promise<Offer[]> => {
//   try {
//     const offersRef = collection(firestoreDb, OFFERS);
//     let field = GIFTEE_ID;
//     if (isGifter === true) {
//       field = GIFTER_ID;
//     };
//     const offersSnapshot = await getDocs(
//       query(offersRef, where(field, EQUALS, userId))
//     );
//     const offers = offersSnapshot.docs;
//     return offers
//       .map(offer => offer.data() as Offer)
//       .filter(offer => offer?.status !== NEEDS_VERIFICATION);
//   } catch (e) {
//     console.error("Error getting all sent or received offers for given user: ", e);
//   }
// }

const getOffersByUser = async (userId: string, roleField: string) : Promise<Offer[]> => {
  try {
    const offersSnapshot = await getDocs(query(
      collection(firestoreDb, OFFERS),
      and(
        where(roleField, EQUALS, userId),
        or(where(STATUS, EQUALS, COMPLETED), where(STATUS, EQUALS, ACCEPTED))
      )
    ));
    return offersSnapshot.docs.map(offer => offer.data() as Offer);
  } catch (e) {
    console.error("Error: ", e);
  };
};

const getAllCustomOffers = async () : Promise<Offer[]> => {
  try {
    const q = query(collection(firestoreDb, OFFERS), where(STATUS, EQUALS, NEEDS_VERIFICATION));
    const offersSnapshot = await getDocs(q);
    return offersSnapshot?.docs?.map(doc => doc.data() as Offer);
  } catch (e) {
    console.error("Error getting all custom offers: ", e);
  }
}

const getAllOffers = async (excludedUsers: string[]) : Promise<Offer[]> => {
  try {
    const q = query(collection(firestoreDb, OFFERS), where(GIFTER_USERNAME, NOT_IN, excludedUsers));
    const offersSnapshot = await getDocs(q);
    return offersSnapshot?.docs?.map(doc => doc.data() as Offer);
  } catch (e) {
    console.error("Error getting all offers: ", e);
  }
}

const offerMadeFromAToB = async (senderId: string, receiverId: string) : Promise<boolean> => {
  try {
    const offersSnapshot = await getDocs(query(collection(firestoreDb, OFFERS),
      where(GIFTER_ID, EQUALS, senderId),
      where(GIFTEE_ID, EQUALS, receiverId),
      where(STATUS, EQUALS, ACCEPTED)
    ));
    if (!offersSnapshot.empty) {
      return true;
    };
    return false;
  } catch (e) {
    console.error("Error getting offers between users: ", e);
  }
};

const deleteOffer = async (offerId: string) : Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, OFFERS, offerId));
  } catch (e) {
    console.error("Error deleting offer: ", e);
  }
}

const OfferService = {
  getOfferById,
  addOffer,
  updateOffer,
  getOfferByPaymentId,
  getOffersByUser,
  getAllCustomOffers,
  getAllOffers,
  offerMadeFromAToB,
  deleteOffer
}

export default OfferService;
