import { FC } from 'react';

import { ButtonGroup, PageHeading, NoWidthButton, Text, LowPriorityButton, MainDiv } from './styled';
import { goToHomeOnClick, handleSentOffersClick } from '../../services/helpers';

interface StatusProps {
  title: string;
  message: string;
  button1: string;
  button2: string;
}
const Status: FC<StatusProps> = ({ title, message, button1, button2 }) => {

  return (
    <MainDiv>
      <PageHeading className='center-width-24rem'>{title}</PageHeading>
      <div className='center-width-24rem'>
        {message.split(/\r?\n/)?.map((text, i) => (
          <Text key={i} style={{ marginBottom: "10px" }}>{text}</Text>
        ))}
      </div>
      <ButtonGroup className='center-width-24rem' container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <LowPriorityButton onClick={goToHomeOnClick}>{button1}</LowPriorityButton>
        <NoWidthButton onClick={handleSentOffersClick}>{button2}</NoWidthButton>
      </ButtonGroup>
    </MainDiv>
  );
};

export default Status;
