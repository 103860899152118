import styled from '@emotion/styled';

import { Container, FooterContainer, PageHeading, Text } from '../children/styled';
import Footer from '../children/Footer';
import { goToHomeOnClick } from '../../services/helpers';

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const NotFound = () => {

  return (
     <FooterContainer>
      <Container>
        <PageHeading>Sorry, this page isn't available.</PageHeading>
        <Text>The link you followed may be broken, or the page may have been removed. <Link onClick={goToHomeOnClick}>Go back to Giftify.</Link></Text>
      </Container>
      <Footer />
     </FooterContainer>
  );
};

export default NotFound;
