import { useState, useContext, useEffect, ChangeEvent } from 'react';
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Navbar as BootNavbar, NavbarBrand as BootNavbarBrand, NavbarToggler as BootNavbarToggler, Collapse, Nav } from 'reactstrap';
import tw from "twin.macro";
import { BottomNavigation, BottomNavigationAction, CircularProgress, Grid, Paper } from '@mui/material';
import { FilterNoneOutlined as CopyIcon } from '@mui/icons-material';
import { Modal as InviteModal } from 'react-bootstrap';

import { AuthContext } from '../../providers/AuthProvider';
import NavModal from './NavModal';
import Search from './Search';
import NavRightSection from './NavRightSection';
import NotificationService from '../../services/notifcationService';
import {
    CONNECTIONS_PATH, REQUESTS_PATH, HOME_PATH, REGISTER_PATH, LOGIN_PATH, RECEIVED_PATH, PRODUCTS_PATH, BASE_URL,
    GBP, PENDING, INVITE_PATH, EMPTY_STRING, SLASH, FEED_N, POST_N, MESSAGES_N, INBOX_PATH
} from '../../constants';
import {
    ToggleNotification, ModalBody, ModalTitle, Input, NoWidthButton, Text, Img, CopyArea, IconButtonMui, LinkText,
    CenterDiv, MsgNotification as BaseMsgNotig
} from './styled';
import Alert from './Alert';
import InviteService from '../../services/inviteService';
import { getRndInteger, randomStringGenerator } from '../../firebase/helpers';
import { emptyPrice } from '../../services/emptyObjs';
import CelebrationFormModal from './CelebrationFormModal';
import StorageService from '../../services/storageService';
import { authenticateUser, getTotalNotifsForAllRooms, goToInbox, handleCopyClick, inChatRoom, updateCurrentUser } from '../../services/helpers';

const Navbar = styled(BootNavbar)`
  padding: 0px 16px;
  background-color: #fff;
  height: 70px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`

const NavbarBrand = styled(BootNavbarBrand)`
  ${tw`text-2xl! text-4xl font-black`};
  color: #fff !important;
  cursor: pointer;
`

const NavbarToggler = styled(BootNavbarToggler)`
  right: 10px;
  position: absolute;

  :focus {
    outline: none;
  }
`

const BottomNavIcon = styled.i`
  font-size: 19px;
  margin-bottom: 3px;
`

const BottomNav = styled(Paper)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  border: 1px solid #F2F3F5;
  border-radius: 0px;
`

const MsgNotification = styled(BaseMsgNotig)`
  bottom: 14px;
  left: 11px;
  right: unset;
  top: unset;
  height: 11px;
  width: 11px;
`

const MainNavbar = () => {
  const location = useLocation();
  const { currentUser, signOut, setCurrentUserToStorage } = useContext(AuthContext);
  const [fullNavbar, setFullNavbar] = useState(false);
  const [profileImg, setProfileImg] = useState<string>(EMPTY_STRING);
  const [notifications, setNotifications] = useState<Notifications | null>();
  const [giftsNotifCSS, setGiftsNotifCSS] = useState<boolean>(false);
  const [requestsNotifCSS, setRequestsNotifCSS] = useState<boolean>(false);
  const [addedNotifCSS, setAddedNotifCSS] = useState<boolean>(false);
  const [giftsAcceptedCSS, setGiftsAcceptedCSS] = useState<boolean>(false);
  const [congratsNotifCSS, setCongratsNotifCSS] = useState<boolean>(false);
  const [dropdownCSS, setDropdownCSS] = useState<boolean>(false);
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const [inviteLink, setInviteLink] = useState<string>(EMPTY_STRING);
  const [name, setName] = useState<string>(EMPTY_STRING);
  const [email, setEmail] = useState<string>(EMPTY_STRING);
  const [phone, setPhone] = useState<string>(EMPTY_STRING);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [celebrationModalOpen, setCelebrationModalOpen] = useState<boolean>(false);
  const [mobileTabSelected, setMobileTabSelected] = useState<number>();
  const [showProductsToSelect, setShowProductsToSelect] = useState<boolean>(false);
  const [totalNotifs, setTotalNotifs] = useState<number>(0);

  const emptyForm = () => {
    setInviteLink(EMPTY_STRING);
    setName(EMPTY_STRING);
    setEmail(EMPTY_STRING);
    setPhone(EMPTY_STRING);
  }

  const handleLogoClick = () => {
    window.location.assign(HOME_PATH);
  }

  const handleToggleClick = () => {
    setFullNavbar(true);
  }

  const handleProfileClick = () => {
    setDropdownCSS(false);
    setFullNavbar(false);
    window.location.assign(`/${currentUser?.username}`);
  }

  const handleAccountClick = () => {
    setDropdownCSS(!dropdownCSS);
  }

  const handleGiftIconClick = async () => {
    setFullNavbar(false);
    window.location.assign(RECEIVED_PATH);
  }

  const handleConnectionsClick = async () => {
    setDropdownCSS(false);
    setAddedNotifCSS(false);
    setFullNavbar(false);
    window.location.assign(CONNECTIONS_PATH);
  }

  const handleGetInviteClick = () => {
    setInviteModalOpen(true);
  }

  const handleRequestsClick = async () => {
    setDropdownCSS(false);
    setRequestsNotifCSS(false);
    setFullNavbar(false);
    window.location.assign(REQUESTS_PATH);
  }

  const handleLogoutClick = () => {
    setDropdownCSS(false);
    signOut();
    setFullNavbar(false);
    window.location.assign(HOME_PATH);
  }

  const handleFindClick = () => {
    setFullNavbar(false);
    window.location.assign(PRODUCTS_PATH);
    window.scrollTo(0, 0);
  }

  const handleLoginClick = () => {
    setFullNavbar(false);
    window.location.assign(LOGIN_PATH);
    window.scrollTo(0, 0);
  }

  const handleRegisterClick = () => {
    window.location.assign(REGISTER_PATH);
    window.scrollTo(0, 0);
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  }

  const handleCancelClick = () => {
    setInviteModalOpen(false);
    emptyForm();
  }

  const handlePostClick = async () => {
    await authenticateUser(currentUser, signOut);
    setCelebrationModalOpen(true);
  };

  const handleGenerateInvite = async () => {
    setIsLoading(true);
    const inviteObj = await InviteService.addInvite({
      id: EMPTY_STRING,
      shareId: randomStringGenerator(),
      inviter: {
        id: currentUser?.id,
        username: currentUser?.username,
        name: currentUser?.name,
        image: currentUser?.images[0],
        phone: currentUser?.phone,
        email: currentUser?.email
      },
      invitee: {
        name,
        phone,
        email
      },
      inviterPromotion: {
        code: EMPTY_STRING,
        discount: emptyPrice
      },
      inviteePromotion: {
        code: getRndInteger(1000, 10000),
        discount: {
          amount: "6.00",
          shippingFee: EMPTY_STRING,
          currencyCode: GBP
        }
      },
      status: PENDING,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    });
    setInviteLink(BASE_URL + INVITE_PATH + SLASH + inviteObj?.shareId);
    setIsLoading(false);
    setFullNavbar(false);
  };

  const getTotalNotifs = (notifsObj: Notifications) : number => {
    const nonCongrats = notifsObj?.giftsReceived +
      notifsObj?.giftsAccepted +
      notifsObj?.connectionRequests +
      notifsObj?.connectionsAdded;
    const congratsNotif = notifsObj?.congratsReceived ? notifsObj?.congratsReceived?.length : 0;
    if (isNaN(nonCongrats)) {
      return congratsNotif;
    };
    return nonCongrats + congratsNotif;
  };

  const getNotifications = async () => {
    const userId = currentUser?.id;
    if (userId && userId !== EMPTY_STRING) {
      const notifs: Notifications = await NotificationService.getNotifications(userId);
      if (notifs) {
        setNotifications(notifs);
        setTotalNotifs(getTotalNotifs(notifs));
        if (notifs?.giftsReceived > 0) {
          setGiftsNotifCSS(true);
        };
        if (notifs?.connectionRequests > 0) {
          setRequestsNotifCSS(true);
        };
        if (notifs?.connectionsAdded > 0) {
          setAddedNotifCSS(true);
        };
        if (notifs?.giftsAccepted > 0) {
          setGiftsAcceptedCSS(true);
        };
        if (notifs?.congratsReceived && notifs?.congratsReceived?.length > 0) {
          setCongratsNotifCSS(true);
        };
      }
    }
  };

  const updateBottomNav = () => {
    if (location && location.pathname === HOME_PATH) {
      setMobileTabSelected(0);
    } else if (location && location.pathname === INBOX_PATH) {
      setMobileTabSelected(2);
    };
  };

  useEffect(() => {
    updateBottomNav();
    if (currentUser) {
      updateCurrentUser(currentUser?.id, setCurrentUserToStorage);
      getNotifications();
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      setProfileImg(StorageService.getImageOrDefault(currentUser?.images));
    };
  }, [currentUser]);

  useEffect(() => {
    if (mobileTabSelected === 1) {
      updateBottomNav();
    }
  }, [mobileTabSelected]);

  return (
    <>
      <Navbar light expand="md" className="pl-md-5 pr-md-5 nav">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <NavbarBrand onClick={handleLogoClick}>
            <Img style={{ width: "160px" }} src="https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/logos%2Fhomepage_logo.PNG?alt=media&token=526f4d42-6ddc-4998-bc09-e64c7438335b" />
          </NavbarBrand>
          <NavbarToggler onClick={handleToggleClick} />
          {totalNotifs > 0 && <ToggleNotification className='hide-notif-on-large-screen' />}
          <Collapse isOpen={false} navbar>
            <Search />
            <Nav className="ms-auto" navbar>
              <NavRightSection
                currentUser={currentUser}
                profileImg={profileImg}
                notifications={notifications}
                requestsNotifCSS={requestsNotifCSS}
                giftsNotifCSS={giftsNotifCSS}
                giftsAcceptedCSS={giftsAcceptedCSS}
                congratsNotifCSS={congratsNotifCSS}
                addedNotifCSS={addedNotifCSS}
                dropdownCSS={dropdownCSS}
                setDropdownCSS={setDropdownCSS}
                setCelebrationModalOpen={setCelebrationModalOpen}
                location={location}
                totalNotifs={totalNotifs}
                handleProfileClick={handleProfileClick}
                handleAccountClick={handleAccountClick}
                handleGiftIconClick={handleGiftIconClick}
                handleConnectionsClick={handleConnectionsClick}
                handleGetInviteClick={handleGetInviteClick}
                handleLogoutClick={handleLogoutClick}
                handleFindClick={handleFindClick}
                handleRegisterClick={handleRegisterClick}
                handleLoginClick={handleLoginClick}
              />
            </Nav>
          </Collapse>
        </Grid>
      </Navbar>
      <NavModal
        currentUser={currentUser}
        profileImg={profileImg}
        notifications={notifications}
        fullNavbar={fullNavbar}
        requestsNotifCSS={requestsNotifCSS}
        giftsNotifCSS={giftsNotifCSS}
        giftsAcceptedCSS={giftsAcceptedCSS}
        congratsNotifCSS={congratsNotifCSS}
        addedNotifCSS={addedNotifCSS}
        location={location}
        setFullNavbar={setFullNavbar}
        handleProfileClick={handleProfileClick}
        handleGiftIconClick={handleGiftIconClick}
        handleConnectionsClick={handleConnectionsClick}
        handleGetInviteClick={handleGetInviteClick}
        handleRequestsClick={handleRequestsClick}
        handleLogoutClick={handleLogoutClick}
        handleFindClick={handleFindClick}
        handleRegisterClick={handleRegisterClick}
        handleLoginClick={handleLoginClick}
      />
      {!inChatRoom(location.pathname) && currentUser &&
        <BottomNav elevation={0} className="bottom-navigation">
          <BottomNavigation showLabels value={mobileTabSelected} onChange={(e, newValue) => { setMobileTabSelected(newValue) }}>
            <BottomNavigationAction label={FEED_N} onClick={() => window.location.assign(HOME_PATH)} icon={<BottomNavIcon className="fa-solid fa-house" />} />
            <BottomNavigationAction label={POST_N} onClick={handlePostClick} icon={<BottomNavIcon className="fa-regular fa-square-plus" />} />
            <BottomNavigationAction label={MESSAGES_N} onClick={() => goToInbox()} icon={
              <div style={{ position: "relative" }}>
                <BottomNavIcon className="fa-solid fa-comment" />
                {(getTotalNotifsForAllRooms(notifications?.totalNotifsForRooms) > 0) && <MsgNotification />}
              </div>
            } />
          </BottomNavigation>
        </BottomNav>
      }
      <InviteModal show={inviteModalOpen} onHide={() => setInviteModalOpen(false)} centered>
        <InviteModal.Header style={{ padding: "20px" }}>
          <ModalTitle style={{ fontSize: "20px" }}>
            {inviteLink === EMPTY_STRING ?
              "Personalise the invite"
              :
              <>{isLoading ?
                "Creating invite link..."
                :
                "Share invite link below"
              }</>
            }
          </ModalTitle>
        </InviteModal.Header>
        <ModalBody style={{ marginBottom: "0px" }}>
          {inviteLink === EMPTY_STRING ?
            <>
              <Text>Whom would you like to send the invite to?</Text>
              <Input type='text' placeholder='Name (optional)' value={name} onChange={handleNameChange} />
              <Input type='email' placeholder='Email (optional)' value={email} onChange={handleEmailChange} />
              <Input type='tel' placeholder='Phone (optional)' value={phone} onChange={handlePhoneChange} />
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "30px" }}>
                <NoWidthButton onClick={handleCancelClick}>Cancel</NoWidthButton>
                <NoWidthButton onClick={handleGenerateInvite}>Get invite link</NoWidthButton>
              </Grid>
            </>
            :
            <>{isLoading ?
              <CenterDiv><CircularProgress /></CenterDiv>
              :
              <><CopyArea onClick={() => handleCopyClick(inviteLink, setAlertIsOpen, setAlertType, setAlertMessage)} container direction="row" justifyContent="space-between" alignItems="center">
                <LinkText>{inviteLink}</LinkText>
                <IconButtonMui><CopyIcon /></IconButtonMui>
              </CopyArea>
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "30px" }}>
                <NoWidthButton onClick={handleCancelClick}>Cancel</NoWidthButton>
                <NoWidthButton onClick={emptyForm}>Get another</NoWidthButton>
              </Grid></>
            }</>
          }
        </ModalBody>
      </InviteModal>
      <CelebrationFormModal celebrationModalOpen={celebrationModalOpen} setCelebrationModalOpen={setCelebrationModalOpen}
        showProductsToSelect={showProductsToSelect} setShowProductsToSelect={setShowProductsToSelect} />
      <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} />
    </>
  )
};

export default MainNavbar;
